import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import logoGrey from '../../../images/logo-grey.png';
import qurishiLogoWhite from '../../../images/qurishi-logo white.png';

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, background } = useContext(ThemeContext);

  const handleMenuToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    const mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper) {
      if (toggle) {
        mainwrapper.classList.add("menu-toggle");
      } else {
        mainwrapper.classList.remove("menu-toggle");
      }
    } else {
      console.error("Main wrapper not found!");
    }
  }, [toggle]);

  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <img src={qurishiLogoWhite} alt="Logo White" className="w-100" />
        ) : (
          <img src={logoGrey} alt="Logo Grey" className="w-100" />
        )}
      </Link>

      <div className="nav-control" onClick={handleMenuToggle}>
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
