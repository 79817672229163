import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Collapse from "react-bootstrap/Collapse";
import { callApi } from "../../../services/frappeServices/db/db";
import { format } from "date-fns";
import NumericLabel from "react-pretty-numbers";
import {
  downloadExcel,
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";
import "./content.css"; // Ensure this file contains the CSS for .fixed-header-table
import { useGlobalContext } from "../../../context/GlobalContext";
const options = [
  { value: "2", label: "Published" },
  { value: "3", label: "Draft" },
  { value: "4", label: "Trash" },
  { value: "5", label: "Private" },
  { value: "6", label: "Pending" },
];
const translations = {
  en: {
    filter:"Filter",
    fromDate:"From Date",
    toDate:"To Date",
    clearFilter:"Clear Filter",
    customer:"Customer",
    download:"Download Excel",
    customerStat:"Customer Statment",
    date:"Date",
    voucherType:"Voucher Type",
    voucherNo:"Voucher No",
    debit:"Debit",
    credit:"Credit",
    balance:"Balance",
    noData:"No Data Found"
  },
  ar: {
    
    filter:"فلتر",
    clearFilter:"محو الفلتر",
    fromDate:"من تاريخ",
    toDate:"الي تاريخ",
    customer:"عميل",
    download:"تنزيل اكسيل",
    customerStat:"حاله العميل",
    date:"التاريخ",
    voucherType:"نوع القسيمه",
    voucherNo:"رقم القسيمه",
    debit:"مدين",
    credit:"دائن",
    balance:" الحساب",
    noData:"لا وجد معلومات"
    
     
  }
};
const tableData = [
  { number: "1", title: "Privacy Policy" },
  { number: "2", title: "Contact Us" },
  { number: "3", title: "Price" },
  { number: "4", title: "Blog" },
  { number: "5", title: "Faq" },
  { number: "6", title: "About us" },
  { number: "7", title: "Portfolio" },
  { number: "8", title: "Schedule" },
  { number: "9", title: "Under Maintenance" },
  { number: "10", title: "Comming Soon" },
  { number: "11", title: "Faq" },
  { number: "12", title: "About us" },
  { number: "13", title: "Portfolio" },
];

const Content = () => {
  
  const {currentCustomer} = useGlobalContext();
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });
  const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
  const t = translations[language];

  // const customer = JSON.parse(localStorage.getItem("userDetails")).message
  //   .customer_Details[0].name;


  // const customer = JSON.parse(localStorage.getItem('userDetails'))?.message?.user_customers[0].name;

  const [open, setOpen] = useState(true);
  const [statementData, setStatementData] = useState([]);
  const [open2, setOpen2] = useState(true);

  const today = format(new Date(), "yyyy-MM-dd");
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);

  const [data, setData] = useState(
    document.querySelectorAll("#content_wrapper tbody tr")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, setTest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  // Use effect
  useEffect(() => {
    setData(document.querySelectorAll("#content_wrapper tbody tr"));
  }, [test]);

  // Active pagination
  activePag.current === 0 && chageData(0, sort);
  // Pagination
  let pagination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active pagination & change data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    setTest(i);
  };

  const handleClearFilterClick = () => {
    setFromDate(today);
    setToDate(today);
  };

  const [deleteItem, setDeleteItem] = useState(tableData);
  const handleDelete = (ind) => {
    setDeleteItem((oldValues) => {
      return oldValues.filter((_, i) => i !== ind);
    });
  };

  // Modified handleApplyClick function to ensure it uses the latest state values
  const handleApplyClick = async () => {
    console.log("apply clicked");
    console.log("toDate", toDate);
    console.log("fromDate", fromDate);
    const { message } = await callApi({
      method: "POST",
      url: "/api/method/customer_app.apis.general_ledger_api.get_customer_general_ledger",
      data: {
        customer: currentCustomer,
        mobile_filters: {
          from_date: fromDate, // Ensure latest state values
          to_date: toDate, // Ensure latest state values
        },
      },
    });

    setStatementData(message);
    console.log("message", message); // Log the correct message
  };
  
  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="filter cm-content-box box-primary">
            <div className="content-title">
              <div className="cpa">
                <i className="fas fa-filter me-2"></i>
                <span>{t.filter}</span>
              </div>
              <div className="tools">
                <Link
                  to={"#"}
                  className={`SlideToolHeader ${open ? "collapse" : "expand"}`}
                  onClick={() => setOpen(!open)}
                >
                  <i className="fas fa-angle-up"></i>
                </Link>
              </div>
            </div>

            <Collapse in={open}>
              <div className="cm-content-body form excerpt">
                <div className="card-body">
                  <div className="row filter-row">
                    <div className="col-xl-3 col-xxl-6">
                      <p>{t.fromDate}</p>
                      <input
                        type="date"
                        name="fromDate"
                        className="form-control mb-xxl-0 mb-3"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                      />
                    </div>
                    <div className="col-xl-3 col-xxl-6">
                      <p>{t.toDate}</p>
                      <input
                        type="date"
                        name="toDate"
                        className="form-control mb-xxl-0 mb-3"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                      />
                    </div>
                    <div className="col-xl-3 col-xxl-6">
                      <p>{t.customer}</p>
                      <input
                        type="text"
                        readOnly
                        className="form-control mb-xl-0 mb-3"
                        id="exampleFormControlInput1"
                        placeholder={currentCustomer}
                      />
                    </div>
                    <div className="col-xl-6 col-xxl-6 mt-2">
                      <div className="d-flex justify-content-start">
                        <button
                          className="btn btn-primary me-2"
                          title="Click here to Search"
                          type="button"
                          onClick={handleApplyClick}
                        >
                          <i className="fa fa-search me-1"></i>{t.filter}
                        </button>
                        <button
                          className="btn btn-primary"
                          title="Click here to Search"
                          type="button"
                          onClick={handleClearFilterClick}
                        >
                          <i className="fa fa-eraser me-1"></i>{t.clearFilter}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
          <div className="mb-3">
            <DownloadTableExcel
              filename="customer statement"
              sheet="statement"
              currentTableRef={tableRef.current}
              onDownload={onDownload}
            >
              <Link to={""} className="btn btn-primary">
                {t.download}
              </Link>
            </DownloadTableExcel>
          </div>
          <div className="filter cm-content-box box-primary mt-5">
            <div className="content-title">
              <div className="cpa">
                <i className="fas fa-file-word me-2"></i>{t.customerStat}
              </div>
            </div>
            <Collapse in={open2}>
              <div className="cm-content-body form excerpt">
                <div className="card-body">
                  <div className="table-responsive fixed-header-table">
                    <div id="content_wrapper" className="dataTables_wrapper ">
                      <table
                        className="table table-bordered table-responsive-lg table-striped table-condensed flip-content"
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            <th>{t.date}</th>
                            <th>{t.voucherType}</th>
                            <th>{t.voucherNo}</th>
                            <th>{t.debit}</th>
                            <th>{t.credit}</th>
                            <th>{t.balance}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {statementData.length > 0 ? (
                            statementData.map((item, index) => (
                              <tr key={index}>
                                <td>{item.posting_date || " "}</td>
                                <td>
                                  {item.voucher_type ||
                                    item.account.replace(/'/g, "") ||
                                    " "}
                                </td>
                                <td>{item.voucher_no || " "}</td>

                                <td>
                                  {item.debit ? (
                                    <NumericLabel params={{ commafy: true }}>
                                      {Math.round(item.debit * 100) / 100}
                                    </NumericLabel>
                                  ) : (
                                    <p className="text-end">0</p>
                                  )}
                                </td>

                                <td>
                                  {item.credit ? (
                                    <NumericLabel params={{ commafy: true }}>
                                      {Math.round(item.credit * 100) / 100}
                                    </NumericLabel>
                                  ) : (
                                    <p className="text-end">0</p>
                                  )}
                                </td>

                                <td>
                                  {item.balance ? (
                                    <NumericLabel params={{ commafy: true }}>
                                      {Math.round(item.balance * 100) / 100}
                                    </NumericLabel>
                                  ) : (
                                    <p className="text-end">0</p>
                                  )}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                {t.noData}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;
