import React, { Fragment, useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useGlobalContext } from "../../../../context/GlobalContext";
import { uploadFile, savePrePayment } from "../../../../services/frappeServices/api/api";
import { Modal } from "react-bootstrap";
import styled, { keyframes } from "styled-components";

const validationSchema = Yup.object().shape({
  amount: Yup.string().required("Reference No is required"),
  reference_date: Yup.date().required("Reference Date is required").nullable(),
  description: Yup.string().required("Description is required"),
  file: Yup.mixed().required("File is required"),
});

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }

  .modal-body {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    color: #333;
  }

  &.show {
    animation: ${fadeIn} 0.3s ease-out;
  }

  &.hide {
    animation: ${fadeOut} 0.3s ease-in;
  }
`;

const AlertModal = ({ show, message, onHide }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onHide();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [show, onHide]);

  return (
    <StyledModal
      show={show}
      onHide={onHide}
      centered
      className={show ? 'show' : 'hide'}
    >
      <Modal.Body>{message}</Modal.Body>
    </StyledModal>
  );
};

const FormValidation = () => {
  const { currentCustomer, customerName } = useGlobalContext();
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const showAlert = (message) => {
    setAlertMessage(message);
    setShowAlertModal(true);
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.target.files[0];
    setFieldValue("file", file);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData();
    formData.append("amount", values.amount);
    formData.append("reference_date", values.reference_date);
    formData.append("description", values.description);
    formData.append("customer_code", currentCustomer);
    formData.append("customer_name", customerName);

    if (values.file) {
      try {
        const { message } = await uploadFile(values.file);
        const fileUrl = message.file_url;
        formData.append("reference_attachment", fileUrl);
      } catch (error) {
        console.error("Error uploading file: ", error);
        showAlert("Error uploading file.");
      }
    }

    try {
      const response = await savePrePayment(formData);
      console.log("Response: ", response.name);

      if (response.name !=='AxiosError') {
        navigate("/pre-payment");
      }

      if (response.response && response.response.status === 417) {
        const serverMessages = JSON.parse(response.response.data._server_messages);
        const errorMessage = JSON.parse(serverMessages[0]).message;

        if (errorMessage) {
          showAlert(`Error: ${errorMessage}`);
          
        } else {
          showAlert("A unique validation error occurred.");
           
        }
      } else {
        showAlert("Form submitted successfully!");
      }
    } catch (error) {
      console.error("Error submitting form: ", error);
      showAlert("Error submitting form.");
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {}, [currentCustomer]);

  return (
    <Fragment>
      <PageTitle
        activeMenu="Add New"
        motherMenu="Pre-payment"
        pageContent="Validation"
      />

      <AlertModal
        show={showAlertModal}
        message={alertMessage}
        onHide={() => setShowAlertModal(false)}
      />

      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Pre-Payment</h4>
            </div>
            <div className="card-body">
              <div className="form-validation">
                <Formik
                  initialValues={{
                    amount: "",
                    reference_date: "",
                    description: "",
                    file: null,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ setFieldValue, isSubmitting }) => (
                    <Form className="form-valide">
                      <div className="row">
                        <div className="col-xl-6">
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="amount"
                            >
                              Amount
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <Field
                                type="text"
                                className="form-control"
                                id="amount"
                                name="amount"
                                placeholder="Enter Amount .."
                              />
                              <ErrorMessage
                                name="amount"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="reference_date"
                            >
                              Reference Date
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <Field
                                type="date"
                                className="form-control"
                                id="reference_date"
                                name="reference_date"
                              />
                              <ErrorMessage
                                name="reference_date"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-2 col-form-label text-lg-start"
                              htmlFor="description"
                            >
                              Description
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-10">
                              <Field
                                as="textarea"
                                className="custom-textarea"
                                id="description"
                                name="description"
                                style={{
                                  display: "block",
                                  width: "100%",
                                  padding: "0.375rem 0.75rem",
                                  fontSize: "0.875rem",
                                  fontWeight: "400",
                                  lineHeight: "1.5",
                                  color: "#888888",
                                  backgroundColor: "#fff",
                                  backgroundClip: "padding-box",
                                  border: "1px solid #e6e6e6",
                                  appearance: "none",
                                  borderRadius: "0.75rem",
                                  transition:
                                    "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
                                  height: "150px",
                                }}
                              />
                              <ErrorMessage
                                name="description"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-6">
                          <div className="form-group mb-3 row">
                            <label
                              className="col-lg-4 col-form-label"
                              htmlFor="file"
                            >
                              Ref. Attachment
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-6">
                              <input
                                type="file"
                                className="form-control"
                                id="file"
                                name="file"
                                onChange={(e) =>
                                  handleFileChange(e, setFieldValue)
                                }
                                ref={fileInputRef}
                              />
                              <ErrorMessage
                                name="file"
                                component="div"
                                className="text-danger"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-group mb-3 row">
                          <div className="col-lg-8 ms-auto">
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={isSubmitting}
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FormValidation;
