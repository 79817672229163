import React from 'react';
import NotFoundImage from '../images/writer.svg';
import './errorPage.css';

const ErrorPage = ({ error, resetErrorBoundary }) => {
  console.log('Error occured', error.message);
  return (
    <div className='error-page'>
      <img src={NotFoundImage} alt='Page not found' />
      <p className='error-msg'>
        Something went wrong.<br></br>
        {/* {error.message}<br></br> */}
        Try clicking the refresh page button to reload the
        application.{' '}
        <button className='btn' onClick={resetErrorBoundary}>
          Refresh page
        </button>
      </p>
    </div>
  );
};

export default ErrorPage;