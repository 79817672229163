import axios from 'axios';
import {saveCustomerCodeInLocalStorage} from '../frappeServices/api/api'
 
const userDetails = JSON.parse(localStorage.getItem("userDetails"));
let api_key_ = '';
let api_secret_ = '';
 


if(userDetails){
    const {api_key ,api_secret} = userDetails.message || {};
    api_key_ = api_key;
    api_secret_ = api_secret;
}

const logoutUser = () => {
    // Clear user-related data (e.g., localStorage, cookies)
    localStorage.removeItem("userDetails");
    // Navigate to login page
    window.location.href = "/login";
};

const handleError = (error) => {
    if ((error.response && error.response.status === 401) ) {
        // If the response status is 401 (Unauthorized), logout the user
        logoutUser();
    }
    else if (error.response.status === 503 && error.response.data.message.includes('session stopped')) {
        throw new Error('Service Unavailable: Server is updating.');
    }
    else {
        // Handle other errors (e.g., network issues)
        console.error("Error:", error);
    }
    return Promise.reject(error); // Reject the promise with the error object
};


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    if (userDetails) {
        const { api_key, api_secret } = userDetails.message || {};
        if (api_key && api_secret) {
            config.headers['Authorization'] = `token ${api_key}:${api_secret}`;
        }
    }
    return config;
},
    (error) => {
        console.log(`error ${error}`)
        return Promise.reject(error);
    }
);
axiosInstance.interceptors.response.use(
    response => response,
    error => handleError(error)
);

export default axiosInstance;

