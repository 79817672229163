import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import "./JobView.css";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { SiPowerpages } from "react-icons/si";

const JobView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { data } = location.state ? location.state : "";
  if (!data || !data.items) {
    navigate("/promotion");
    return (
      <h1>
        No Data Found <br /> return to promotion page
      </h1>
    );
  }
  return (
    <>
      <div className="d-flex align-items-center flex-wrap">
        <h3 className="mb-0 me-auto">Promotion Details</h3>
        <div></div>
      </div>
      <div className="row">
        <div className="col-xl-3 col-xxl-4">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
              <Card
        className="mb-3"
        style={{ borderRadius: "10px", border: "1px solid #dee2e6" }}
      >
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div>
              <Card.Title
                className="mb-1"
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "bold",
                  marginBottom: "10px",
                }}
              >
                <SiPowerpages
                  style={{
                    color: "red",
                    fontSize: "1.5rem",
                    marginRight: "10px",
                  }}
                />
                {data.name}
              </Card.Title>
              {/* <Card.Subtitle className="mb-2 text-muted">{data.date}</Card.Subtitle> */}
            </div>
            <div>
              <span style={{ color: "black", fontWeight: "bold" }}>
                {data.valid_from}
              </span>
              <span style={{ color: "red", fontWeight: "bold" }}>
                &nbsp;&nbsp;To&nbsp;&nbsp;
              </span>
              <span style={{ color: "black", fontWeight: "bold" }}>
                {data.valid_upto}
              </span>
            </div>
          </div>

          <Card.Text className="mb-1" style={{ fontWeight: "bold" }}>
          <h4 className="font-weight-bold">
            {data.title}
            </h4>
          </Card.Text>
          <div className="d-flex align-items-center mb-1">
            <span>
              {
              data.price_or_product_discount === "Product" ? (
                <>
                {
                  data.same_item === 1  && (
                    <FaArrowAltCircleRight style={{ color: "red", marginRight: "5px" }} />
                  )  
                }
                  <span style={{ fontWeight: "bold" }}>
                    {data.same_item > 0 ? "Same Item" : ""}
                  </span>
                  <span style={{ fontWeight: "bold" }}>
                    {data.free_item.length > 0 &&
                    data.price_or_product_discount === "Product" &&
                    data.same_item < 0
                      ? "Free Item"
                      : ""}
                  </span>
                  {data.free_item.length > 0 &&
                    data.price_or_product_discount === "Product" &&
                    data.same_item === 0 && (
                      <span style={{ fontWeight: "bold" }}>
                        <br />
                        Free Item : <br />
                        {data.free_item} <br />
                      </span>
                    )}
                </>
              ) : (
                ""
                // <>
                //   <FaPlusCircle style={{ color: "red", marginRight: "5px" }} />
                //   <span style={{ fontWeight: "bold" }}>
                //     Free Item : <br />
                //     {data.free_item} <br />
                //   </span>
                // </>
              )}
            </span>
          </div>
          {data.free_qty && data.price_or_product_discount === "Product" ? (
            <Card.Text className="mb-2" style={{ fontWeight: "bold" }}>
              Free Qty: {data.free_qty}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {data.free_item_rate > 0 &&
                data.price_or_product_discount === "Product" && (
                  <span>Free Item Rate: {data.free_item_rate}</span>
                )}
            </Card.Text>
          ) : (
            ""
          )}

          {(data.discount_percentage > 0 ||
            data.discount_amount > 0 ||
            data.rate > 0) &&
          data.price_or_product_discount === "Price" ? (
            <Card.Text className="mb-2" style={{ fontWeight: "bold" }}>
              {data.discount_amount > 0 && (
                <>
                  Discount Amount: {data.discount_amount}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </>
              )}
              {data.discount_percentage > 0 && (
                <>
                  Discount Percentage: {data.discount_percentage}%
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </>
              )}
              {data.rate > 0 && (
                <>
                  Rate After Discount: {data.rate}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </>
              )}
            </Card.Text>
          ) : null}

          <Card.Text className="mb-2" style={{ fontWeight: "bold" }}>
            Min Qty: {data.min_qty}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Max
            Qty: {data.max_qty}
          </Card.Text>

          {data.min_amt > 0 && data.min_amt > 0 ? (
            <Card.Text className="mb-2" style={{ fontWeight: "bold" }}>
              Min Amount: {data.min_amt}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Max Amount:{" "}
              {data.max_amt}
            </Card.Text>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-9 col-xxl-8">
          <div className="row">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body pb-0 scrollable-container">
                  <h4 className="fs-20 mb-3">Items</h4>
                  <Row>
                    {data.items &&
                      data.items.map((item, index) => (
                        <Col xl="4" key={index}>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id={`tooltip-${index}`}>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: item.description,
                                  }}
                                />
                              </Tooltip>
                            }
                          >
                            <Card>
                              <Card.Img
                                variant="top"
                                src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                              />
                              <Card.Body>
                                <Card.Title>{item.item_name}</Card.Title>
                              </Card.Body>
                              <Card.Footer>
                                <small className="text-muted">
                                  Item Code: {item.name}
                                </small>
                              </Card.Footer>
                            </Card>
                          </OverlayTrigger>
                        </Col>
                      ))}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobView;
