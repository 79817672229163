import React, { useState, useContext, useEffect } from "react";
import { useGlobalContext } from "../../../context/GlobalContext";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ThemeContext } from "../../../context/ThemeContext";
import { Modal } from "react-bootstrap";

const inputDate = [
  { title: "Salary Form", subtitle: "$" },
  { title: "Salary To", subtitle: "$" },
  { title: "Enter City", subtitle: "$" },
  { title: "Enter State", subtitle: "State" },
  { title: "Enter Counter", subtitle: "State" },
  { title: "Enter Education Level", subtitle: "Education Level" },
];

const languageOptions = [
  // { value: '', label: 'Select Language' },  // Add this default option
  { value: 'en', label: 'English' },
  { value: 'ar', label: 'العربية' }
];

const Basic = ({ title, subtitle }) => {
  return (
    <div className="col-xl-6 col-md-6 mb-4">
      <label className="form-label font-w600">
        {title}<span className="text-danger ms-1">*</span>
      </label>
      <input
        type="text"
        className="form-control solid"
        placeholder={subtitle}
        aria-label="name"
      />
    </div>
  );
};

const Header = ({ onNote }) => {
  const [userCustomers, setUserCustomers] = useState(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    return storedUserDetails?.message?.user_customers || [];
  });

  const [options, setOptions] = useState(() => {
    const storedUserDetails = JSON.parse(localStorage.getItem("userDetails"));
    const initialUserCustomers = storedUserDetails?.message?.user_customers || [];
    return initialUserCustomers.map((data) => ({ value: data.name, label: data.customer_name }));
  });

  const { setCurrentCustomer, setCustomerName } = useGlobalContext();
  const [selectedOption, setSelectedOption] = useState(() => options[0]?.label);

  // Modified to initialize selectedLanguage from localStorage
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("selected_language");
    if (storedLanguage) {
      const languageOption = languageOptions.find(option => option.value === storedLanguage);
      return languageOption || languageOptions[0];
    }
    return languageOptions[0];
  });

  useEffect(() => {
    const handleStorageChange = () => {
      const storedUserDetails = JSON.parse(localStorage.getItem("userDetails"));
      const updatedUserCustomers = storedUserDetails?.message?.user_customers || [];
      setUserCustomers(updatedUserCustomers);

      const updatedOptions = updatedUserCustomers.map((data) => ({
        value: data.name,
        label: data.customer_name
      }));
      setOptions(updatedOptions);
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const handleCustomerChange = (selected) => {
    setCurrentCustomer(selected.value);
    setCustomerName(selected.label);
    setSelectedOption(selected.label);
    localStorage.setItem("current_customer_code", selected.value);
  };

  const handleLanguageChange = (selected) => {
    setSelectedLanguage(selected);
    localStorage.setItem("selected_language", selected.value);
    window.location.reload();
  };

  const { background, changeBackground } = useContext(ThemeContext);
  const handleThemeMode = () => {
    if (background.value === "dark") {
      changeBackground({ value: "light", label: "Light" });
    } else {
      changeBackground({ value: "dark", label: "Dark" });
    }
  };

  const [jobModal, setJobModal] = useState(false);


  return (
    <>
      <div className="header">
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left">
                <div className="dashboard_bar" style={{ textTransform: "capitalize" }}>
                  <h3>{selectedOption}</h3>
                </div>
              </div>
              <ul className="navbar-nav header-right main-notification">

                <li className="nav-item me-3">
                  <Select
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    options={languageOptions}
                    className="language-select"
                    placeholder="Select Language"
                    style={{
                      lineHeight: "40px",
                      color: "#7e7e7e",
                      paddingLeft: "15px",
                      minWidth: "120px"
                    }}
                  />
                </li>

                <li className="nav-item me-3">
                  <Select
                    defaultValue={options[0]}
                    onChange={handleCustomerChange}
                    options={options}
                    className="customer-select"
                    style={{
                      lineHeight: "40px",
                      color: "#7e7e7e",
                      paddingLeft: "15px",
                    }}
                  />
                </li>
                <li className="nav-item dropdown notification_dropdown">
                  <Link
                    to={"#"}
                    className={`nav-link bell dz-theme-mode p-0 ${background.value === "dark" ? "active" : ""
                      }`}
                    onClick={() => handleThemeMode()}
                  >
                    <i id="icon-light" className="fas fa-sun"></i>
                    <i id="icon-dark" className="fas fa-moon"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <Modal
        className="modal fade bd-example-modal-lg"
        show={jobModal}
        onHide={setJobModal}
        centered
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title">Job Title</h5>
          <button
            type="button"
            className="btn-close"
            onClick={() => setJobModal(false)}
          ></button>
        </div>
        <div className="modal-body">
          <div className="row">
            {/* <div className="col-xl-6  col-md-6 mb-4">
                <label  className="form-label font-w600">Company Name<span className="text-danger ms-1">*</span></label>
                <input type="text" className="form-control solid" placeholder="Name" aria-label="name" />
              </div> */}
            <Basic title="Company Name" subtitle="Name" />
            <Basic title="Position" subtitle="Name" />
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">
                Job Category<span className="text-danger ms-1">*</span>
              </label>
              <select className="nice-select default-select wide form-control solid">
                <option selected>Choose...</option>
                <option>QA Analyst</option>
                <option>IT Manager</option>
                <option>Systems Analyst</option>
              </select>
            </div>
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">
                Job Type<span className="text-danger ms-1">*</span>
              </label>
              <select className="nice-select default-select wide form-control solid">
                <option selected>Choose...</option>
                <option>Part-Time</option>
                <option>Full-Time</option>
                <option>Freelancer</option>
              </select>
            </div>
            <Basic title="No. of Vancancy" subtitle="Name" />
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">
                Select Experience<span className="text-danger ms-1">*</span>
              </label>
              <select className="nice-select default-select wide form-control solid">
                <option selected>1 yr</option>
                <option>2 Yr</option>
                <option>3 Yr</option>
                <option>4 Yr</option>
              </select>
            </div>
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">
                Posted Date<span className="text-danger ms-1">*</span>
              </label>
              <div className="input-group">
                <div className="input-group-text">
                  <i className="far fa-clock"></i>
                </div>
                <input type="date" name="datepicker" className="form-control" />
              </div>
            </div>
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">
                Last Date To Apply<span className="text-danger ms-1">*</span>
              </label>
              <div className="input-group">
                <div className="input-group-text">
                  <i className="far fa-clock"></i>
                </div>
                <input type="date" name="datepicker" className="form-control" />
              </div>
            </div>
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">
                Close Date<span className="text-danger ms-1">*</span>
              </label>
              <div className="input-group">
                <div className="input-group-text">
                  <i className="far fa-clock"></i>
                </div>
                <input type="date" name="datepicker" className="form-control" />
              </div>
            </div>
            <div className="col-xl-6  col-md-6 mb-4">
              <label className="form-label font-w600">
                Select Gender:<span className="text-danger ms-1">*</span>
              </label>
              <select className="nice-select default-select wide form-control solid">
                <option selected>Choose...</option>
                <option>Male</option>
                <option>Female</option>
              </select>
            </div>
            {inputDate.map((data, ind) => (
              <div className="col-xl-6  col-md-6 mb-4" key={ind}>
                <label className="form-label font-w600">
                  {data.title}
                  <span className="text-danger ms-1">*</span>
                </label>
                <input
                  type="text"
                  className="form-control solid"
                  placeholder={data.subtitle}
                  aria-label="name"
                />
              </div>
            ))}
            <div className="col-xl-12 mb-4">
              <label className="form-label font-w600">
                Description:<span className="text-danger ms-1">*</span>
              </label>
              <textarea
                className="form-control solid"
                rows="5"
                aria-label="With textarea"
              ></textarea>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger light"
            onClick={() => setJobModal(false)}
          >
            Close
          </button>
          <button type="button" className="btn btn-primary">
            Save changes
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Header;