import React, { useState, useEffect } from "react";
import { Modal, Nav, Tab } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import classnames from 'classnames';
import axios from 'axios';
import avater1 from "../../../../../images/avatar/1.jpg";
import PageTitle from "../../../../layouts/PageTitle";
import axiosInstance from '../../../../../services/frappeServices/AxiosInstance';
const ProductDetail = () => {
	const [reviewToggle, setReviewToggle] = useState(false);
	const [activeTab, setActiveTab] = useState('0');
	const [product, setProduct] = useState({});
	const { item_code } = useParams();

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab);
	}

	useEffect(() => {
		const fetchProduct = async () => {
			try {
				const response = await axios.get(
					`https://aaaq-stg.frappe.cloud/api/resource/Item/${item_code}`,
					{
						headers: {
							'Authorization': 'token 14a2c5301955e96:c78e753ae651a4d'
						}
					}
				);
				setProduct(response.data.data);
			} catch (error) {
				console.error("Error fetching the product details:", error);
			}
		};

		fetchProduct();
	}, [item_code]);

	const images = product.image ? [product.image, product.image, product.image, product.image] : [];

	return (
		<>
			<PageTitle motherMenu="Layout" activeMenu="Product Detail" />
			<div className="row">
				<div className="col-lg-12">
					<div className="card">
						<div className="card-body">
							<div className="row">
								<div className="col-xl-3 col-lg-6  col-md-6 col-xxl-5 ">
									{/* Tab panes */}
									<Tab.Container defaultActiveKey="first">
										<Tab.Content>
											{images.map((img, index) => (
												<Tab.Pane eventKey={`tab${index}`} key={index}>
													<img className="img-fluid" src={`https://aaaq-stg.frappe.cloud${img}`} alt="" />
												</Tab.Pane>
											))}
										</Tab.Content>
										<div className="tab-slide-content new-arrival-product mb-4 mb-xl-0">
											{/* Nav tabs */}
											<Nav as="ul" className="nav slide-item-list mt-3" role="tablist">
												{images.map((img, index) => (
													<Nav.Item as="li" key={index}>
														<Nav.Link as="a" eventKey={`tab${index}`} to={`#tab${index}`}>
															<img className="img-fluid" src={`https://aaaq-stg.frappe.cloud${img}`} alt="" width={50} />
														</Nav.Link>
													</Nav.Item>
												))}
											</Nav>
										</div>
									</Tab.Container>
								</div>
								{/* Tab slider End */}

								<div className="col-xl-9 col-lg-6  col-md-6 col-xxl-7 col-sm-12">
									<div className="product-detail-content">
										{/* Product details */}
										<div className="new-arrival-content pr">
											<h4>{product.item_name}</h4>
											<div className="comment-review star-rating">
												<ul>
													{" "}<li><i className="fa fa-star" /></li>
													{" "}<li><i className="fa fa-star" /></li>
													{" "}<li><i className="fa fa-star" /></li>
													{" "}<li><i className="fas fa-star-half-alt" /></li>
													{" "}<li><i className="fas fa-star-half-alt" /></li>
												</ul>
												<span className="review-text">(34 reviews) / </span>
												<Link onClick={() => setReviewToggle(true)} className="product-review" to="#" data-toggle="modal" data-target="#reviewModal">Write a review?</Link>
											</div>
											<div className="d-table mb-2">
												<p className="price float-left d-block">${product.dpp_rate}</p>
											</div>
											<p> Availability:{" "}<span className="item">{" "}In stock <i className="fa fa-shopping-basket" /></span></p>
											<p> Product code: <span className="item">{product.item_code}</span>{" "}</p>
											<p>Brand: <span className="item">Lee</span></p>
											<p className="text-content">
												{product.description}
											</p>
											<div className="filtaring-area my-3">
												<div className="size-filter">
													<h4 className="m-b-15">Select size</h4>
													<div className="btn-group" data-toggle="buttons">
														<label className={classnames({ active: activeTab === '1' }) + ' btn btn-outline-primary light btn-sm'} onClick={() => { toggle('1'); }}>
															<input type="radio" className="position-absolute invisible" name="options" id="option5" />{" "}XS
														</label>
														<label className={classnames({ active: activeTab === '2' }) + ' btn btn-outline-primary light btn-sm'} onClick={() => { toggle('2'); }}>
															<input type="radio" className="position-absolute invisible" name="options" id="option1" defaultChecked />SM
														</label>
														<label className={classnames({ active: activeTab === '3' }) + ' btn btn-outline-primary light btn-sm'} onClick={() => { toggle('3'); }}>
															<input type="radio" className="position-absolute invisible" name="options" id="option2" />{" "} MD
														</label>
														<label className={classnames({ active: activeTab === '4' }) + ' btn btn-outline-primary light btn-sm'} onClick={() => { toggle('4'); }}>
															<input type="radio" className="position-absolute invisible" name="options" id="option3" />{" "}LG
														</label>
														<label className={classnames({ active: activeTab === '5' }) + ' btn btn-outline-primary light btn-sm'} onClick={() => { toggle('5'); }}>
															<input type="radio" className="position-absolute invisible" name="options" id="option4" />{" "}XL
														</label>
													</div>
												</div>
											</div>
											{/* Quantity start */}
											<div className="col-2 px-0">
												<input type="number" name="num" className="form-control input-btn input-number" defaultValue={1} />
											</div>
											{/* Quantity End */}
											<div className="shopping-cart mt-3">
												<Link className="btn btn-primary btn-lg" to="/ecom-product-detail"> <i className="fa fa-shopping-basket mr-2" />Add to cart </Link>
												<Link className="btn btn-primary btn-lg" to="/ecom-product-detail"><i className="fa fa-heart mr-2" />Save to wishlist</Link>
											</div>
										</div>
									</div>
								</div>
							</div>
							<Modal className="modal fade" show={reviewToggle} onHide={setReviewToggle}>
								<div className="" role="document">
									<div className="">
										<form>
											<div className="modal-header">
												<h5 className="modal-title">Review</h5>
												<button type="button" className="close" onClick={() => setReviewToggle(false)}><span>×</span></button>
											</div>
											<div className="modal-body">
												<div className="text-center mb-4">
													<img className="img-fluid rounded" width={78} src={avater1} alt="DexignZone" />
												</div>
												<div className="form-group">
													<div className="rating-widget mb-4 text-center">
														{/* Rating Stars Box */}
														<div className="rating-stars">
															<ul id="stars">
																<li className="star" title="Poor" data-value={1}>
																	<i className="fa fa-star fa-fw" />
																</li>
																<li className="star" title="Fair" data-value={2}>
																	<i className="fa fa-star fa-fw" />
																</li>
																<li className="star" title="Good" data-value={3}>
																	<i className="fa fa-star fa-fw" />
																</li>
																<li className="star" title="Excellent" data-value={4}>
																	<i className="fa fa-star fa-fw" />
																</li>
																<li className="star" title="WOW!!!" data-value={5}>
																	<i className="fa fa-star fa-fw" />
																</li>
															</ul>
														</div>
													</div>
												</div>
												<div className="form-group">
													<textarea className="form-control" placeholder="Comment" rows={5} defaultValue={""} />
												</div>
											</div>
											<div className="modal-footer">
												<button type="button" className="btn btn-danger light" onClick={() => setReviewToggle(false)}>Close</button>
												<button type="button" className="btn btn-primary">Submit</button>
											</div>
										</form>
									</div>
								</div>
							</Modal>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ProductDetail;
