import React, { createContext, useState, useContext, useEffect } from 'react';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [currentCustomer, setCurrentCustomer] = useState('');
  const [customerName, setCustomerName] = useState('');

  const updateCustomerDetails = () => {
    const userDetails = JSON.parse(localStorage.getItem('userDetails'));
    if (userDetails && userDetails.message && userDetails.message.user_customers.length > 0) {
      setCurrentCustomer(userDetails.message.user_customers[0].name);
      setCustomerName(userDetails.message.user_customers[0].customer_name);
    }
  };

  useEffect(() => {
    updateCustomerDetails();
    window.addEventListener('storage', updateCustomerDetails);
    return () => {
      window.removeEventListener('storage', updateCustomerDetails);
    };
  }, []);

  return (
    <GlobalContext.Provider value={{ currentCustomer, setCurrentCustomer, customerName, setCustomerName }}>
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalContext = () => useContext(GlobalContext);
