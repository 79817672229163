import React, { useState, useEffect } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import CanvasChart2 from "./TabChart/CanvasChart2";
import { getCustomTargetStatistics } from "../../../../services/frappeServices/api/api";
import { useGlobalContext } from '../../../../context/GlobalContext';
const translations = {
  en: {
    target: "Target",
    actual: "Actual",
    variance: "Variance",
    year2022: "2022",
    year2023: "2023",
    year2024: "2024",
  },
  ar: {
    target: "الهدف",
    actual: "الفعلي",
    variance: "الفرق",
    year2022: "2022",
    year2023: "2023",
    year2024: "2024",
  }
};
const CanvasChartTab = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const [yearsData, setYearsData] = useState(null);
  const { currentCustomer } = useGlobalContext();

  const handleApplyClick = async (year) => {
    try {
       setYear(year);
      const response = await getCustomTargetStatistics(year, currentCustomer);
      setYearsData(response);
    } catch (error) {
      console.log(error);
    }
  };

  const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
  const t = translations[language];

  useEffect(() => {
    handleApplyClick(year);
  }, [currentCustomer]); // Call when currentCustomer changes

  return (
    <>  
      <Tab.Container defaultActiveKey="Monthly">
        <div className="card">
          <div className="card-header border-0 pb-0">
            <h4 className="fs-20 mb-1">{t.target}</h4>
            <div className="card-action coin-tabs mt-3 mt-sm-0">
              <div className="year-input">
                <Nav className="nav nav-tabs" role="tablist">
                  <Nav.Item>
                    <Nav.Link onClick={() => handleApplyClick('2022')} eventKey="Daily">2022</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={() => handleApplyClick('2023')} eventKey="Weekly">2023</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link onClick={() => handleApplyClick('2024')} eventKey="Monthly">2024</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="pb-4 d-flex flex-wrap">
              <span className="me-sm-5 me-3 font-w500">
                <svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                  <rect width="13" height="13" fill="#f73a0b"/>
                </svg>
                {t.target} 
              </span>
              <span className="ms-sm-5 ms-3 font-w500 mr-5">
                <svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                  <rect width="13" height="13" fill="#4BC0C0"/>
                </svg>
                {t.actual}
              </span>
              <span className="me-sm-5 me-3 font-w500">
                <svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                  <rect width="13" height="13" fill="#FFCE56"/>
                </svg>
                {t.variance} 
              </span>
            </div>
            <Tab.Content>
              <Tab.Pane eventKey="Daily">
                <CanvasChart2 className="chartjs" dataActive={0} year={year} statisticsData={yearsData}/>
              </Tab.Pane> 
              <Tab.Pane eventKey="Weekly">
                <CanvasChart2 className="chartjs" dataActive={1} year={year} statisticsData={yearsData} />
              </Tab.Pane> 
              <Tab.Pane eventKey="Monthly">                      
                <CanvasChart2 className="chartjs" dataActive={2} year={year} statisticsData={yearsData} />
              </Tab.Pane> 
            </Tab.Content> 
          </div>
        </div> 
      </Tab.Container>      
    </>
  );
};

export default CanvasChartTab;
