import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import { Row, Spinner, Col } from "react-bootstrap";
import SchemaCard from "./schemaCard";
import { get_promotion_schema } from "../../../services/frappeServices/api/api";
import DatePicker from "react-datepicker"; // Import DatePicker
import "react-datepicker/dist/react-datepicker.css"; // Import DatePicker CSS
import { useGlobalContext } from "../../../context/GlobalContext";
import { format } from 'date-fns';

const UiListGroup = () => {
  const { currentCustomer } = useGlobalContext();
  const [open, setOpen] = useState(false);
  const [schemaArr, setSchemaArr] = useState([]);
  const [loading, setLoading] = useState(true);

  // State to manage filter inputs
  const [itemName, setItemName] = useState("");
  const [fromDate, setFromDate] = useState(null); // State for from_date
  const [toDate, setToDate] = useState(null);     // State for to_date

  const handleClearFilterClick = () => {
    setItemName("");
    setFromDate(null);
    setToDate(null);  
    fetchPromotion();
  };
  const translations = {
    en: {
      filter:"Filter",
      fromDate:"From Date",
      toDate:"To Date",
      clearFilter:"Clear Filter",
      promotionName:"promotion Name",
      noPro:"No Promotion Found"
    },
    ar: {
      
      filter:"فلتر",
      fromDate:"من تاريخ",
      toDate:"الي تاريخ",
      clearFilter:"محو الفلتر ",
      promotionName:"اسم العرض",
      noPro:"لا توجد عروض"
       
    }
  };
  const fetchPromotion = async (filters = {}) => {
    setLoading(true);
    try {
      const { message } = await get_promotion_schema(filters, currentCustomer);
      setSchemaArr(message);
      console.log("Filtered Data:", message);
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  const handleApplyClick = () => {
    const filters = {
      ...(itemName && { promotion_title: itemName }),
      ...(fromDate && { from_date: format(fromDate, 'yyyy-MM-dd') }),
      ...(toDate && { to_date: format(toDate, 'yyyy-MM-dd') }),
    };
    fetchPromotion(filters);
  };
  
  const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
  const t = translations[language];
  useEffect(() => {
    fetchPromotion();
  }, [currentCustomer]);

  return (
    <Fragment>
      {loading ? (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ width: "100%" }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div className="row">
          <div className="col-xl-12">
            <div className="filter cm-content-box box-primary">
              <div className="content-title">
                <div className="cpa">
                  <i className="fas fa-filter me-2"></i>
                  <span>{t.filter}</span>
                </div>
                <div className="tools">
                  <Link
                    to={"#"}
                    className={`SlideToolHeader ${open ? "collapse" : "expand"}`}
                    onClick={() => setOpen(!open)}
                  >
                    <i className="fas fa-angle-up"></i>
                  </Link>
                </div>
              </div>
              <Collapse in={open}>
                <div className="cm-content-body form excerpt">
                  <div className="card-body">
                    <div className="row filter-row">
                      {/* Remove Nav component and its items */}
                      <div className="col-xl-12 col-xxl-12 mt-2">
                        <p>{t.promotionName}</p>
                        <input
                          type="text"
                          className="form-control mb-xl-0 mb-3"
                          placeholder="Promotion Name"
                          value={itemName}
                          onChange={(e) => setItemName(e.target.value)}
                        />
                      </div>

                      {/* From Date Picker */}
                      <div className="col-xl-6 col-xxl-6 mt-2">
                        <p>{t.fromDate}</p>
                        <DatePicker
                          selected={fromDate}
                          onChange={(date) => setFromDate(date)}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                          placeholderText="Valid From"
                        />
                      </div>

                      {/* To Date Picker */}
                      <div className="col-xl-6 col-xxl-6 mt-2">
                        <p>{t.toDate}</p>
                        <DatePicker
                          selected={toDate}
                          onChange={(date) => setToDate(date)}
                          dateFormat="yyyy-MM-dd"
                          className="form-control"
                          placeholderText="Valid To"
                        />
                      </div>

                      <div className="col-xl-3 col-xxl-6">
                        <p>&nbsp;</p>
                        <button
                          className="btn btn-primary me-2"
                          title="Click here to Search"
                          type="button"
                          onClick={handleApplyClick}
                        >
                          <i className="fa fa-search me-1"></i>Filter
                        </button>
                      </div>

                      <div className="col-xl-3 col-xxl-6">
                        <p>&nbsp;</p>
                        <button
                          className="btn btn-primary me-2"
                          title="Click here to Clear"
                          type="button"
                          onClick={handleClearFilterClick}
                        >
                          <i className="fa fa-eraser me-1"></i>{t.clearFilter}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </div>

          {/* Promotion Cards */}
          <div className="col-xl-12">
            <Row>
              {schemaArr.length > 0 ? (
                schemaArr.map((item, index) => (
                  <Col xl="4" key={index}>
                    <SchemaCard data={item} />
                  </Col>
                ))
              ) : (
                <p>{t.noPro}</p>
              )}
            </Row>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default UiListGroup;

