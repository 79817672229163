import React, { Fragment, useEffect, useState } from "react";
// import axios from "axios";
import { Button, Dropdown, ListGroup, Modal } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import axiosInstance from '../../../../../services/frappeServices/AxiosInstance';
import PageTitle from "../../../../layouts/PageTitle";
import "./product.css";
import "./model.css";


import styled, { keyframes } from 'styled-components';

import { useGlobalContext } from "../../../../../context/GlobalContext";

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }

  .modal-body {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    color: #333;
  }

  &.show {
    animation: ${fadeIn} 0.3s ease-out;
  }

  &.hide {
    animation: ${fadeOut} 0.3s ease-in;
  }
`;

const translations = {
   en: {
      cart: "Cart",
      updatingCartItems: "Updating cart items...",
      yourCartIsEmpty: "Your cart is empty.",
      freeItem: "(Free Item)",
      freeItemRate: "Free Item Rate",
      freeQty: "Free Qty",
      priceNotAvailable: "Price not available",
      price: "Price",
      rate: "Rate",
      discountRate: "Discount Rate",
      rateBeforeDiscount: "Rate Before Discount",
      total: "Total",
      code: "Code",
      Next:"Next",
      Filter_Products:"Filter Products",
      netTotal: "Net Total",
      page: "Page",
      addtocard:"Add To Card",
      totalTaxes: "Total Taxes",
      grandTotal: "Grand Total",
      excludesItemsWithUnavailablePrices: "(Excludes items with unavailable prices)",
      proceed: "Proceed",
      Show_Details:"  Show Details",
      stock:"Stock",
      filter: "Filter",
      to: "To",
      view: "View",
      close: "Close",
      loading: "Loading...",
      error: "Error fetching item details or price. Please try again later.",
      name: "Name",
      customer: "Customer",
      date: "Date",
      status: "Status",
      Previous:"Previous",
      items: "Items",
      itemCode: "Item Code",
      itemName: "Item Name",
      itemImage: "Item Image",
      quantity: "Quantity",
      rate: "Rate",
      total: "Total",
      noItemsFound: "No items found",
      netTotal: "Net Total",
      totalTaxes: "Total Taxes",
      grandTotal: "Grand Total",
      print: "Print",
      close: "Close",
      productCode: "Product code",
      brand: "Brand",
      uom: "UOM",
      category: "Category",
      description: "Description",
      noDescription: "No description available",
         freeItem: "Free Item",
         rate: "Rate",
         freeQty: "Free Qty",
         freeItemRate: "Free Item Rate",
         Apply_Filters:"Apply Filters",
         Clear_Filters:"Clear Filters",
         rate: "Rate",
         discountRate: "Discount Rate",
         rateBeforeDiscount: "Rate Before Discount",
         productDetails: "Product Details",
         loading: "Loading...",
         error: "Error fetching price details. Please try again later.",
         productCode: "Product code",
         alternativeItem: "Alternative Item",
         uom: "UOM",
         category: "Category",
         brand: "Brand",
         description: "Description",
         noDescription: "No description available",
         priceNotAvailable: "Price not available",
         freeItem: "Free Item",
         itemCode: "Item Code",
         itemName: "Item Name",
         freeQty: "Free Qty",
      Featured_Items:"Featured Items",
         freeItemRate: "Free Item Rate",
         rate: "Rate",
         discountRate: "Discount Rate",
         rateBeforeDiscount: "Rate Before Discount",
         loading: "Loading...",
         error: "Failed to load SRFQ details. Please try again.",
         name: "Name",
         customer: "Customer",
         date: "Date",
         status: "Status",
         items: "Items",
         itemCode: "Item Code",
         itemName: "Item Name",
         itemImage: "Item Image",
         quantity: "Quantity",
         rate: "Rate",
         total: "Total",
         noItemsFound: "No items found",
         netTotal: "Net Total",
         totalTaxes: "Total Taxes",
         grandTotal: "Grand Total",
         print: "Print",
         close: "Close",
         requestSent: "Request sent successfully!",
         view: "View"

      },
      ar: {
         filter: "تصفية",
         to: "إلى",
         view: "عرض",
         close: "إغلاق",
         loading: "جار التحميل...",
         error: "خطأ في جلب تفاصيل العنصر أو السعر. يرجى المحاولة مرة أخرى.",
         name: "الاسم",
         customer: "العميل",
         date: "التاريخ",
         Next:"القادم",
         Previous:"سابق",
         status: "الحالة",
         items: "العناصر",
         addtocard:"اضف اليي السله",
         itemCode: "رمز العنصر",
         itemName: "اسم العنصر",
         Filter_Products:"محو الاصناف",
         itemImage: "صورة العنصر",
         Page:"الصفحه",
                  quantity: "الكمية",
         rate: "السعر",
         Apply_Filters: "اضافه فلتر",
         Clear_Filters: "مسح الفلتر",
         total: "المجموع",
         Featured_Items:"المنتجات المميزه" ,
         noItemsFound: "لم يتم العثور على عناصر",
         netTotal: "صافي المجموع",
         totalTaxes: "إجمالي الضرائب",
         grandTotal: "المجموع الكلي",
         print: "طباعة",
         close: "إغلاق",
         productCode: "رمز المنتج",
         Show_Details: "شاهد التفاصيل",
         stock: "مخزون",
         brand: "العلامة التجارية",
         uom: "الوحدة",
         category: "الفئة",
         description: "الوصف",
         noDescription: "لا يوجد وصف",
         freeItem: "العنصر المجاني",
         rate: "السعر",
         freeQty: "الكمية المجانية",
         freeItemRate: "سعر العنصر المجاني",
         rate: "السعر",
         discountRate: "سعر الخصم",
         rateBeforeDiscount: "السعر قبل الخصم",
         productDetails: "تفاصيل المنتج",
         loading: "جار التحميل...",
         error: "خطأ في جلب تفاصيل السعر. يرجى المحاولة مرة أخرى.",
         productCode: "رمز المنتج",
         alternativeItem: "عنصر بديل",
         uom: "الوحدة",
         category: "الفئة",
         brand: "العلامة التجارية",
         description: "الوصف",
         noDescription: "لا يوجد وصف",
         priceNotAvailable: "السعر غير متوفر",
         rate: "السعر",
         discountRate: "سعر الخصم",
         rateBeforeDiscount: "السعر قبل الخصم",
         freeItem: "العنصر المجاني",
         itemCode: "رمز العنصر",
         itemName: "اسم العنصر",
         freeQty: "الكمية المجانية",
         cart: "سلة",
         updatingCartItems: "تحديث عناصر السلة...",
         yourCartIsEmpty: "سلتك فارغة.",
         freeItem: "(عنصر مجاني)",
         freeItemRate: "سعر العنصر المجاني",
         freeQty: "الكمية المجانية",
         priceNotAvailable: "السعر غير متوفر",
         price: "السعر",
         rate: "معدل",
         discountRate: "سعر الخصم",
         rateBeforeDiscount: "السعر قبل الخصم",
         total: "الإجمالي",
         code: "رمز",
         netTotal: "الصافي الكلي",
         totalTaxes: "إجمالي الضرائب",
         grandTotal: "الإجمالي الكلي",
         excludesItemsWithUnavailablePrices: "(يستبعد العناصر ذات الأسعار غير المتوفرة)",
         proceed: "تقدم",
         freeItemRate: "سعر العنصر المجاني",
         loading: "جار التحميل...",
         error: "فشل في تحميل تفاصيل طلب عرض الأسعار. يرجى المحاولة مرة أخرى.",
         name: "الاسم",
         customer: "العميل",
         date: "التاريخ",
         status: "الحالة",
         items: "العناصر",
         itemCode: "رمز العنصر",
         itemName: "اسم العنصر",
         itemImage: "صورة العنصر",
         quantity: "الكمية",
         rate: "السعر",
         total: "المجموع",
         noItemsFound: "لم يتم العثور على عناصر",
         netTotal: "صافي المجموع",
         totalTaxes: "إجمالي الضرائب",
         grandTotal: "المجموع الكلي",
         print: "طباعة",
         close: "إغلاق",
         requestSent: "تم إرسال الطلب بنجاح!",
         view: "عرض"
      }
   }





const ConfirmationModal = ({ show, message, onHide, onViewClick }) => {

   const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
   const t = translations[language];
   return (
      <StyledModal show={show} onHide={onHide} centered>
         <Modal.Body>

            <p>{message}</p>
            <Button onClick={onViewClick} variant="primary" className="mr-2" >
               {t.view}
            </Button>
            <Button onClick={onHide} variant="secondary" >
               {t.close}
            </Button>


         </Modal.Body>
      </StyledModal>
   );
};



const SRFQDetailsModal = ({ show, onHide, srfqName }) => {
   const [modalData, setModalData] = useState(null);
   const [loading, setLoading] = useState(true);
   const [error, setError] = useState(null);
   const [hidePriceCategories, setHidePriceCategories] = useState([]);
   const { currentCustomer } = useGlobalContext();
   const [sumTotal, setSumTotal] = useState(0);
   const [hasHiddenPrice, setHasHiddenPrice] = useState(false);

   const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
   const t = translations[language];
   useEffect(() => {
      if (show && srfqName) {
         fetchSRFQDetails(srfqName);
         fetchHidePriceCategories();
      } else {
         resetState();
      }
   }, [show, srfqName]);

   const resetState = () => {
      setModalData(null);
      setLoading(true);
      setError(null);
      setSumTotal(0);
      setHasHiddenPrice(false);
   };

   const fetchHidePriceCategories = async () => {
      try {
         const response = await axiosInstance.post(
            `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_items_by_category`,
            { category: "Project", customer: currentCustomer }
         );
         if (response.data && response.data.message) {
            setHidePriceCategories(response.data.message.map(item => item.name));
         }
      } catch (error) {
         console.error("Error fetching hide price categories:", error);
      }
   };

   const fetchSRFQDetails = async (name) => {
      setLoading(true);
      setError(null);
      setHasHiddenPrice(false);
      try {
         const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/resource/Sales Request For Quotation/${name}`);
         const srfqData = response.data.data;

         const itemsWithCategory = await Promise.all(
            srfqData.srfq_item.map(async (item) => {
               const itemResponse = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/resource/Item/${item.item_code}`);
               return { ...item, custom_category: itemResponse.data.data.custom_category };
            })
         );

         setModalData({ ...srfqData, srfq_item: itemsWithCategory });

         let total = 0;
         let hidden = false;

         itemsWithCategory.forEach(item => {
            if (!hidePriceCategories.includes(item.custom_category)) {
               if (item.rate !== "Price not available") {
                  total += item.qty * parseFloat(item.rate);
               }
            } else {
               hidden = true;
            }
         });

         setSumTotal(total);
         setHasHiddenPrice(hidden);

      } catch (error) {
         console.error("Error fetching SRFQ details:", error);
         setError("Failed to load SRFQ details. Please try again.");
      } finally {
         setLoading(false);
      }
   };

   const handleClose = () => {
      onHide();
      resetState();
   };

   // Calculate Net Total, Total Taxes, and Grand Total
   const netTotal = modalData ? modalData.srfq_item.reduce((acc, item) => {
      if (!hidePriceCategories.includes(item.custom_category) && item.rate !== "Price not available") {
         return acc + (item.qty * parseFloat(item.rate));
      }
      return acc;
   }, 0).toFixed(2) : "0.00";

   const totalTaxes = (parseFloat(netTotal) * 0.15).toFixed(2);
   const grandTotal = (parseFloat(netTotal) + parseFloat(totalTaxes)).toFixed(2);

   return (
      <Modal show={show} onHide={handleClose} centered size="xl">
         <Modal.Header closeButton>
            <Modal.Title>SRFQ Details</Modal.Title>
         </Modal.Header>
         <Modal.Body>
            {loading && <p>{t.loading}...</p>}
            {error && <p className="text-danger">{error}</p>}
            {modalData && (
               <>
                  <p><strong>{t.name}:</strong> {modalData.name}</p>
                  <p><strong>{t.customer}:</strong> {modalData.customer}</p>
                  <p><strong>{t.date}:</strong> {modalData.date}</p>
                  <p><strong>{t.status}:</strong> {modalData.workflow_state}</p>
                  <div>
                     <h3>{t.items}</h3>
                     {modalData.srfq_item && modalData.srfq_item.length > 0 ? (
                        <table className="custom-table">
                           <thead>
                              <tr>
                                 <th>{t.itemCode}</th>
                                 <th>{t.itemName}</th>
                                 <th>{t.itemImage}</th>
                                 <th>{t.quantity}</th>
                                 <th>{t.rate}</th>
                                 <th>{t.total}</th>
                              </tr>
                           </thead>
                           <tbody>
                              {modalData.srfq_item.map((item, index) => (
                                 <tr key={index}>
                                    <td className="scrollable-text">{item.item_code}</td>
                                    <td className="scrollable-text">{item.item_name}</td>
                                    <td>
                                       {item.image && item.image !== "" ? (
                                          <img
                                             src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                             alt="Item"
                                          />
                                       ) : (
                                          <img
                                             src="https://www.shutterstock.com/image-vector/no-image-available-vector-illustration-260nw-744886198.jpg"
                                             alt="Default"
                                          />
                                       )}
                                    </td>
                                    <td>{item.qty}</td>
                                    <td>
                                       {hidePriceCategories.includes(item.custom_category)
                                          ? "Price not available"
                                          : `${item.rate} SAR`}
                                    </td>
                                    <td>
                                       {hidePriceCategories.includes(item.custom_category)
                                          ? "Total not available"
                                          : `${(item.qty * parseFloat(item.rate)).toFixed(2)} SAR`}
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     ) : (
                        <p>{t.noItemsFound}</p>
                     )}
                  </div>
                  {/* Net Total, Total Taxes, and Grand Total in separate divs */}
                  <div className="totals-container">
                     <p className="total-item">
                        <strong>{t.netTotal}:</strong> <span>{netTotal} SAR</span>
                     </p>
                     <p className="total-item">
                        <strong>{t.totalTaxes}:</strong> <span>{totalTaxes} SAR</span>
                     </p>
                     <p className="total-item">
                        <strong>{t.grandTotal}:</strong> <span>{grandTotal} SAR</span>
                     </p>
                     <div className="d-flex justify-content-center"> {/* Center the buttons */}
                        {!modalData.srfq_item.some(item => hidePriceCategories.includes(item.custom_category)) && (
                           <a
                              href={`${process.env.REACT_APP_BASE_URL}/printview?doctype=Sales%20Request%20For%20Quotation&name=${modalData.name}&format=SRFQ%20V00`}
                              target="_blank"
                              rel="noopener noreferrer"
                           >
                              <Button variant="primary" className="mr-2">{t.print}</Button>
                           </a>
                        )}
                        <Button variant="secondary" onClick={handleClose}>{t.close}</Button>
                     </div>
                  </div>

               </>
            )}

         </Modal.Body>
         <Modal.Footer>

         </Modal.Footer>
      </Modal>
   );
};


const AlertModal = ({ show, message, onHide, onViewClick }) => {
   const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
   const t = translations[language];
   useEffect(() => {
      if (show) {
         const timer = setTimeout(() => {
            onHide();
         }, 3000);
         return () => clearTimeout(timer);
      }
   }, [show]);

   return (
      <StyledModal
         show={show}
         onHide={onHide}
         centered
         className={show ? 'show' : 'hide'}
      >
         <Modal.Body>
            <p>{message}</p>
            {message === "Request sent successfully!" && (
               <Button onClick={onViewClick} variant="primary" className="mt-2">
                  {t.view}
               </Button>
            )}
         </Modal.Body>
      </StyledModal>
   );
};

// Modal Component for Alternative Item Details
const AlternativeItemModal = ({ show, onHide, itemName }) => {
   const [itemDetails, setItemDetails] = useState(null);
   const [priceDetails, setPriceDetails] = useState(null);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
   const t = translations[language];
   const { currentCustomer } = useGlobalContext();
   useEffect(() => {
      if (itemName) {
         const fetchItemDetails = async () => {
            setLoading(true);
            setError(null);
            try {
               const response = await axiosInstance.get(
                  `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_items`,
                  {
                     params: {
                        customer: currentCustomer,
                        search_txt: itemName
                     }
                  }
               );
               setItemDetails(response.data.message[0]);

               const priceResponse = await axiosInstance.post(
                  `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_price_by_item_code`,
                  {
                     item_code: response.data.message[0].item_code,
                     customer: currentCustomer,
                     qty: 1
                  }
               );
               setPriceDetails(priceResponse.data.message);

            } catch (error) {
               setError('Error fetching item details or price. Please try again later.');
               console.error("Error fetching item details or price:", error);
            } finally {
               setLoading(false);
            }
         };

         fetchItemDetails();
      }
   }, [itemName]);

   if (!itemDetails) return null;

   return (
      <Modal size="lg" show={show} onHide={onHide} centered className="modal-custom">
         <Modal.Header closeButton>
            <Modal.Title>{itemDetails.item_name}</Modal.Title>
         </Modal.Header>
         <Modal.Body className="modal-body-scroll">
            {loading && <p>{t.loading}...</p>}
            {error && <p className="text-danger">{error}</p>}
            <div className="row">
               <div className="col-xl-3 col-lg-6 col-md-6 col-xxl-5">
                  {itemDetails.image && (
                     <img
                        className="img-fluid"
                        src={`${process.env.REACT_APP_BASE_URL}${itemDetails.image}`}
                        alt={itemDetails.item_name}
                     />
                  )}
               </div>
               <div className="col-xl-9 col-lg-6 col-md-6 col-xxl-7 col-sm-12">
                  <div className="product-detail-content">
                     <h4>{itemDetails.item_name}</h4>
                     <div className="d-table mb-2">
                        <p className="price float-left d-block">{priceDetails?.price_list_rate}</p>
                     </div>
                     <p>{t.productCode}: <span className="item">{itemDetails.item_code}</span></p>
                     <p>{t.brand}: <span className="item">{itemDetails.brand}</span></p>
                     <p>{t.uom}: <span className="item">{itemDetails.UOM}</span></p>
                     <p>{t.category}: <span className="item">{itemDetails.custom_category}</span></p>
                     <p>{t.description}: <span className="item">{itemDetails.description || 'No description available'}</span></p>
                     {priceDetails && priceDetails.free_item_data && priceDetails.free_item_data.length > 0 && (
                        <div className="free-item-details">
                           <p>{t.freeItem}: {priceDetails.free_item_data[0].item_code}</p>
                           <p>{t.rate}: {priceDetails.price_list_rate}</p>
                           <p>{t.freeQty}: {priceDetails.free_item_data[0].qty}</p>
                           <p>{t.freeItemRate}: {priceDetails.free_item_data[0].rate}</p>
                        </div>
                     )}
                     {priceDetails && priceDetails.rate_discount != null && (
                        <div className="discount-details">
                           <p>{t.rate}: {priceDetails.price_list_rate}</p>
                           <p>{t.discountRate}: {priceDetails.rate_discount}</p>
                           <p>{t.rateBeforeDiscount}: {priceDetails.formatted_mrp}</p>
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </Modal.Body>
         <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>{t.close}</Button>
         </Modal.Footer>
      </Modal>
   );
};

const ProductDetailsModal = ({ show, onHide, product, onAlternativeItemClick, onAddToCart, hidePriceCategories }) => {
   const [priceDetails, setPriceDetails] = useState(null);
   const [freeItemDetails, setFreeItemDetails] = useState(null);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   const { currentCustomer } = useGlobalContext();

   const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
   const t = translations[language];
   useEffect(() => {
      if (show && product) {
         const fetchPriceDetails = async () => {
            setLoading(true);
            setError(null);
            try {
               const response = await axiosInstance.post(
                  `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_price_by_item_code`,
                  {
                     item_code: product.title2,
                     customer: currentCustomer,
                     qty: 1
                  }
               );
               setPriceDetails(response.data.message);

               // If there's a free item, fetch its details
               if (response.data.message.free_item_data && response.data.message.free_item_data.length > 0) {
                  const freeItemCode = response.data.message.free_item_data[0].item_code;
                  const freeItemResponse = await axiosInstance.get(
                     `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_items`,
                     {
                        params: {
                           customer: currentCustomer,
                           search_txt: freeItemCode
                        }
                     }
                  );
                  setFreeItemDetails(freeItemResponse.data.message[0]);
               }
            } catch (error) {
               setError('Error fetching price details. Please try again later.');
               console.error("Error fetching price details:", error);
            } finally {
               setLoading(false);
            }
         };

         fetchPriceDetails();
      }
   }, [show, product]);

   if (!product) return null;
   const shouldHidePrice = hidePriceCategories.includes(product.custom_category);
   return (
      <Modal show={show} onHide={onHide} size="lg" centered className="modal-custom">
         <Modal.Header closeButton>
            <Modal.Title>{t.productDetails}</Modal.Title>
         </Modal.Header>
         <Modal.Body className="modal-body-scroll">
            {loading && <p>{t.loading}...</p>}
            {error && <p className="text-danger">{error}</p>}
            <div className="row">
               <div className="col-xl-3 col-lg-6 col-md-6 col-xxl-5">
                  {product.previewImg && (
                     <img className="img-fluid" src={product.previewImg} alt={product.title} />
                  )}
               </div>
               <div className="col-xl-9 col-lg-6 col-md-6 col-xxl-7 col-sm-12">
                  <div className="product-detail-content">
                     <h4>{product.title}</h4>
                     <div className="d-table mb-2">
                        {!shouldHidePrice && <p className="price float-left d-block">{product.price}</p>}
                        {shouldHidePrice && <p className="price float-left d-block">{t.priceNotAvailable}</p>}
                     </div>
                     <p>{t.productCode}: <span className="item">{product.title2}</span></p>
                     {product.isEOL === 1 && (
                        <h5>
                           {t.alternativeItem}: <span style={{ color: "red", cursor: "pointer", borderBottom: "1px solid red" }} onClick={() => onAlternativeItemClick(product.alternativeItem)}>{product.alternativeItem}</span>
                        </h5>
                     )}
                     <p>{t.uom}: <span className="item">{product.UOM}</span></p>
                     <p>{t.category}: <span className="item">{product.custom_category}</span></p>
                     <p>{t.brand}: <span className="item">{product.brand}</span></p>
                     <p>{t.description}: <span className="item">{product.description || 'No description available'}</span></p>
                     {priceDetails && priceDetails.free_item_data && priceDetails.free_item_data.length > 0 && (
                        <div className="free-item-details">
                           <h5>{t.freeItem}:</h5>
                           <p>{t.itemCode}: {priceDetails.free_item_data[0].item_code}</p>
                           {freeItemDetails && (
                              <>
                                 <p>{t.itemName}: {freeItemDetails.item_name}</p>
                                 <p>{t.description}: {freeItemDetails.description || 'No description available'}</p>
                                 <img
                                    src={freeItemDetails.image ? `${process.env.REACT_APP_BASE_URL}${freeItemDetails.image}` : "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"}
                                    alt={freeItemDetails.item_name}
                                    style={{ maxWidth: '100px', maxHeight: '100px' }}
                                 />
                              </>
                           )}
                           <p>{t.freeQty}: {priceDetails.free_item_data[0].qty}</p>
                           <p>{t.freeItemRate}: {priceDetails.free_item_data[0].rate}</p>
                        </div>
                     )}
                     {priceDetails && priceDetails.rate_discount != null && (
                        <div className="discount-details">
                           <p>{t.rate}: {priceDetails.price_list_rate}</p>
                           <p>{t.discountRate} {priceDetails.rate_discount}</p>
                           <p>{t.rateBeforeDiscount}: {priceDetails.formatted_mrp}</p>
                        </div>
                     )}
                  </div>
               </div>
            </div>
         </Modal.Body>
         <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>{t.close}</Button>
         </Modal.Footer>
      </Modal>
   );
};

const calculateTotalPrice = (cartItems) => {
   return cartItems.reduce((total, item) => {
      if (item.isFreeItem) return total;
      const itemPrice = item.priceDetails?.rate || item.price;
      return total + (itemPrice * item.quantity);
   }, 0);
};

const CartModal = ({ show, onHide, cartItems, setCartItems, onRemoveItem, clearCart, updateItemQuantity, showConfirmation }) => {
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   const { currentCustomer } = useGlobalContext();


   const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
   const t = translations[language];







   const calculateTotalPrice = (cartItems) => {
      return cartItems.reduce((total, item) => {
         if (item.isFreeItem || item.hidePrice) return total;
         const itemPrice = item.priceDetails?.rate || item.price;
         return total + (itemPrice * item.quantity);
      }, 0);
   };

   const totalPrice = calculateTotalPrice(cartItems);

   useEffect(() => {
      if (show) {
         updateCartItemsDetails();
      }
   }, [show]);

   const updateCartItemsDetails = async () => {
      setLoading(true);
      setError(null);

      try {
         const updatedCartItems = await Promise.all(cartItems.map(async (item) => {
            if (item.isFreeItem) return item;

            const priceResponse = await axiosInstance.post(
               `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_price_by_item_code`,
               {
                  item_code: item.code,
                  customer: currentCustomer,
                  qty: item.quantity
               }
            );
            const priceDetails = priceResponse.data.message;

            let updatedItem = { ...item };

            // Update price details if they exist
            if (priceDetails.price_list_rate || priceDetails.rate_discount || priceDetails.formatted_mrp) {
               updatedItem.priceDetails = {};
               if (priceDetails.price_list_rate) updatedItem.priceDetails.rate = priceDetails.price_list_rate;
               if (priceDetails.rate_discount) updatedItem.priceDetails.discountRate = priceDetails.rate_discount;
               if (priceDetails.formatted_mrp) updatedItem.priceDetails.rateBeforeDiscount = priceDetails.formatted_mrp;
            } else {
               delete updatedItem.priceDetails;
            }

            // Check for free items
            if (priceDetails.free_item_data && priceDetails.free_item_data.length > 0) {
               const freeItemData = priceDetails.free_item_data[0];
               const freeItemQuantity = freeItemData.qty;

               // Check if free item already exists in cart
               const existingFreeItemIndex = cartItems.findIndex(
                  cartItem => cartItem.isFreeItem && cartItem.code === freeItemData.item_code
               );

               if (existingFreeItemIndex !== -1) {
                  // Update existing free item
                  cartItems[existingFreeItemIndex].quantity = freeItemQuantity;
               } else {
                  // Add new free item
                  cartItems.push({
                     name: freeItemData.item_name || "Free Item",
                     price: 0,
                     quantity: freeItemQuantity,
                     image: item.image, // You might want to fetch the correct image for the free item
                     code: freeItemData.item_code,
                     isFreeItem: true,
                     freeItemRate: freeItemData.rate,
                     associatedMainItemCode: item.code
                  });
               }
            }

            return updatedItem;
         }));

         setCartItems(updatedCartItems);
      } catch (error) {
         console.error("Error updating cart items:", error);
         setError("Failed to update cart items. Please try again.");
      } finally {
         setLoading(false);
      }
   };

   const handleProceed = async () => {
      const srfqItems = cartItems
         .filter(item => !item.isFreeItem)
         .map(item => ({
            item_code: item.code,
            qty: item.quantity,
         }));

      console.log("srfqItems before sending:", srfqItems);

      const body = {
         customer: currentCustomer,
         srfq_item: srfqItems
      };

      try {
         const response = await axiosInstance.post(
            `${process.env.REACT_APP_BASE_URL}/api/resource/Sales Request For Quotation`,
            body
         );

         const latestSRFQResponse = await axiosInstance.get(
            `${process.env.REACT_APP_BASE_URL}/api/resource/Sales Request For Quotation`,
            {
               params: {
                  order_by: 'creation desc',
                  limit_page_length: 1
               }
            }
         );

         if (latestSRFQResponse.data && latestSRFQResponse.data.data && latestSRFQResponse.data.data.length > 0) {
            const latestSRFQName = latestSRFQResponse.data.data[0].name;
            console.log("Successfully sent data. Latest SRFQ:", latestSRFQName);
            showConfirmation("Request sent successfully!", latestSRFQName);
            clearCart();
            onHide();
         } else {
            console.error("No Sales Request For Quotation found in the response");
            showConfirmation("Request sent successfully, but couldn't retrieve the latest request details.");
         }
      } catch (error) {
         console.error("Error sending data:", error);
         showConfirmation("Failed to send request.");
      }
   };

   const handleQuantityChange = async (index, newQuantity) => {
      const item = cartItems[index];

      if (item.isFreeItem) return; // Don't allow changes for free items

      if (newQuantity < 1) newQuantity = 1; // Ensure quantity is at least 1

      try {
         const priceResponse = await axiosInstance.post(
            `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_price_by_item_code`,
            {
               item_code: item.code,
               customer: currentCustomer,
               qty: newQuantity
            }
         );
         const priceDetails = priceResponse.data.message;

         // Update main item quantity and price details
         const newCartItems = [...cartItems];
         newCartItems[index] = {
            ...newCartItems[index],
            quantity: newQuantity,
            price: priceDetails.price_list_rate,
            priceDetails: {
               rate: priceDetails.price_list_rate,
               discountRate: priceDetails.rate_discount,
               rateBeforeDiscount: priceDetails.formatted_mrp
            }
         };

         // Only add priceDetails if they exist
         if (priceDetails.price_list_rate || priceDetails.rate_discount || priceDetails.formatted_mrp) {
            newCartItems[index].priceDetails = {};
            if (priceDetails.price_list_rate) newCartItems[index].priceDetails.rate = priceDetails.price_list_rate;
            if (priceDetails.rate_discount) newCartItems[index].priceDetails.discountRate = priceDetails.rate_discount;
            if (priceDetails.formatted_mrp) newCartItems[index].priceDetails.rateBeforeDiscount = priceDetails.formatted_mrp;
         } else {
            delete newCartItems[index].priceDetails;
         }

         // Handle free item
         const freeItemIndex = newCartItems.findIndex(cartItem =>
            cartItem.isFreeItem && cartItem.associatedMainItemCode === item.code
         );

         if (priceDetails.free_item_data && priceDetails.free_item_data.length > 0) {
            const freeItemData = priceDetails.free_item_data[0];
            const newFreeQuantity = freeItemData.qty;

            if (freeItemIndex !== -1) {
               if (newFreeQuantity > 0) {
                  // Update existing free item
                  newCartItems[freeItemIndex].quantity = newFreeQuantity;
               } else {
                  // Remove free item if quantity becomes zero
                  newCartItems.splice(freeItemIndex, 1);
               }
            } else if (newFreeQuantity > 0) {
               // Add new free item
               newCartItems.push({
                  name: freeItemData.item_name || "Free Item",
                  price: 0,
                  quantity: newFreeQuantity,
                  image: item.image,
                  code: freeItemData.item_code,
                  isFreeItem: true,
                  freeItemRate: freeItemData.rate,
                  associatedMainItemCode: item.code
               });
            }
         } else {
            // Remove any existing free item if there's no free item data
            if (freeItemIndex !== -1) {
               newCartItems.splice(freeItemIndex, 1);
            }
         }

         setCartItems(newCartItems);
      } catch (error) {
         console.error("Error updating quantity:", error);
         alert('Error updating quantity. Please try again.');
      }
   };



   const netTotal = cartItems.reduce((acc, item) => {
      if (item.isFreeItem || item.hidePrice) return acc; // Skip free or hidden price items
      const itemPrice = item.priceDetails?.rate || item.price;
      return acc + itemPrice * item.quantity;
   }, 0);

   const totalTaxes = netTotal * 0.15; // Assuming a 15% tax rate
   const grandTotal = netTotal + totalTaxes;


   return (
      <Modal show={show} onHide={onHide} size="lg" centered className="modal-custom">
         <Modal.Header closeButton>
            <Modal.Title>{t.cart}</Modal.Title>
         </Modal.Header>

         <Modal.Body>
            {loading && <p>{t.updatedCartItems}...</p>}
            {error && <p className="text-danger">{error}</p>}

            {cartItems.length === 0 ? (
               <p>{t.yourCartIsEmpty}.</p>
            ) : (
               <div className="cart-items-scrollable">
                  <ListGroup>
                     {cartItems.map((item, index) => (
                        <ListGroup.Item
                           key={index}
                           className={`cart-item ${item.isFreeItem ? 'free-item' : ''}`}
                        >
                           <div className="d-flex align-items-center">
                              <img src={item.image} alt={item.name} className="cart-item-image" />

                              <div className="cart-item-details">
                                 <h5>
                                    {item.name} {item.isFreeItem && <span className="free-item-label">({t.freeItem})</span>}
                                 </h5>
                                 <p>{t.code}: {item.code}</p>

                                 {item.isFreeItem ? (
                                    <>
                                       <p>{t.freeItemRate}: {item.freeItemRate} SAR</p>
                                       <p>{t.freeQty}: {item.quantity}</p>
                                    </>
                                 ) : (
                                    <>
                                       {item.hidePrice ? (
                                          <p>{t.priceNotAvailable}</p>
                                       ) : (
                                          <>
                                             <p>{t.price}: {item.price} SAR</p>

                                             {item.priceDetails && (
                                                <div className="discount-details">
                                                   {item.priceDetails.rate && <p>{t.rate}: {item.priceDetails.rate} SAR</p>}
                                                   {item.priceDetails.discountRate && <p>{t.discountRate}: {item.priceDetails.discountRate} SAR</p>}
                                                   {item.priceDetails.rateBeforeDiscount && <p>{t.rateBeforeDiscount}: {item.priceDetails.rateBeforeDiscount} SAR</p>}
                                                </div>
                                             )}
                                             <p>{t.total}: {(item.price * item.quantity).toFixed(2)} SAR</p>
                                          </>
                                       )}
                                    </>
                                 )}

                                 <div className="quantity-control">
                                    <button
                                       className="quantity-button"
                                       onClick={() => handleQuantityChange(index, item.quantity - 1)}
                                    >
                                       -
                                    </button>
                                    <input
                                       type="number"
                                       className="quantity-input1"
                                       value={item.quantity}
                                       onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                                       min="1"
                                    />
                                    <button
                                       className="quantity-button"
                                       onClick={() => handleQuantityChange(index, item.quantity + 1)}
                                    >
                                       +
                                    </button>
                                 </div>
                              </div>

                              {!item.isFreeItem && (
                                 <button className="delete-button" onClick={() => onRemoveItem(index)}>
                                    <FaTrash />
                                 </button>
                              )}
                           </div>
                        </ListGroup.Item>
                     ))}
                  </ListGroup>
               </div>
            )}

            <div className="total-price">
               {totalPrice > 0 ? (
                  <>
                     <h4>{t.netTotal}: {netTotal.toFixed(2)} SAR</h4>
                     <h4>{t.totalTaxes}: {totalTaxes.toFixed(2)} SAR</h4>
                     <h4>{t.grandTotal}: {grandTotal.toFixed(2)} SAR</h4>
                  </>
               ) : (
                  <p>({t.excludesItemsWithUnavailablePrices})</p>
               )}
            </div>


         </Modal.Body>

         <Modal.Footer>
            <Button variant="primary" onClick={handleProceed}>{t.proceed}</Button>
            <Button variant="secondary" onClick={onHide}>{t.close}</Button>
         </Modal.Footer>
      </Modal>
   );
};







const ProductGrid = () => {
   const [allProducts, setAllProducts] = useState([]);
   const [displayedProducts, setDisplayedProducts] = useState([]);
   const [searchText, setSearchText] = useState("");
   const [brands, setBrands] = useState([]);
   const [categories, setCategories] = useState([]);
   const [subCategories, setSubCategories] = useState([]);
   const [selectedBrand, setSelectedBrand] = useState("");
   const [selectedCategory, setSelectedCategory] = useState("");
   const [selectedSubCategory, setSelectedSubCategory] = useState("");
   const [subCategoryDropdowns, setSubCategoryDropdowns] = useState([]);
   const [isEOL, setIsEOL] = useState(0);
   const [showFilterModal, setShowFilterModal] = useState(false);
   const [activeFilter, setActiveFilter] = useState('');
   const [showDetailsModal, setShowDetailsModal] = useState(false);
   const [selectedProduct, setSelectedProduct] = useState(null);
   const [showAltItemModal, setShowAltItemModal] = useState(false);
   const [alternativeItemName, setAlternativeItemName] = useState("");
   const [cartItems, setCartItems] = useState([]);
   const [showCart, setShowCart] = useState(false);
   const [showAlertModal, setShowAlertModal] = useState(false);
   const [alertMessage, setAlertMessage] = useState('');
   const [hidePriceCategories, setHidePriceCategories] = useState([]);
   const [currentPage, setCurrentPage] = useState(1);
   const [totalPages, setTotalPages] = useState(1);
   const itemsPerPage = 16;
   const { currentCustomer } = useGlobalContext();
   const [showSRFQModal, setShowSRFQModal] = useState(false);
   const [currentSRFQName, setCurrentSRFQName] = useState('');
   const [isFeatured, setIsFeatured] = useState(1);
   const [isLoading, setIsLoading] = useState(false);
   const [showConfirmationModal, setShowConfirmationModal] = useState(false);
   const [confirmationMessage, setConfirmationMessage] = useState('');
   const [selectedBrands, setSelectedBrands] = useState("");

   const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
   const t = translations[language];
   const showConfirmation = (message, srfqName = null) => {
      setConfirmationMessage(message);
      if (srfqName) {
         setCurrentSRFQName(srfqName);
      }
      setShowConfirmationModal(true);
   };
   const showAlert = (message, srfqName = null) => {
      setAlertMessage(message);
      if (srfqName) {
         setCurrentSRFQName(srfqName);
      }
      setShowAlertModal(true);
   };
   const handleViewClick = () => {
      setShowConfirmationModal(false);
      if (currentSRFQName) {
         setShowSRFQModal(true);
      } else {
         console.error("No SRFQ name available");
         showConfirmation("Unable to view SRFQ details. Please try again.");
      }
   };

   const handleCloseConfirmation = () => {
      setShowConfirmationModal(false);
      setCurrentSRFQName('');
   };
   const handleCloseSRFQModal = () => {
      setShowSRFQModal(false);
      setCurrentSRFQName('');
   };
   const updateItemQuantity = (index, newQuantity) => {
      setCartItems(prevItems => {
         const newItems = [...prevItems];
         newItems[index].quantity = newQuantity;
         return newItems;
      });
   };
   const clearCart = () => {
      setCartItems([]);
   };
   const handleRemoveItem = (index) => {
      setCartItems(prevItems => {
         const newItems = [...prevItems];
         const removedItem = newItems[index];

         // Remove the main item
         newItems.splice(index, 1);

         // If the removed item was not a free item, look for and remove its associated free item
         if (!removedItem.isFreeItem) {
            const freeItemIndex = newItems.findIndex(item =>
               item.isFreeItem && item.associatedMainItemCode === removedItem.code
            );
            if (freeItemIndex !== -1) {
               newItems.splice(freeItemIndex, 1);
            }
         }

         return newItems;
      });
   };
   const handleAddToCart = (product, quantity, freeItem = null, priceDetails = null, shouldHidePrice = false) => {
      // if (priceDetails.stock_balance <= 0) {
      //    showAlert(`Sorry, "${product.title}" is currently out of stock.`);
      //    return; // Exit the function without adding to cart
      // }

      setCartItems(prevItems => {
         const newItems = [...prevItems];

         // Add main item
         const existingItemIndex = newItems.findIndex(item => item.code === product.title2);
         if (existingItemIndex !== -1) {
            const newQuantity = newItems[existingItemIndex].quantity + quantity;
            newItems[existingItemIndex].quantity = newQuantity;
            // const maxQuantity = priceDetails.stock_balance || Infinity;
            // if (maxQuantity === 0) {
            //    showAlert(`Sorry, "${product.title}" is now out of stock.`);
            //    return prevItems;
            // }
            // newItems[existingItemIndex].quantity = Math.min(newQuantity, maxQuantity);
            // newItems[existingItemIndex].stockLimited = maxQuantity !== Infinity;
            // newItems[existingItemIndex].stockBalance = maxQuantity;
         } else {
            newItems.push({
               name: product.title,
               price: shouldHidePrice ? null : product.price,
               quantity: quantity,
               image: product.previewImg,
               code: product.title2,
               isFreeItem: false,
               hidePrice: shouldHidePrice
            });
         }

         // Add free item if exists (assuming free items don't have stock limitations)
         if (freeItem) {
            const freeItemIndex = newItems.findIndex(item => item.code === freeItem.item_code && item.isFreeItem);
            if (freeItemIndex !== -1) {
               newItems[freeItemIndex].quantity += quantity * (priceDetails.free_item_data[0].qty || 1);
            } else {
               newItems.push({
                  name: freeItem.item_name,
                  price: 0,
                  quantity: priceDetails.free_item_data[0].qty,
                  image: freeItem.image,
                  code: freeItem.item_code,
                  isFreeItem: true,
                  freeItemRate: priceDetails.free_item_data[0].rate,
                  associatedMainItemCode: product.title2
               });
            }
         }

         return newItems;
      });

      // showAlert('Product added to cart');
   };




   const fetchProducts = async (searchText = "", brands = "", category = "", subCategory = "", isEOL = 0, isFeatured = 1, page = 1) => {
      setIsLoading(true);
      try {
         const response = await axiosInstance.get(
            `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_items`,
            {
               params: {
                  customer: currentCustomer,
                  search_txt: searchText,
                  brand: brands,
                  category: subCategory || category,
                  custom_eol_item: isEOL ? 1 : 0,
                  custom_featured_item: isFeatured ? 1 : 0,
                  portal: 1,
                  page,
                  paging: 1,
                  page_size: itemsPerPage
               }
            }
         );
         if (response.data && response.data.message) {
            const { num_of_pages, items } = response.data.message;

            const transformedProducts = items.map((item) => ({
               key: item.name,
               previewImg: item.image ? `${process.env.REACT_APP_BASE_URL}${item.image}` : "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg",
               title: item.item_name,
               title2: item.item_code,
               UOM: item.stock_uom,
               custom_category: item.custom_category,
               brand: item.brand,
               alternativeItem: item.custom_alternative_item,
               isEOL: item.custom_eol_item,
               rating: item.custom_publish_customer_portal || 0,
               price: item.price_list_rate,
               description: item.description || '',
            }));



            setDisplayedProducts(transformedProducts);
            setTotalPages(num_of_pages);
         } else {
            console.error("Unexpected API response structure:", response);
            setDisplayedProducts([]);
            setTotalPages(1);
         }
      } catch (error) {
         console.error("Error fetching the product data:", error);
      }
      finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      fetchProducts(searchText, selectedBrands, selectedCategory, selectedSubCategory, isEOL, isFeatured, currentPage);
   }, [searchText, selectedBrands, selectedCategory, selectedSubCategory, isEOL, isFeatured, currentPage]);


   const handleBrandChange = (brand) => {
      setSelectedBrands(prevBrands => {
         const brandArray = prevBrands ? prevBrands.split(',') : [];
         if (brandArray.includes(brand)) {
            return brandArray.filter(b => b !== brand).join(',');
         } else {
            return [...brandArray, brand].join(',');
         }
      });
   };






   const handleNextPage = () => {
      if (currentPage < totalPages) {
         setCurrentPage(prevPage => prevPage + 1);
      }
   };

   const handlePrevPage = () => {
      if (currentPage > 1) {
         setCurrentPage(prevPage => prevPage - 1);
      }
   };




   const fetchHidePriceCategories = async () => {
      try {
         const response = await axiosInstance.post(
            `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_items_by_category`,
            {
               category: "Project",
               customer: currentCustomer
            }
         );
         if (response.data && response.data.message) {
            setHidePriceCategories(response.data.message.map(item => item.name));
         }
      } catch (error) {
         console.error("Error fetching hide price categories:", error);
      }
   };



   const updateDisplayedProducts = (products, page) => {
      const startIndex = (page - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      setDisplayedProducts(products.slice(startIndex, endIndex));
   };


   const fetchBrands = async () => {
      try {
         const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/resource/Brand?fileds=["name"]&limit=*&filters=[["custom_publish_customer_portal", "=" , 1]]`);
         setBrands(response.data.data.map((brand) => brand.name));
      } catch (error) {
         console.error("Error fetching brands:", error);
      }
   };

   const fetchCategories = async () => {
      try {
         const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/resource/Category?fields=["name","parent_category"]&filters=[["parent_category", "is", "not set"]]`);
         setCategories(response.data.data.map((category) => category.name));
      } catch (error) {
         console.error("Error fetching categories:", error);
      }
   };

   const fetchSubCategories = async (categoryName) => {
      try {
         const response = await axiosInstance.post(
            `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.kpis.get_child_category`,
            {
               category: categoryName,
               customer: currentCustomer
            }
         );
         const newSubCategories = response.data.message.map((subcategory) => subcategory.name);
         return newSubCategories;
      } catch (error) {
         console.error("Error fetching subcategories:", error);
         return [];
      }
   };


   useEffect(() => {
      fetchHidePriceCategories();
   }, []);

   useEffect(() => {
      fetchProducts(searchText, selectedBrand, selectedCategory, selectedSubCategory, isEOL);
   }, [searchText, selectedBrand, selectedCategory, selectedSubCategory, isEOL]);

   useEffect(() => {
      updateDisplayedProducts(allProducts, currentPage);
   }, [currentPage, allProducts]);


   useEffect(() => {
      if (showFilterModal) {
         // Reset filters when modal opens
         setActiveFilter('');
         setSelectedBrand('');
         setSelectedCategory('');
         setSelectedSubCategory('');
         setSubCategoryDropdowns([]);
         setIsEOL(0);
      }
   }, [showFilterModal]);

   const handleSearchChange = (event) => {
      setSearchText(event.target.value);
      setIsFeatured(0); // Clear featured items filter
      fetchProducts(event.target.value, selectedBrand, selectedCategory, selectedSubCategory, isEOL, 0, 1);
   };

   const handleFilterButtonClick = () => {
      setActiveFilter('');
      setSelectedBrand("");
      setSelectedCategory("");
      setSelectedSubCategory("");
      setSubCategoryDropdowns([]);
      setIsEOL(0);
      setIsFeatured(0); // Clear featured items filter
      setShowFilterModal(true);
      fetchBrands();
      fetchCategories();
   };


   const handleApplyFilters = () => {
      setShowFilterModal(false);
      fetchProducts(searchText, selectedBrands, selectedCategory, selectedSubCategory, isEOL, isFeatured);
   };

   const handleClearFilters = () => {
      setSelectedBrands(""); // Clear selected brands
      setSelectedCategory("");
      setSelectedSubCategory("");
      setSubCategoryDropdowns([]);
      setIsEOL(0);
      setIsFeatured(0);
      setSearchText("");
      setCurrentPage(1);
      setShowFilterModal(false);

      fetchProducts("", "", "", "", 0, 1, 1);
   };
   const handleCategoryChange = async (category) => {
      setSelectedCategory(category);
      setSelectedSubCategory("");
      const newSubCategories = await fetchSubCategories(category);
      setSubCategoryDropdowns([{ category, subCategories: newSubCategories }]);
   };

   const handleSubCategoryChange = async (subCategory, level) => {
      setSelectedSubCategory(subCategory);
      const newSubCategories = await fetchSubCategories(subCategory);
      setSubCategoryDropdowns((prevDropdowns) => {
         const updatedDropdowns = prevDropdowns.slice(0, level + 1);
         updatedDropdowns[level].selectedSubCategory = subCategory;
         if (newSubCategories.length > 0) {
            updatedDropdowns.push({ category: subCategory, subCategories: newSubCategories });
         }
         return updatedDropdowns;
      });
   };

   const handleShowDetails = (product) => {
      setSelectedProduct(product);
      setShowDetailsModal(true);
   };

   const handleCloseDetailsModal = () => {
      setShowDetailsModal(false);
      setSelectedProduct(null);
   };

   const handleAlternativeItemClick = (itemName) => {
      setAlternativeItemName(itemName);
      setShowDetailsModal(false);
      setShowAltItemModal(true);
   };

   const handleCloseAltItemModal = () => {
      setShowAltItemModal(false);
      setAlternativeItemName("");
   };

   const renderFilterContent = () => {
      const selectedBrandArray = selectedBrands ? selectedBrands.split(',') : [];
      return (
         <div className="filter-content">
            <div className="filter-section">
               <h5>{t.brand}</h5>
               <div className="checkbox-list">
                  {brands.map((brand) => (
                     <div key={brand} className="form-check">
                        <input
                           className="form-check-input"
                           type="checkbox"
                           id={`brand-${brand}`}
                           checked={selectedBrandArray.includes(brand)}
                           onChange={() => handleBrandChange(brand)}
                        />
                        <label className="form-check-label" htmlFor={`brand-${brand}`}>
                           {brand}
                        </label>
                     </div>
                  ))}
               </div>
            </div>

            <div className="filter-section">
               <h5>EOL</h5>
               <div className="form-check">
                  <input
                     className="form-check-input"
                     type="checkbox"
                     checked={isEOL}
                     onChange={(e) => setIsEOL(e.target.checked)}
                     id="eolCheck"
                  />
                  <label className="form-check-label" htmlFor="eolCheck">
                     (EOL) {t.items}
                  </label>
               </div>
            </div>
            <div className="filter-section">
               <h5>{t.Featured_Items}</h5>
               <div className="form-check">
                  <input
                     className="form-check-input"
                     type="checkbox"
                     checked={isFeatured}
                     onChange={(e) => setIsFeatured(e.target.checked)}
                     id="featuredCheck"
                  />
                  <label className="form-check-label" htmlFor="featuredCheck">
                     {t.Featured_Items}
                  </label>
               </div>
            </div>
            <div className="category-filter">
               <h5 className="filter-title">{t.category}</h5>
               <div className="custom-dropdown">
                  <button className="dropdown-toggle">
                     {selectedCategory || "Select Category"}
                     <span className="dropdown-arrow">▼</span>
                  </button>
                  <ul className="dropdown-menu">
                     {categories.map((category) => (
                        <li key={category} onClick={() => handleCategoryChange(category)}>
                           {category}
                        </li>
                     ))}
                  </ul>
               </div>

               {subCategoryDropdowns.map((dropdown, index) => (
                  <div className="custom-dropdown" key={index}>
                     <button className="dropdown-toggle">
                        {dropdown.selectedSubCategory || `Select Subcategory ${index + 1}`}
                        <span className="dropdown-arrow">▼</span>
                     </button>
                     <ul className="dropdown-menu">
                        {dropdown.subCategories.map((subCategory) => (
                           <li key={subCategory} onClick={() => handleSubCategoryChange(subCategory, index)}>
                              {subCategory}
                           </li>
                        ))}
                     </ul>
                  </div>
               ))}
            </div>


         </div>
      );
   };

   return (
      <Fragment>
         <PageTitle activeMenu="Sales Request For Qutation" />

         {displayedProducts.length > 0 && (
            <div className="pagination-controls" style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
               <Button onClick={handlePrevPage} disabled={currentPage === 1}>{t.Previous}</Button>
               <span style={{
                  margin: '0 10px',
                  padding: '10px 15px',
                  backgroundColor: '#f0f0f0',
                  borderRadius: '15px',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: '#333',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
               }}>{t.page} {currentPage} of {totalPages}</span>
               <Button onClick={handleNextPage} disabled={currentPage === totalPages}>{t.Next}</Button>
            </div>
         )}

         <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button onClick={() => setShowCart(true)}>{t.view} {t.cart} ({cartItems.length})</Button>
            <Button style={{ minWidth: "150px" }} onClick={handleFilterButtonClick}>{t.filter}</Button>
         </div>
         <div className="search-bar-container">
            <input
               type="text"
               className="search-bar"
               placeholder="Search Items..."
               value={searchText}
               onChange={handleSearchChange}
            />
         </div>
         <div className="row">
            {displayedProducts.length > 0 ? (
               displayedProducts.map((product) => (
                  <ProductCard
                     key={product.key}
                     product={product}
                     onShowDetails={handleShowDetails}
                     onAddToCart={handleAddToCart}
                     showAlert={showAlert}
                     hidePriceCategories={hidePriceCategories}
                  />
               ))
            ) : (
               <div className="col-12 text-center">
                  <p> .........</p>
               </div>
            )}
         </div>

         {/* Filter Modal */}
         <Modal
            show={showFilterModal}
            onHide={() => setShowFilterModal(false)}
            centered
            className="filter-modal"
            size="lg"
         >
            <Modal.Header closeButton>
               <Modal.Title>{t.Filter_Products}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
               {renderFilterContent()}
            </Modal.Body>
            <Modal.Footer>
               <Button variant="secondary" onClick={() => setShowFilterModal(false)}>{t.close}</Button>
               <Button variant="primary" onClick={handleApplyFilters}>{t.Apply_Filters}</Button>
               <Button variant="danger" onClick={handleClearFilters}>{t.Clear_Filters}</Button>
            </Modal.Footer>
         </Modal>

         {/* Product Details Modal */}
         <ProductDetailsModal
            show={showDetailsModal}
            onHide={handleCloseDetailsModal}
            product={selectedProduct}
            onAlternativeItemClick={handleAlternativeItemClick}
            onAddToCart={handleAddToCart}
            hidePriceCategories={hidePriceCategories}
         />

         {/* Alternative Item Modal */}
         <AlternativeItemModal
            show={showAltItemModal}
            onHide={handleCloseAltItemModal}
            itemName={alternativeItemName}
            onAddToCart={handleAddToCart}
         />
         <CartModal
            show={showCart}
            onHide={() => setShowCart(false)}
            cartItems={cartItems}
            setCartItems={setCartItems}
            onRemoveItem={handleRemoveItem}
            clearCart={clearCart}
            updateItemQuantity={updateItemQuantity}
            showAlert={showAlert}
            showConfirmation={showConfirmation}  // Add this line
         />
         <AlertModal
            show={showAlertModal}
            message={alertMessage}
            onHide={() => setShowAlertModal(false)}
            onViewClick={handleViewClick}
         />

         <ConfirmationModal
            show={showConfirmationModal}
            message={confirmationMessage}
            onHide={handleCloseConfirmation}
            onViewClick={handleViewClick}
         />

         <SRFQDetailsModal
            show={showSRFQModal}
            onHide={handleCloseSRFQModal}
            srfqName={currentSRFQName}
         />
      </Fragment>
   );
};

const ProductCard = ({ product, onShowDetails, onAddToCart, hidePriceCategories }) => {

   const { previewImg, title, isEOL, price, title2 } = product;
   const [localQty, setLocalQty] = useState(1);
   const [loading, setLoading] = useState(false);
   const [error, setError] = useState(null);
   const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
   const t = translations[language];
   const [stockBalance, setStockBalance] = useState(null);
   const { currentCustomer } = useGlobalContext();
   const handleQuantityChange = (e) => {
      const newQty = parseInt(e.target.value, 10);
      if (newQty > 0) {
         setLocalQty(newQty);
      }
   };
   const shouldHidePrice = hidePriceCategories.includes(product.custom_category);

   const handleAddToCart = async () => {
      setLoading(true);
      setError(null);
      try {
         const priceResponse = await axiosInstance.post(
            `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_price_by_item_code`,
            {
               item_code: product.title2,
               customer: currentCustomer,
               qty: localQty
            }
         );
         const priceDetails = priceResponse.data.message;

         // Check and set stock balance
         if (priceDetails.stock_balance !== undefined) {
            setStockBalance(priceDetails.stock_balance <= 0 ? 0 : null);
         }

         let freeItem = null;
         if (priceDetails.free_item_data && priceDetails.free_item_data.length > 0) {
            const freeItemResponse = await axiosInstance.get(
               `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_items`,
               {
                  params: {
                     customer: currentCustomer,
                     search_txt: priceDetails.free_item_data[0].item_code
                  }
               }
            );
            const freeItemDetails = freeItemResponse.data.message[0];
            freeItem = {
               item_code: freeItemDetails.item_code,
               item_name: freeItemDetails.item_name,
               image: freeItemDetails.image ? `${process.env.REACT_APP_BASE_URL}${freeItemDetails.image}` : null
            };
         }

         const cartPriceDetails = {};
         if (priceDetails.price_list_rate) cartPriceDetails.rate = priceDetails.price_list_rate;
         if (priceDetails.rate_discount) cartPriceDetails.discountRate = priceDetails.rate_discount;
         if (priceDetails.formatted_mrp) cartPriceDetails.rateBeforeDiscount = priceDetails.formatted_mrp;

         onAddToCart(product, localQty, freeItem, priceDetails, shouldHidePrice);

         // Reset the quantity to 1 after successfully adding to cart
         setLocalQty(1);
      } catch (error) {
         setError('Error adding to cart. Please try again.');
         console.error("Error adding to cart:", error);
      } finally {
         setLoading(false);
      }
   };

   return (
      <div className="col-xl-2 col-xxl-3 col-md-4 col-sm-6">
         <div className="card product-card ">
            <div className="card-body">
               <div className="new-arrival-product">
                  {isEOL === 1 && <div className="eol-label">EOL {t.items}</div>}
                  <div className="new-arrivals-img-contnent">
                     <img className="img-fluid" src={previewImg} alt="" />
                  </div>
                  <div className="new-arrival-content text-center mt-3">
                     <h5>{title}</h5>
                     <h6>{title2}</h6>
                     {!shouldHidePrice && <span className="price">{price} SAR </span>}
                     {shouldHidePrice && <span className="price">{t.priceNotAvailable}</span>}
                     <div className="quantity mt-2">
                        <input
                           type="number"
                           value={localQty}
                           min="1"
                           onChange={handleQuantityChange}
                           className="quantity-input1"
                        />
                     </div>
                     {stockBalance !== null && (
                        <div className="stock-info mt-2">
                           {t.stock}: {stockBalance}
                        </div>
                     )}
                  </div>
                  <div className="button-group mt-2">
                     <button className="custom-button btn-primary" onClick={() => onShowDetails(product)}>
                        {t.Show_Details}
                     </button>
                     <button className="custom-button btn-success" onClick={handleAddToCart} disabled={loading}>
                        {loading ? `Adding...` : `${t.addtocard}`}
                     </button>
                  </div>
                  {error && <div className="text-danger mt-2">{error}</div>}
               </div>
            </div>
         </div>
      </div>
   );
};

export default ProductGrid;