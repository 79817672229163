import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { Dropdown, Toast } from "react-bootstrap";
import { useGlobalContext } from "../../../context/GlobalContext";
//Import Components
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

import { ThemeContext } from "../../../context/ThemeContext";
import VacanyTab from "../Jobick/Home/VacanyTab";
import CanvasChartTab from "../Jobick/Home/CanvasChartTab";
import FeaturedCompanies from "../Jobick/Home/FeaturedCompanies";
import RecentActivity from "../Jobick/Home/RecentActivity";
import HomeSlider from "../Jobick/Home/HomeSlider";
import NetworkStatistics from "../Jobick/Home/NetworkStatistics";
import { callApi } from "../../../services/frappeServices/db/db";
import NumericLabel from "react-pretty-numbers";
import {
  getTotalStatistics,
  getCustomerCode,
  getTopBrands,
} from "../../../services/frappeServices/api/api";
import { Row, Card, Col, Alert, Button } from "react-bootstrap";

//import FinancialChartMultipleData from './FinancialChartMultipleData';

//Images
import pic1 from "./../../../images/profile/pic1.jpg";


const translations = {
  en: {
    KPIs: "KPIs",
    GrandTotal: "Grand Total",
    Nettotal: "Net total",
    Taxes: "Taxes",
    discount: "discount",
    outstanding: "outstanding ",
    overdue: "overdue",
    credit_limit: "credit limit",
    Top_brand: "Top Brand"

  },
  ar: {
    KPIs: "مؤشر الاداء",
    outstanding: "المبلغ المستحق",
    overdue: "المبلغ المتأخر",
    credit_limit: "",
    GrandTotal: "المبلغ الكلي",
    Nettotal: "المبلغ الصافي",
    Taxes: "الضريبة",
    discount: "الخصم",
    Top_brand: "الاكثر شراء"


  }
};

//Components
const NewCustomers1 = loadable(() =>
  pMinDelay(import("../Jobick/Home/NewCustomers1"), 1000)
);
const NewCustomers2 = loadable(() =>
  pMinDelay(import("../Jobick/Home/NewCustomers2"), 1000)
);
const NewCustomers3 = loadable(() =>
  pMinDelay(import("../Jobick/Home/NewCustomers3"), 1000)
);
const NewCustomers4 = loadable(() =>
  pMinDelay(import("../Jobick/Home/NewCustomers4"), 1000)
);
const ApexPieChart = loadable(() =>
  pMinDelay(import("../Jobick/Home/ApexPieChart"), 1000)
);

const Home = () => {
  const { currentCustomer } = useGlobalContext();

  const [statistics, setStatistics] = useState(null);
  const [topBrands, setTopBrands] = useState([]);

  const [showToast, setShowToast] = useState(true);

  const [showAlert, setShowAlert] = useState(true); // State to control alert visibility

  const { changeBackground, background } = useContext(ThemeContext);

  useEffect(() => {
    // Custom CSS as a string
    const styles = `
      .custom-toast .Toastify__toast {
        color:blue
        font-size: 50px !important; /* Increase text size */
        background-color: red !important; /* Set background color to red */
      }
    
      .custom-toast .Toastify__progress-bar {
        background-color: grey !important; /* Set progress bar color to grey */
      }
    `;

    // Create a <style> element and append it to the head
    const styleSheet = document.createElement("style");
    styleSheet.type = "text/css";
    styleSheet.innerText = styles;
    document.head.appendChild(styleSheet);

    const showWelcomeToast = localStorage.getItem("showWelcomeToast");

    if (showWelcomeToast === "true") {
      const userDetails = JSON.parse(localStorage.getItem("userDetails"));
      const total_due = Math.round(
        userDetails.message.user_customers[0]["total dues"]
      ).toLocaleString();

      // Show the toast notification
      toast.error(
        `Welcome, ${userDetails.full_name}! Your total dues are ${total_due} .`,
        {
          className: "custom-toast", // Apply custom class
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );

      // Set the flag to false to ensure it only shows once
      localStorage.setItem("showWelcomeToast", "false");
    }

    const fetchStatistics = async (currentCustomer) => {
      try {
        const { message } = await getTotalStatistics(currentCustomer);
        console.log("grand total message is ", message);
        setStatistics(message);
      } catch (error) {
        console.error("Error fetching statistics:", error);
      }
    };

    fetchStatistics(currentCustomer);

    const fetchTopBrnads = async (currentCustomer) => {
      try {
        const { message } = await getTopBrands(currentCustomer);
        console.log("top brands is ", message);
        setTopBrands(message);
      } catch (error) {
        console.log("error fetching  top brands", error);
      }
    };
    fetchTopBrnads(currentCustomer);

    changeBackground({ value: "light", label: "Light" });

    // Set a timeout to hide the toast after 5 seconds
    const timer = setTimeout(() => setShowToast(false), 5000);

    // Cleanup the timeout on component unmount
    return () => clearTimeout(timer);
  }, [currentCustomer]);

  const [control3, setControl3] = useState("Newest");
  const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
  const t = translations[language];
  return (
    <>
      <ToastContainer />
      <div className="row">
        <div className="col-xl-6">
          <div className="row">
            <div className="col-xl-12">
              <div className="card fixed-kpi-height">
                <div className="card-body">
                  <div className="col text-center">
                    {statistics ? (
                      <h4 className="fs-20">{statistics[0].year}</h4>
                    ) : <p>Loading...</p>}
                  </div>
                  <div className="row separate-row">
                    <div className="col-sm-6">
                      <div className="job-icon pb-4 d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center mb-1">
                            {statistics ? (
                              <h2 className="mb-0 lh-1">
                                {" "}
                                <NumericLabel params={{ shortFormat: true }}>
                                  {Number(
                                    parseInt(statistics[0].grand_total)
                                  ) || 0}
                                </NumericLabel>
                              </h2>
                            ) : (
                              <p>Loading...</p>
                            )}
                          </div>
                          <span className="fs-14 d-block mb-2">
                            {t.GrandTotal}
                          </span>
                        </div>
                        <div id="NewCustomers">
                          <NewCustomers1 />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="job-icon pb-4 pt-4 pt-sm-0 d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center mb-1">
                            {statistics ? (
                              <h2 className="mb-0 lh-1">
                                {" "}
                                <NumericLabel params={{ shortFormat: true }}>
                                  {Number(parseInt(statistics[0].net_total)) ||
                                    0}
                                </NumericLabel>
                              </h2>
                            ) : (
                              <p>Loading...</p>
                            )}
                          </div>
                          <span className="fs-14 d-block mb-2">{t.Nettotal}</span>
                        </div>
                        <div id="NewCustomers1">
                          <NewCustomers2 />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="job-icon pt-4 pb-sm-0 pb-4 pe-3 d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center mb-1">
                            {statistics ? (
                              <h2 className="mb-0 lh-1">
                                {" "}
                                <NumericLabel params={{ shortFormat: true }}>
                                  {Number(
                                    parseInt(statistics[0].total_taxes)
                                  ) || 0}
                                </NumericLabel>
                              </h2>
                            ) : (
                              <p>Loading...</p>
                            )}
                            <span className="text-danger ms-3"></span>
                          </div>
                          <span className="fs-14 d-block mb-2">{t.Taxes}</span>
                        </div>
                        <div id="NewCustomers2">
                          <NewCustomers3 />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="job-icon pt-4  d-flex justify-content-between">
                        <div>
                          <div className="d-flex align-items-center mb-1">
                            {statistics ? (
                              <h2 className="mb-0 lh-1">
                                {" "}
                                <NumericLabel params={{ shortFormat: true }}>
                                  {Number(
                                    parseInt(statistics[0]["discount"])
                                  ) || 0}
                                </NumericLabel>
                              </h2>
                            ) : (
                              <p>Loading...</p>
                            )}
                          </div>
                          <span className="fs-14 d-block mb-2">{t.discount}</span>
                        </div>
                        <div id="NewCustomers3">
                          <NewCustomers4 />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-12 fixed-customer-target-height ">
              <VacanyTab />
            </div>
            <div className="col-xl-12">
              <FeaturedCompanies />
            </div>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row ">
                    <div className="col-xl-8 col-xxl-7 col-sm-7">
                      {/* <div className="update-profile d-flex">
												<img src={pic1} alt="" />
												<div className="ms-4">
													<h3 className="fs-24 font-w600 mb-0">Franklin Jr</h3>
													<span className="text-primary d-block mb-4">UI / UX Designer</span>
													<span><i className="fas fa-map-marker-alt me-1"></i>Medan, Sumatera Utara - ID</span>
												</div>
											</div> */}
                    </div>
                    {/* <div className="col-xl-4 col-xxl-5 col-sm-5 sm-mt-auto mt-3 text-sm-end">
											<Link to={"#"} className="btn btn-primary">Update Profile</Link>
										</div> */}
                  </div>

                  <div className="row mt-4 align-items-center">
                    <h4 className="fs-20 mb-3">{t.Top_brand}</h4>
                    {topBrands.length > 0 ? (
                      <div className="col-xl-6 col-sm-6">
                        {topBrands.slice(0, 3).map((brand, index) => (
                          <React.Fragment key={index}>
                            {brand.percentage_of_total ? (
                              <>
                                <div className="progress default-progress">
                                  <div
                                    className={`progress-bar bg-${['green', 'info', 'blue'][index]} progress-animated`}
                                    style={{
                                      width: `${brand.percentage_of_total}%`,
                                      height: "13px",
                                    }}
                                    role="progressbar"
                                  >
                                    <span className="sr-only">
                                      {brand.percentage_of_total}% Complete
                                    </span>
                                  </div>
                                </div>
                                <div className="d-flex align-items-end mt-2 pb-4 justify-content-between">
                                  <span className="fs-14 font-w500">{brand.brand}</span>
                                  <span className="fs-16">
                                    <span className="text-black pe-2"></span>
                                    {brand.percentage_of_total}%
                                  </span>
                                </div>
                              </>
                            ) : null}
                          </React.Fragment>
                        ))}
                      </div>
                    ) : (
                      <p>Loading...</p>
                    )}

                    <div className="col-xl-6 col-sm-6">
                      {topBrands.length > 0 ? (
                        <div id="pieChart1">
                          <ApexPieChart data={topBrands} />
                        </div>
                      ) : (
                        <p>Loading...</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-6">
          <div className="col-xl-12">
            <div className="card fixed-kpi-height ">
              <div className="card-header pb-0 border-0 flex-wrap">
                <h4 className="fs-20">{t.KPIs}</h4>

                <div className="overflow-auto">
                  <NetworkStatistics />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12  fixed-customer-target-height">
              <CanvasChartTab />
            </div>
            <div className="col-xl-12">
              <RecentActivity />
            </div>
            <div className="col-xl-12"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;