import axios from 'axios';
import swal from "sweetalert";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';
import { useGlobalContext } from "../context/GlobalContext";

import { useNavigate } from 'react-router-dom';

const INACTIVITY_TIMEOUT = 1800000;

let inactivityTimer;

// Function to setup activity listeners
const setupActivityTracking = () => {
    const resetTimer = () => {
        if (inactivityTimer) {
            clearTimeout(inactivityTimer);
        }

        inactivityTimer = setTimeout(() => {
            // Clear any stored auth tokens/data
            localStorage.removeItem('authToken');
            localStorage.removeItem('userData');

            // Redirect to login page
            window.location.href = '/login';
        }, INACTIVITY_TIMEOUT);
    };

    // List of events to track for user activity
    const activityEvents = [
        'mousedown',
        'mousemove',
        'keypress',
        'scroll',
        'touchstart'
    ];

    // Add event listeners
    activityEvents.forEach(event => {
        document.addEventListener(event, resetTimer);
    });

    // Initial timer setup
    resetTimer();

    // Cleanup function to remove event listeners
    return () => {
        activityEvents.forEach(event => {
            document.removeEventListener(event, resetTimer);
        });
        if (inactivityTimer) {
            clearTimeout(inactivityTimer);
        }
    };
};

export function signUp(email, password) {
    //axios call
    const postData = {
        email,
        password,
        returnSecureToken: true,
    };
    return axios.post(
        `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
        postData,
    );
}

export async function login(email, password) {
    const postData = {
        usrName: email,
        passwrd: password
    };

    try {
        const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Auth.get_user_data`,
            postData
        );
        setupActivityTracking();
        return response;
    } catch (error) {
        console.error("Login error:", error);

        if (error.response && error.response.data && error.response.data.message) {
            const errorMessage = error.response.data.message.error || "An unknown error occurred";
            alert(errorMessage);
        } else {
            alert("An error occurred during login. Please try again.");
        }

        // Reload the page after displaying the alert
        window.location.reload();

        throw error;
    }
}

// export function useActivityTracking() {
//     const navigate = useNavigate();

//     React.useEffect(() => {
//         return setupActivityTracking();
//     }, [navigate]);
// }

export function formatError(errorResponse) {
    console.log('error responser reciveed', errorResponse)
    switch (errorResponse.message) {
        case 'EMAIL_EXISTS':
            //return 'Email already exists';
            swal("Oops", "Email already exists", "error");
            break;
        case 'EMAIL_NOT_FOUND':
            //return 'Email not found';
            swal("Oops", "Email not found", "error", { button: "Try Again!", });
            break;
        case 'Invalid login credentials':
            //return 'Invalid Password';
            swal("Oops", "Invalid Password or email", "error", { button: "Try Again!", });
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            return '';
    }
}



export function saveTokenInLocalStorage(tokenDetails, navigate) {
    localStorage.setItem('userDetails', JSON.stringify(tokenDetails));
    localStorage.setItem('showWelcomeToast', 'true');
    const event = new Event('storage');
    window.dispatchEvent(event);
    navigate('/dashboard', { replace: true });
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}

// I want this function to chek if user Details is present in the local storage  
// then dispatch   user Details and api key and secrete the loginConfirmedAction else dispatch the Logout action
export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');

    if (!tokenDetailsString) {
        console.log('No userDetails found in localStorage');
        dispatch(Logout(navigate));
        return;
    }

    let tokenDetails;
    try {
        tokenDetails = JSON.parse(tokenDetailsString);
    } catch (error) {
        console.error('Error parsing userDetails from localStorage:', error);
        dispatch(Logout(navigate));
        return;
    }

    const api_key = tokenDetails?.message?.api_key || '';
    const api_secret = tokenDetails?.message?.api_secret || '';


    if (!api_key || !api_secret) {
        console.log('API key or secret not found');
        dispatch(Logout(navigate));
        return;
    }

    dispatch(loginConfirmedAction(tokenDetails));
}
