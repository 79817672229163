import React, { useEffect, useState } from 'react';
import {Tab, Nav} from 'react-bootstrap';
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { getTotalStatisticsOverYear ,getTotalStatisticsOverPeriodOfYear } from '../../../../services/frappeServices/api/api';
import {useGlobalContext} from '../../../../context/GlobalContext';

const translations = {
    en: {
        buyingPerformance: "Buying Performance",
        apply: "Apply",
        daily: "2022",
        weekly: "2023",
        monthly: "2024",
        grandTotal: "Grand Total",
        netTotal: "Net Total",
        discount: "Discount",
    },
    ar: {
        buyingPerformance: "أداء الشراء",
        apply: "تطبيق",
        daily: "2022",
        weekly: "2023",
        monthly: "2024",
        grandTotal: "الإجمالي الكلي",
        netTotal: " المبلغ الصافي",
        discount: "الخصم",
    }
};

const VacanyCanvasChart1 = loadable(() =>
    pMinDelay(import("./TabChart/VacanyCanvasChart1"), 1000)
);
  
const VacanyTab = () => {
     const [yearsData, setYearsData] = useState(null);

     const { currentCustomer } = useGlobalContext();

    const handleApplyClick = async (event) => {
        try {
        
            const response = await getTotalStatisticsOverYear(event.target.innerText,currentCustomer);
            // const response2=await getTotalStatisticsOverPeriodOfYear(yearInput);
            // console.log(response2);

            
            setYearsData(response.message);
            // Use response.message instead of yearsData
        } catch (error) {
            console.log('error fetching total years statistics', error);
        }
    };
    const e={
        target:{
            innerText:"2024"
        }
    }
    const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
    const t = translations[language];
	useEffect(() => {
        handleApplyClick(e);
    }, [currentCustomer]);
    return (
        <>
            <Tab.Container defaultActiveKey="Monthly">
                <div className="card">
                    <div className="card-header border-0 pb-0 flex-wrap">
                        <h4 className="fs-20 font-w500 mb-1">{t.buyingPerformance}</h4>
                        <div className="card-action coin-tabs mt-3 mt-sm-0">
                            <div className="year-input">
                                {/* <input
                                    type="text"
                                    className="form-control input-rounded"
                                    value={yearInput}
                                    onChange={handleYearChange}
                                    placeholder="Enter Year"
                                />
                                <button onClick={handleApplyClick} className="btn btn-primary mt-2">Apply</button> */}
                            <Nav  className="nav nav-tabs" role="tablist">
								<Nav.Item >
									<Nav.Link onClick={handleApplyClick} eventKey="Daily">2022</Nav.Link>
								</Nav.Item>
								<Nav.Item >
									<Nav.Link onClick={handleApplyClick}  eventKey="Weekly">2023</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link  onClick={handleApplyClick}  eventKey="Monthly">2024</Nav.Link>
								</Nav.Item >
							</Nav>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="pb-4 d-flex flex-wrap">
                            <span className="d-flex align-items-center">
                                <svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                    <rect width="13" height="13" rx="6.5" fill="#35c556" />
                                </svg>
                                 {t.grandTotal}
                            </span>
                            <span className="application d-flex align-items-center">
                                <svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                    <rect width="13" height="13" rx="6.5" fill="#3f4cfe" />
                                </svg>
                                 {t.netTotal}
                            </span>
                            <span className="application d-flex align-items-center">
                                <svg className="me-1" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                    <rect width="13" height="13" rx="6.5" fill="#f34040" />
                                </svg>
                                {t.discount}
                            </span>
                        </div>
                        <Tab.Content>
                            <Tab.Pane eventKey="Daily">
                                <VacanyCanvasChart1 dataActive={0} statisticsData={yearsData} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Weekly">
                                <VacanyCanvasChart1 dataActive={1} statisticsData={yearsData} />
                            </Tab.Pane>
                            <Tab.Pane eventKey="Monthly">
                                <VacanyCanvasChart1 dataActive={2} statisticsData={yearsData} />
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </div>
            </Tab.Container>
        </>
    );
}

export default VacanyTab;
