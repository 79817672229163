import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./NetworkStatistics.css";
import { callApi } from "../../../../services/frappeServices/db/db";
import { getKpis } from "../../../../services/frappeServices/api/api";
import { useGlobalContext } from "../.../../../../../context/GlobalContext";

const translations = {
  en: {
    outstanding: "outstanding ",
    overdue: "overdue",
    credit_limit: "credit limit",
  },
  ar: {
    outstanding: "المبلغ المستحق",
    overdue: " الحد الأتماني",
    credit_limit: "مؤشر الاداء",
  },
};

const NetworkStatistics = () => {
  const [loading, setLoading] = useState(true); // Add a loading state
  const [outstandingPercentage, setOutStatndingPercentage] = useState(null);
  const [overduePercentage, setOverduePercentage] = useState(null);
  const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
  const t = translations[language];

  const [creditLimit, setCreditLimit] = useState(null);
  const [totalDue, setTotalDue] = useState(null);
  const [outstanding, setOutstanding] = useState(null);
  const [creditLimitPercentage, setCreditLimitPercentage] = useState(null);
  const { currentCustomer } = useGlobalContext();
  useEffect(() => {
    const fetchKpis = async () => {
      try {
        const kpis = await getKpis(currentCustomer);
        console.log("kpis is ", kpis);

        if (kpis) {
          const { outstandingKpi, creditLimitKpi, overDueCKpiRespone } = kpis;

          setOutstanding(
            Math.round(outstandingKpi.outstanding).toLocaleString()
          );
          setTotalDue(
            Math.round(overDueCKpiRespone.total_due).toLocaleString()
          );

          setCreditLimit(
            Math.round(creditLimitKpi.credit_limit).toLocaleString()
          );
          setOutStatndingPercentage(Math.round(outstandingKpi.percentage));
          setOverduePercentage(Math.round(overDueCKpiRespone.percentage));

          setCreditLimitPercentage(Math.round(creditLimitKpi.percentage));
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchKpis(currentCustomer);
  }, [currentCustomer]); // Ensure the dependency array is empty to run this effect once

  return (
    <div className=" overflow-auto ">
      <div className=" row text-center">
        {loading ? (
          <div>Loading...</div>
        ) : (
          <>
            <div className="col-4">
              <div className="circle  ">
                <svg viewBox="0 0 36 36" className="circular-chart orange">
                  <path
                    className="circle-bg"
                    d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <path
                    className="circle"
                    strokeDasharray={`${outstandingPercentage}, 100`}
                    d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <text x="18" y="20.35" className="percentage">
                    {outstandingPercentage}%
                  </text>
                </svg>
                <span> {t.outstanding} {outstanding}</span>
              </div>
            </div>

            <div className="col-4">
              <div className="circle">
                <svg viewBox="0 0 36 36" className="circular-chart orange">
                  <path
                    className="circle-bg"
                    d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <path
                    className="circle"
                    strokeDasharray={`${overduePercentage}, 100`}
                    d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <text x="18" y="20.35" className="percentage">
                    {overduePercentage}%
                  </text>
                </svg>
                <span> {t.overdue} {totalDue} </span>
              </div>
            </div>

            <div className="col-4">
              <div className="circle">
                <svg viewBox="0 0 36 36" className="circular-chart orange">
                  <path
                    className="circle-bg "
                    d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <path
                    className="circle"
                    strokeDasharray={`${creditLimitPercentage}, 100`}
                    d="M18 2.0845
                      a 15.9155 15.9155 0 0 1 0 31.831
                      a 15.9155 15.9155 0 0 1 0 -31.831"
                  />
                  <text x="18" y="20.35" className="percentage">
                    {creditLimitPercentage}%
                  </text>
                </svg>
                <span> {t.credit_limit} {creditLimit} </span>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default NetworkStatistics;