import axiosInstance from "../AxiosInstance";

export async function getDocList (doctype , args={}) {
     let params = {};
    if(args){
    const { fields, filters, orFilters, orderBy, limit, limit_start, groupBy, asDict = true } = args;
    const orderByString = orderBy ? `${String(orderBy?.field)} ${orderBy?.order ?? 'asc'}` : '';
    params = {
        fields: fields ? JSON.stringify(fields) : undefined,
        filters: filters ? JSON.stringify(filters) : undefined,
        or_filters: orFilters ? JSON.stringify(orFilters) : undefined,
        order_by: orderByString,
        group_by: groupBy,
        limit,
        limit_start,
        as_dict: asDict,
      };
    }
  const doctypeData = await axiosInstance.get(`/api/resource/${doctype}`,{params});
  return doctypeData.data.data;
}



export async function callApi( args={}){
    const { method, url, data, params ,baseURL} = args;
    const response = await axiosInstance.request({
        method,
        url,
        baseURL,
        data,
        params
    });
    return response.data;
}
 
// add this filter later to the api endpoint 
//?fields=${fields.join(',')}&limit_start=${offset}&limit_page_length=${limit}&order_by=${order_by}&filters=${JSON.stringify(filters)}
