import React, { useState } from "react";
import { Card } from "react-bootstrap";
import { SiPowerpages } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import "./schemacard.css"; // Import the updated CSS

const SchemaCard = ({ data }) => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  // handle model
  // Handle modal open/close
  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setZoomLevel(1); // Reset zoom level when closing modal
  };

  // Handle zoom in and out
  const handleZoomIn = () => {
    setZoomLevel(prevZoom => prevZoom + 0.1);
  };

  const handleZoomOut = () => {
    if (zoomLevel > 1) setZoomLevel(prevZoom => prevZoom - 0.1);
  };

  // Conditionally render date
  const renderDates = () => {
    if (data.from_date || data.to_date) {
      return (
        <div className="d-flex justify-content-between">
          {data.from_date && (
            <span style={{ color: "black", fontWeight: "bold" }}>
              Valid From: {data.from_date}
            </span>
          )}
          {data.to_date && (
            <span style={{ color: "black", fontWeight: "bold" }}>
              Valid To: {data.to_date}
            </span>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <Card
      className="mb-3"
      style={{ borderRadius: "10px", border: "1px solid #dee2e6" }}
    >
      <Card.Body>
        <div className="d-flex justify-content-between">
          <div>
            {/* Display promotion image */}
            <Card.Img
              variant="top"
              src={`${process.env.REACT_APP_BASE_URL}${data.image}`}
              alt={data.name}
              style={{ borderRadius: "10px", cursor: "pointer", width: "300px", height: "auto" }} // Set fixed width
              onClick={handleImageClick}
            />
            {/* Display promotion name */}
            <Card.Title
              className="mb-1"
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              <SiPowerpages
                style={{
                  color: "red",
                  fontSize: "1.5rem",
                  marginRight: "10px",
                }}
              />
              {data.promotion_id}
            </Card.Title>
          </div>
        </div>
        <Card.Text className="mb-1" style={{ fontWeight: "bold" }}>
          <h4 className="font-weight-bold">{data.name}</h4>
          {renderDates()}
        </Card.Text>
      </Card.Body>

      {/* Modal for image popup */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="button-group2">
              <button className="circle-button" onClick={handleZoomIn}>+</button>
              <button className="circle-button" onClick={handleZoomOut}>-</button>
              <button className="circle-button" onClick={handleCloseModal}>x</button>
            </div>
            <img
  src={`${process.env.REACT_APP_BASE_URL}${data.image}`}
  alt={data.name}
  className="modal-image"
  style={{ 
    maxWidth: "auto", 
    maxHeight: "500px", 
    transform: `scale(${zoomLevel})` 
  }}
/>

          </div>
        </div>
      )}
    </Card>
  );
};

export default SchemaCard;


