import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import ThemeContext from "./context/ThemeContext";
import { ErrorBoundary } from "react-error-boundary";
import { GlobalProvider } from "./context/GlobalContext";
import ErrorPage from "./utils/errorPage";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <GlobalProvider>
          <ThemeContext>
            <ErrorBoundary
              FallbackComponent={ErrorPage}
              onReset={() => (window.location.href = "/")}
            >
              <App />
            </ErrorBoundary>
          </ThemeContext>
        </GlobalProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
