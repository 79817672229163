import { lazy, Suspense, useEffect,useState } from 'react';
/// Components
import Index from './jsx/index';
import { connect, useDispatch } from 'react-redux';
import {  Route, Routes, useLocation , useNavigate , useParams } from 'react-router-dom';
// action
import { checkAutoLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import {getDocList} from './services/frappeServices/db/db';
import {GlobalContext} from './store/context/global-context';

const SignUp = lazy(() => import('./jsx/pages/Registration'));
const ForgotPassword = lazy(() => import('./jsx/pages/ForgotPassword'));
const Login = lazy(() => {
    return new Promise(resolve => {
		setTimeout(() => resolve(import('./jsx/pages/Login')), 500);
	});
});

{/* <Alert  variant="danger" dismissible show={state.soliddanger} className="solid">						
<strong>Error! </strong> Message sending failed.
<button className="btn-close" onClick={()=>dispatch({type:'soliddanger'})}></button>
</Alert> */}
// const getTokenFromLocalStorage = ()=>{
//     return 'token 6fbf9db1172c4b6:246aafc7f51d2c7'
//   }

// const getSalesInvoice= async ()=>{
//     let data = await getDocList('Sales Invoice', {fields: ['name', 'customer_name', 'posting_date', 'total', 'outstanding_amount']});
//     console.log(data);
// }
// getSalesInvoice();
function withRouter(Component) {
    function ComponentWithRouterProp(props) {
      let location = useLocation();
      let navigate = useNavigate();
      let params = useParams();
      
      return (
        <Component
          {...props}
          router={{ location, navigate, params }}
        />
      );
    }
  
    return ComponentWithRouterProp;
}

function App (props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    
    useEffect(() => {
        checkAutoLogin(dispatch, navigate);
    }, [dispatch, navigate]);

    useEffect(() => {
        if (location.pathname === '/login' && !props.isAuthenticated) {
            localStorage.removeItem('userDetails');
            localStorage.removeItem('showWelcomeToast');

            // Check if the reload has already happened
            if (!localStorage.getItem('hasReloaded')) {
                localStorage.setItem('hasReloaded', 'true'); // Set the flag
                window.location.reload(); // Reload the page
            }
        }
    }, [location.pathname, props.isAuthenticated]);

    // Reset the reload flag when the app loads or when user logs out
    useEffect(() => {
        if (location.pathname !== '/login') {
            localStorage.removeItem('hasReloaded'); // Reset the reload flag for future sessions
        }
    }, [location.pathname]);

    
    // useEffect(() => {
    //     console.log('option changed')
    // }, [selectedOption]);

    //   useEffect(() => {
        
    //     localStorage.setItem('current_customer', selectedOption);
    //   }, [selectedOption]);

    
    let routeblog = (  
        <Routes>
            <Route path='/login' element={<Login />} />
        </Routes>
    );
    if (props.isAuthenticated) {
		return (
			<>
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>  
                   }
                >
                    <GlobalContext.Provider>
                    <Index />
                    </GlobalContext.Provider>
                </Suspense>
            </>
        );
	
	}else{
		return (
			<div className="vh-100">
                <Suspense fallback={
                    <div id="preloader">
                        <div className="sk-three-bounce">
                            <div className="sk-child sk-bounce1"></div>
                            <div className="sk-child sk-bounce2"></div>
                            <div className="sk-child sk-bounce3"></div>
                        </div>
                    </div>
                  }
                >
                    {routeblog}
                </Suspense>
			</div>
		);
	}
};

const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

export default withRouter(connect(mapStateToProps)(App)); 