import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import Select from "react-select";
import Collapse from "react-bootstrap/Collapse";
import { callApi } from "../../../services/frappeServices/db/db";
import { format, set } from "date-fns";
import NumericLabel from "react-pretty-numbers";
import "./PrePayment.css";
import { useGlobalContext } from "../../../context/GlobalContext";

import styled, { keyframes } from 'styled-components';


import {
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";
import "./content.css"; // Ensure this file contains the CSS for .fixed-header-table
import { getPrePayment } from "../../../services/frappeServices/api/api";
import { reference } from "@popperjs/core";
const options = [
  { value: "2", label: "Published" },
  { value: "3", label: "Draft" },
  { value: "4", label: "Trash" },
  { value: "5", label: "Private" },
  { value: "6", label: "Pending" },
];
const translations = {
  en: {
    filter: "Filter",
    status: "Status",
    refNo: "Amount",
    refDate: "Refrence Date",
    clearFilter: "Clear Filter",
    downloadExel: "Download Exel",
    addPrepayment: "+ Add pre Payment",
    referenceAttachment: "	Reference Attachment",
    description: "Description",
    nodata: "No Data Found",
    prePay: "Pre Payment",
    amount: "amount"


  },
  ar: {
    filter: "فلتر",
    status: "الحالة",
    refNo: "المبلغ",
    refDate: "التاريخ المرجعي",
    clearFilter: "محو الفلتر",
    downloadExel: "تنزيل اكسيل ",
    addPrepayment: "+ اضافه الدفع المسبق",
    referenceAttachment: "المرفق المرجعي",
    description: "الوصف",
    nodata: "لا توجد معلومات",
    prePay: "الدفع المسبق",
    amount: "المبلغ"


  }
};




const tableData = [
  { number: "1", title: "Privacy Policy" },
  { number: "2", title: "Contact Us" },
  { number: "3", title: "Price" },
  { number: "4", title: "Blog" },
  { number: "5", title: "Faq" },
  { number: "6", title: "About us" },
  { number: "7", title: "Portfolio" },
  { number: "8", title: "Schedule" },
  { number: "9", title: "Under Maintenance" },
  { number: "10", title: "Comming Soon" },
  { number: "11", title: "Faq" },
  { number: "12", title: "About us" },
  { number: "13", title: "Portfolio" },
];

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
  }

  .modal-body {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    color: #333;
  }

  &.show {
    animation: ${fadeIn} 0.3s ease-out;
  }

  &.hide {
    animation: ${fadeOut} 0.3s ease-in;
  }
`;


const AlertModal = ({ show, message, onHide }) => {
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        onHide();
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [show, onHide]);
  return (
    <StyledModal
      show={show}
      onHide={onHide}
      centered
      className={show ? 'show' : 'hide'}
    >
      <Modal.Body>{message}</Modal.Body>
    </StyledModal>
  );
};


const PrePayment = () => {
  const { currentCustomer } = useGlobalContext();
  const tableRef = useRef(null);
  const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
  const t = translations[language];


  const [showAlertModal, setShowAlertModal] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');


  const showAlert = (message) => {
    setAlertMessage(message);
    setShowAlertModal(true);
  };


  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: "Users table",
    sheet: "Users",
  });

  const [open, setOpen] = useState(true);
  const [prePaymentData, setPrePaymentData] = useState(null);

  const [filter, setFilter] = useState([]);

  const [open2, setOpen2] = useState(true);

  const today = format(new Date(), "yyyy-MM-dd");
  const [refNumber, setRefNumber] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [data, setData] = useState(
    document.querySelectorAll("#content_wrapper tbody tr")
  );
  const sort = 8;
  const activePag = useRef(0);
  const [test, setTest] = useState(0);

  // Active data
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  // Use effect
  useEffect(() => {

    setData(document.querySelectorAll("#content_wrapper tbody tr"));
  }, [test]);

  const fetchPrePayment = async (filter) => {
    try {
      const response = await getPrePayment(filter);
      setPrePaymentData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(`currenct customer value is `, currentCustomer);
    const fetchData = async () => {
      if (currentCustomer) {
        const filter = [["customer_code", "=", currentCustomer]];
        await fetchPrePayment(filter);
      }
    };

    fetchData();
  }, [currentCustomer]);


  // Active pagination
  activePag.current === 0 && chageData(0, sort);
  // Pagination
  let pagination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  // Active pagination & change data
  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
    setTest(i);
  };

  const handleClearFilterClick = () => {
    setRefNumber("");
    setToDate(today);
    const filter = [["customer_code", "=", currentCustomer]];
    fetchPrePayment(filter);
  };

  const [deleteItem, setDeleteItem] = useState(tableData);
  const handleDelete = (ind) => {
    setDeleteItem((oldValues) => {
      return oldValues.filter((_, i) => i !== ind);
    });
  };

  // Modified handleApplyClick function to ensure it uses the latest state values
  const handleApplyClick = async () => {
    const setFilterArray = (amount, reference_date) => {
      let filters = [];

      if (currentCustomer) {
        filters.push(["customer_code", "=", currentCustomer]);
      }
      if (amount) {
        filters.push(["amount", "=", amount]);
      }

      if (reference_date) {
        filters.push(["reference_date", "=", reference_date]);
      }
      return filters;
    };

    const filters = setFilterArray(refNumber, toDate);
    setFilter(filters);
    await fetchPrePayment(filters);
  };

  return (
    <>

      <div className="row">
        <div className="col-xl-12">
          <div className="filter cm-content-box box-primary">
            <div className="content-title">
              <div className="cpa">
                <i className="fas fa-filter me-2"></i>
                <span>{t.filter}</span>
              </div>
              <div className="tools">
                <Link
                  to={"#"}
                  className={`SlideToolHeader ${open ? "collapse" : "expand"}`}
                  onClick={() => setOpen(!open)}
                >
                  <i className="fas fa-angle-up"></i>
                </Link>
              </div>
            </div>

            <Collapse in={open}>
              <div className="cm-content-body form excerpt">
                <div className="card-body">
                  <div className="row filter-row">
                    <div className="col-xl-3 col-xxl-6">
                      <p>{t.refNo}</p>
                      <input
                        type="input"
                        name="ref-no"
                        className="form-control mb-xxl-0 mb-3"
                        value={refNumber}
                        onChange={(e) => setRefNumber(e.target.value)}
                      />
                    </div>
                    <div className="col-xl-3 col-xxl-6">
                      <p>{t.refDate}</p>
                      <input
                        type="date"
                        name="toDate"
                        className="form-control mb-xxl-0 mb-3"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                      />
                    </div>
                    <div className="col-xl-3 col-xxl-6"></div>
                    <div className="col-xl-6 col-xxl-6 mt-2">
                      <div className="d-flex justify-content-start">
                        <button
                          className="btn btn-primary me-2"
                          title="Click here to Search"
                          type="button"
                          onClick={handleApplyClick}
                        >
                          <i className="fa fa-search me-1"></i>{t.filter}
                        </button>
                        <button
                          className="btn btn-primary"
                          title="Click here to Search"
                          type="button"
                          onClick={handleClearFilterClick}
                        >
                          <i className="fa fa-eraser me-1"></i>{t.clearFilter}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>

          <div className="d-flex justify-content-between">
            <div className="mb-3">
              <DownloadTableExcel
                filename="Pre Payment"
                sheet="statement"
                currentTableRef={tableRef.current}
                onDownload={onDownload}
              >
                <Link to={""} className="btn btn-primary">
                  {t.downloadExel}
                </Link>
              </DownloadTableExcel>
            </div>

            <div className="ml-auto">
              <Link to={"/pre-payment-save"} className="btn btn-primary">
                {t.addPrepayment}
              </Link>
            </div>
          </div>


          <div className="filter cm-content-box box-primary mt-5">
            <div className="content-title">
              <div className="cpa">
                <i className="fas fa-file-word me-2"></i>{t.prePay}
              </div>
            </div>
            <Collapse in={open2}>
              <div className="cm-content-body form excerpt">
                <div className="card-body">
                  <div className="table-responsive fixed-header-table">
                    <div id="content_wrapper" className="dataTables_wrapper ">
                      <table
                        className="table table-bordered table-responsive-lg table-striped table-condensed flip-content"
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            <th>No</th>
                            <th>{t.amount}</th>
                            <th>{t.refDate}</th>
                            <th>{t.referenceAttachment}</th>
                            <th>{t.status}</th>
                            <th>{t.description}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {prePaymentData && prePaymentData.length > 0 ? (
                            prePaymentData.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>{" "}
                                <td>{item.amount || " "}</td>
                                <td>{item.reference_date || " "}</td>
                                {/* Assuming voucher number is just the row index */}
                                <td>
                                  <a
                                    href={`${process.env.REACT_APP_BASE_URL}${item.reference_attachment}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    View File
                                  </a>
                                </td>
                                <td>{item.workflow_state || " "}</td>
                                <td className="description-cell">
                                  <span>{item.description || " "}</span>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="6" className="text-center">
                                {t.nodata}
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrePayment;