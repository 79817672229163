import axiosInstance from "../AxiosInstance";
import { getDocList } from "../db/db";
import axios from "axios";
 
export const getTotalStatisticsOverYear = async (year,currentCustomerCode) => {
  try {
 
    const response = await axiosInstance.post(
      `/api/method/customer_app.apis.kpi_api.get_total_statistics_over_period_of_year_v2`,
      {
        customer: currentCustomerCode,
        year: year,
        mobile_filters: {},
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getTopSalesItems = async (currentCustomer) => {
  try {
    const response = await axiosInstance.post(
      `api/method/customer_app.apis.kpi_api.get_top_selling_items`
   ,{
    customer:currentCustomer
   } );
    return response.data;
  } catch (error) {
    return error;
  }
};


export async function getTopBrands(currentCustomer){
  try {
    const response = await axiosInstance.post(
      `api/method/customer_app.apis.kpi_api.get_top_brands_v2`,
      {
        customer: currentCustomer,
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}
 
export async function getTotalStatisticsOverPeriodOfYear(year) {
  const doctype = "Sales Invoice";
  const args = {
    fields: [
      "base_grand_total",
      "base_net_total",
      "base_discount_amount",
      "creation",
    ],
    filters: {
      docstatus: 1,
      is_return: 0,
      customer: "AAAQ-03585",
      creation: ["between", [`${year}-01-01`, `${year}-12-31`]],
    },
    asDict: true,
  };

  const invoices = await getDocList(doctype, args);

  const totalStatistics = invoices.reduce((totals, invoice) => {
    const creationDate = new Date(invoice.creation);
    const month = creationDate.getMonth() + 1;

    if (!totals[month]) {
      totals[month] = {
        grand_total: 0,
        net_total: 0,
        discount: 0,
        year: creationDate.getFullYear(),
        month: month,
      };
    }

    totals[month].grand_total += invoice.base_grand_total || 0;
    totals[month].net_total += invoice.base_net_total || 0;
    totals[month].discount += invoice.base_discount_amount || 0;

    return totals;
  }, {});

  // Convert totals object to array
  const totalStatisticsOverYear = Object.values(totalStatistics);

  return totalStatisticsOverYear;
}
 
 

export async function getTotalStatistics(currentCustomerCode) {
  try {
     
    const response = await axiosInstance.post(
      `/api/method/customer_app.apis.kpi_api.get_total_statistics`
    ,{
      customer:currentCustomerCode
    });
    return response.data;
  } catch (error) {
    return error;
  }
}

export async function getCustomTargetStatistics(year,currentCustomerCode) {
  const doctype = "Customer Target";
  const args = {
    fields: [
      "q1_target",
      "q1_actual",
      "q1_variance",
      "q2_target",
      "q2_actual",
      "q2_variance",
      "q3_target",
      "q3_actual",
      "q3_variance",
      "q4_target",
      "q4_actual",
      "q4_variance",
    ],
    filters: {
      customer:currentCustomerCode,
      year: year
    },
    asDict: true,
  };

  const customTargetStatistics = await getDocList(doctype, args);

  // Calculate absolute values for variances
  customTargetStatistics.forEach((item) => {
    item.q1_variance = Math.abs(item.q1_variance);
    item.q2_variance = Math.abs(item.q2_variance);
    item.q3_variance = Math.abs(item.q3_variance);
    item.q4_variance = Math.abs(item.q4_variance);
  });

  return customTargetStatistics;
}

 

export async function get_custoemr_statement(body) {
  try {
    const response = await axiosInstance.get(
      `api/method/customer_app.apis.statement_api.statement_api`,
      { body }
    );
    return response.data;
  } catch (error) {
    return error;
  }
}
export async function get_promotion_schema(mobile_filters = {}, currentCustomerCode) {
  try {
    const customerCompany = JSON.parse(localStorage.getItem("userDetails"))
      ?.message?.user_customers[0].customer_company;

    const requestBody = {
      customer: currentCustomerCode,
      portal: 1, 
      promotion_title: mobile_filters.promotion_title || "",
      from_date: mobile_filters.from_date || "",
      to_date: mobile_filters.to_date || "",
    };
    console.log("Request Body:", requestBody); // <-- Add this
    const response = await axiosInstance.post(
      `api/method/customer_app.apis.kpis.get_promotion_poster`,
      requestBody
    );
    
    return response.data;
  
  } catch (error) {
    console.error("Error fetching promotion schema:", error);
    return error;
  }
  
}


export const getKpis = async (currentCustomer) => {
  
  const outstandingKpi = await axiosInstance.post(
    "/api/method/customer_app.apis.kpis.get_total_outstanding",
    { customer: currentCustomer }
  );
 
  const creditLimitKpi = await axiosInstance.post(
    "/api/method/customer_app.apis.kpis.get_credit_limit",
    { customer: currentCustomer }
  );
  const getOverdue = await axiosInstance.post(
    "/api/method/customer_app.apis.kpis.get_overdue",
    { customer: currentCustomer }
  );
  const [
    outstandingKpiResponse,
   
    creditLimitKpiResponse,
    overDueKpiRespone,
  ] = await Promise.all([
    outstandingKpi,
   
    creditLimitKpi,
    getOverdue,
  ]);
  return {
    outstandingKpi: outstandingKpiResponse.data.message,
   
    creditLimitKpi: creditLimitKpiResponse.data.message,
    overDueCKpiRespone: overDueKpiRespone.data.message,
  };
};

export const getRecentActivity = async (currentCustomer) => {
  try {
     
    const response = await axiosInstance.post(
      `api/method/customer_app.apis.kpis.get_activity_log`,
      { customer: currentCustomer }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getPrePayment = async (filter = [],currentCustoemr) => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_BASE_URL}/api/resource/PrePayment`,
  {
    params: {
      fields: '["amount","reference_date","reference_attachment","description","workflow_state"]',
      filters: JSON.stringify(filter),
      order_by: 'reference_date desc'
    }
  }
    );

    return response.data;
  } catch (error) {
    return error;
  }
};

export const uploadFile = async (file) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    const response = await axiosInstance.post(
      `/api/method/upload_file`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const savePrePayment = async (data) => {
  try {
    const response = await axiosInstance.post(
      `api/resource/PrePayment`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    return error;
  }
};