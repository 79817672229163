import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { ThemeContext } from "../../context/ThemeContext";

export const SidebarTrigger = ({ handleGoToCart }) => {
	return (
		<Link to="#" className="sidebar-right-trigger wave-effect wave-effect-x" onClick={handleGoToCart}>
			<span><i className="fa fa-cog fa-spin" /></span>
		</Link>
	);
};

const Setting = ({ handleGoToCart }) => {
	const [settingToggle, setSettingToggle] = useState(false);
	const [demoToggle, setDemoToggle] = useState(false);
	const {
		body,
		sideBarOption,
		layoutOption,
		backgroundOption,
		sidebarposition,
		headerPositions,
		containerPosition,
		fontFamily,
		changePrimaryColor,
		changeNavigationHader,
		sideBarStyle,
		changeSideBarStyle,
		changeSideBarPostion,
		sidebarpositions,
		changeHeaderPostion,
		headerposition,
		changeSideBarLayout,
		sidebarLayout,
		colors,
		chnageHaderColor,
		chnageSidebarColor,
		changeBackground,
		background,
		changeContainerPosition,
		containerPosition_,
		setDemoTheme
	} = useContext(ThemeContext);

	const handleToggle = () => {
		setSettingToggle(!settingToggle);
	};

	return (
		<>
			{/* <div className={`sidebar-right ${settingToggle ? "show" : ""}`}>
				<div className="bg-overlay" onClick={() => setSettingToggle(false)}></div>
				<SidebarTrigger handleGoToCart={handleToggle} />
			</div> */}
		</>
	);
};

export default Setting;
