import React, { useEffect, useState, useRef } from "react";
import "./content.css";
import "./Srfqsyle.css";
import Modal from "./Modal";
import axiosInstance from "../../../services/frappeServices/AxiosInstance";
import { useGlobalContext } from "../../../context/GlobalContext";
import { FaArrowAltCircleRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import './fixModel.css';
import { set } from "date-fns";


const translations = {
  en: {
    // Buttons and Actions
    SalesRequestForQutation: "Sales Request For Quotation",
    addSalesRequestManual: "Add Sales Request For Quotation Manual",
    addSalesRequestGallery: "Add Sales Request For Quotation Gallery",
    resetFilters: "Reset Filters",
    close: "Close",
    print: "Print",
    view: "View",

    // Filter Section
    filter: "Filter",
    from: "From",
    to: "To",
    status: "Status",
    name: "Name",
    searchByName: "Search by name",

    // Table Headers
    date: "Date",
    actions: "Actions",
    itemCode: "Item Code",
    itemName: "Item Name",
    image: "Image",
    quantity: "Quantity",
    rate: "Rate",
    total: "Total",

    // Modal Titles and Headers
    quotationsDetails: "Quotations Details",
    orderDetails: "Order Details",
    invoiceDetails: "Invoice Details",
    deliveryDetails: "Delivery Details",
    items: "Items",
    orderTrack: "Order Track",

    // Document Types
    quotation: "Quotation",
    salesOrder: "Sales Order",
    salesInvoice: "Sales Invoice",
    deliveryNote: "Delivery Note",

    // Status and Messages
    loading: "Loading...",
    noDataAvailable: "No data available",
    noItemsFound: "No items found",
    priceNotAvailable: "Price not available",
    hidden: "Hidden",

    // Financial Terms
    netTotal: "Net Total",
    grandTotal: "Grand Total",
    totalTaxes: "Total Taxes",
    discountAmount: "Discount Amount",
    totalTaxesAndCharges: "Total Taxes and Charges",

    // Units
    sar: "SAR",

    // Customer Details
    customer: "Customer"
  },
  ar: {
    // Buttons and Actions
    SalesRequestForQutation: "طلب عرض سعر للمبيعات",
    addSalesRequestManual: "إضافة طلب مبيعات للتسعير يدوياً",
    addSalesRequestGallery: "إضافة طلب مبيعات للتسعير من المعرض",
    resetFilters: "محو الفلتر",
    close: "إغلاق",
    print: "طباعة",
    view: "عرض التفاصيل",

    // Filter Section
    filter: "فلتر",
    from: "من",
    to: "إلى",
    status: "الحالة",
    name: "الاسم",
    searchByName: "البحث بالاسم",

    // Table Headers
    date: "التاريخ",
    actions: "عرض التفاصيل",
    itemCode: "رمز الصنف",
    itemName: "اسم الصنف",
    image: "الصورة",
    quantity: "الكمية",
    rate: "السعر",
    total: "المجموع",

    // Modal Titles and Headers
    quotationsDetails: "تفاصيل عرض السعر",
    orderDetails: "تفاصيل الطلب",
    invoiceDetails: "تفاصيل الفاتورة",
    deliveryDetails: "تفاصيل التسليم",
    items: "الأصناف",
    orderTrack: "تتبع الطلب",

    // Document Types
    quotation: "عرض السعر",
    salesOrder: "أمر البيع",
    salesInvoice: "فاتورة المبيعات",
    deliveryNote: "إشعار التسليم",

    // Status and Messages
    loading: "جاري التحميل...",
    noDataAvailable: "لا توجد بيانات متاحة",
    noItemsFound: "لا توجد أصناف",
    priceNotAvailable: "السعر غير متاح",
    hidden: "مخفي",

    // Financial Terms
    netTotal: "صافي المبلغ ",
    grandTotal: " المبلغ الكلي",
    totalTaxes: "مجموع الضرائب",
    discountAmount: "مبلغ الخصم",
    totalTaxesAndCharges: "مجموع الضرائب والرسوم",

    // Units
    sar: "ريال سعودي",

    // Customer Details
    customer: "العميل"
  }
};
const Content = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { currentCustomer } = useGlobalContext();
  const tableRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [workflowState, setWorkflowState] = useState("");
  const [searchName, setSearchName] = useState("");
  const [filteredNames, setFilteredNames] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [modalData, setModalData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isQuotationModelOpen, setIsQuotationModelOpen] = useState(false);
  const [isSalesOrderModelOpen, setIsSalesOrderModelOpen] = useState(false);
  const [isSalesInvoiceModelOpen, setIsSalesInvoiceModelOpen] = useState(false);
  const [isDeliveryNoteModelOpen, setIsDeliveryNoteModelOpen] = useState(false);
  const [quotationName, setQuotationName] = useState("");
  const [salesOrderName, setSalesOrderName] = useState("");
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [salesOrderDetails, setSalesOrderDetails] = useState(null);
  const [salesInvoiceData, setSalesInvoiceData] = useState(null);
  const [deliveryNoteData, setDeliveryNoteData] = useState(null);
  const [salesInvoices, setSalesInvoices] = useState([]);
  const [deliveryNotes, setDeliveryNotes] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [selectedDeliveryNote, setSelectedDeliveryNote] = useState(null);
  const [salesInvoicePostinDate, setSalesInvoicePostinDate] = useState("");
  const [salesInvoiceStatus, setSalesInvoiceStatus] = useState("");
  const [deliveryNotePostinDate, setDeliveryNotePostinDate] = useState("");
  const [deliveryNoteStatus, setDeliveryNoteStatus] = useState("");
  const [itemCategories, setItemCategories] = useState({});
  const [hideRates, setHideRates] = useState({});
  const [error, setError] = useState(null);

  const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
  const t = translations[language];
  const [sumTotal, setSumTotal] = useState(0);
  const [hasHiddenPrice, setHasHiddenPrice] = useState(false);

  const fetchInvoiceDetails = async (invoiceName) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}/api/resource/Quotation/${invoiceName}`
      );
      setInvoiceDetails(response.data.data);
    } catch (error) {
      console.error("Error fetching invoice details:", error);
    }
  };

  const fetchSalesOrderData = async (invoiceName) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}/api/resource/Sales%20Order/${invoiceName}`
      );
      setSalesOrderDetails(response.data.data);
      return response.data.data;
    } catch (error) {
      console.error("Error fetching invoice details:", error);
    }
  };

  const fetchSalesInvoiceDetails = async (invoiceName) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}/api/resource/Sales%20Invoice/${invoiceName}`
      );
      setSalesInvoiceData(response.data.data);
    } catch (error) {
      console.error("Error fetching invoice details:", error);
    }
  };


  const fetchDeliveryNoteData = async (invoiceName) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/resource/Delivery%20Note/${invoiceName}`);
      setDeliveryNoteData(response.data.data);
    } catch (error) {
      console.error('Error fetching invoice details:', error);
    }
  };


  const handleQuotationCloseModal = () => {
    setIsQuotationModelOpen(false);
  };

  const handleSalesOrderCloseModal = () => {
    setIsSalesOrderModelOpen(false);
  };

  const handleSalesInvoiceCloseModal = () => {
    setIsSalesInvoiceModelOpen(false);
  };

  const handleDeliveryNoteCloseModal = () => {
    setIsDeliveryNoteModelOpen(false);
  };

  const handleCloseModal = () => {

    setIsOpen(false);
    // Clear invoice details when closing the modal
  };
  const fetchQuotationData = async (qtnId) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_BASE_URL}/api/resource/Quotation/${qtnId}`
      );
      return response.data.data;
    } catch (error) {
      console.error("Error fetching quotation data:", error);
    }
  };
  const fetchListData = async (
    year,
    month,
    fromDate,
    toDate,
    workflowState,
    currentCustomer
  ) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}/api/resource/Sales%20Request%20For%20Quotation`;

    const filters = [];
    if (year) {
      filters.push(`["year", "=", "${year}"]`);
    }
    if (month) {
      filters.push(`["month", "=", "${month}"]`);
    }
    if (fromDate) {
      filters.push(`["date", ">=", "${fromDate}"]`);
    }
    if (toDate) {
      filters.push(`["date", "<=", "${toDate}"]`);
    }
    if (workflowState) {
      filters.push(`["workflow_state", "=", "${workflowState}"]`);
    }
    if (currentCustomer) {
      filters.push(`["customer", "=", "${currentCustomer}"]`);
    }
    if (searchName) {
      filters.push(`["name", "like", "%${searchName.trim()}%"]`);
    }
    if (filters.length > 0) {
      url += `?filters=[${filters.join(",")}]`;
    }
    url += filters.length > 0 ? `&order_by=name desc` : `?order_by=name desc`;
    // add filters

    try {
      const response = await axiosInstance.get(url);
      const fetchedData = response.data.data;
      const detailedDataPromises = fetchedData.map(async (item) => {
        const detailUrl = `${process.env.REACT_APP_BASE_URL}/api/resource/Sales%20Request%20For%20Quotation/${item.name}`;

        const detailResponse = await axiosInstance.get(detailUrl);
        return detailResponse.data.data;
      });

      const detailedData = await Promise.all(detailedDataPromises);


      // Process detailed data to get sales_invoice and delivery_note
      const allSalesInvoices = detailedData.flatMap(data => data.sales_invoice || []);
      const allDeliveryNotes = detailedData.flatMap(data => data.delivery_note || []);

      // Process sales_invoice
      const filteredAndSortedSalesInvoices = allSalesInvoices
        .filter(invoice => invoice.status !== 'Draft' && invoice.status !== 'Cancelled')
        .map(({ name, posting_date, status, sales_invoice, parent }) => ({ name, posting_date, status, sales_invoice, parent }))
        .sort((a, b) => new Date(a.posting_date) - new Date(b.posting_date));

      // Process delivery_note
      const filteredAndSortedDeliveryNotes = allDeliveryNotes
        .filter(note => note.status !== 'Draft' && note.status !== 'Cancelled')
        .map(({ name, posting_date, status, delivery_note, parent }) => ({ name, posting_date, status, delivery_note, parent }))
        .sort((a, b) => new Date(a.posting_date) - new Date(b.posting_date));

      detailedData.forEach(item => {
        // Filter and sort sales_invoice for the current item
        item.filteredSalesInvoices = item.sales_invoice
          .filter(invoice => invoice.status !== 'Draft' && invoice.status !== 'Cancelled')
          .map(({ name, posting_date, status, sales_invoice, parent }) => ({ name, posting_date, status, sales_invoice, parent }))
          .sort((a, b) => new Date(a.posting_date) - new Date(b.posting_date));

        // Filter and sort delivery_note for the current item
        item.filteredDeliveryNotes = item.delivery_note
          .filter(note => note.status !== 'Draft' && note.status !== 'Cancelled')
          .map(({ name, posting_date, status, delivery_note, parent }) => ({ name, posting_date, status, delivery_note, parent }))
          .sort((a, b) => new Date(a.posting_date) - new Date(b.posting_date));
      });
      setSalesInvoices(filteredAndSortedSalesInvoices);
      setDeliveryNotes(filteredAndSortedDeliveryNotes);
      setData(detailedData);
      setFilteredNames(detailedData.map((item) => item.name));
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchListData(
      year,
      month,
      fromDate,
      toDate,
      workflowState,
      currentCustomer,
      searchName
    );
  }, [year, month, fromDate, toDate, workflowState, currentCustomer, searchName]);

  const handleWorkflowStateChange = (e) => {
    setWorkflowState(e.target.value);
  };

  const handleSearchNameChange = (e) => {

    const value = e.target.value;
    setSearchName(value);
    if (value) {
      const filtered = data
        .filter((item) => item.name.toLowerCase().includes(value.toLowerCase()))
        .map((item) => item.name);
      setFilteredNames(filtered);
    } else {
      setFilteredNames(data.map((item) => item.name));
    }
  };

  const handleQuotationClick = (e) => {

    fetchInvoiceDetails(quotationName);
    setIsQuotationModelOpen(true);
  };

  const handleSalesOrderClick = () => {

    fetchSalesOrderData(salesOrderName);
    setIsSalesOrderModelOpen(true);
  };

  const handleSalesInvoiceClick = () => {
    fetchSalesInvoiceDetails(selectedInvoice);
    setIsSalesInvoiceModelOpen(true);
  };

  const handleDeliveryNoteClick = () => {
    fetchDeliveryNoteData(selectedDeliveryNote);
    setIsDeliveryNoteModelOpen(true);
  }

  const handleDeliveryNoteChange = (noteName) => {
    setSelectedDeliveryNote(noteName);
    const note = deliveryNotes.find(note => note.delivery_note === noteName);
    setDeliveryNotePostinDate(note.posting_date);
    setDeliveryNoteStatus(note.status);
  }


  const handleSalesInvoiceChange = (invoiceName) => {
    setSelectedInvoice(invoiceName);
    const invoice = salesInvoices.find(invoice => invoice.sales_invoice === invoiceName);
    setSalesInvoicePostinDate(invoice.posting_date);
    setSalesInvoiceStatus(invoice.status);
  }
  const handleNameSelect = (name) => {
    setSelectedName(name);
    setSearchName(name);
    setFilteredNames([]); // Clear the dropdown
  };

  const handleViewClick = async (item) => {
    console.log(`item recived in handleview click`, item)


    const categoriesPromises = item.srfq_item.map(async (srfq_item) => {
      try {
        console.log("Fetching details for item code:", srfq_item.item_code);
        const itemResponse = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/resource/Item/${srfq_item.item_code}`);
        const customCategory = itemResponse.data.data.custom_category;
        console.log("Custom category for item:", customCategory);

        const categoryResponse = await axiosInstance.post(`${process.env.REACT_APP_BASE_URL}/api/method/customer_app.apis.Items.get_items_by_category`, {
          category: "Project",
          customer: "AAAQ-03585"
        });

        const categories = categoryResponse.data.message.map(cat => cat.name);
        console.log("Categories returned from API:", categories);
        const hideRate = categories.includes(customCategory);
        console.log("Should hide rate for this item?", hideRate);

        return { itemCode: srfq_item.item_code, customCategory, hideRate };
      } catch (error) {
        console.error("Error fetching item details:", error);
        return { itemCode: srfq_item.item_code, customCategory: null, hideRate: false };
      }
    });

    const categoriesResults = await Promise.all(categoriesPromises);

    const newItemCategories = {};
    const newHideRates = {};
    let newHasHiddenPrice = false;
    let newSumTotal = 0;

    categoriesResults.forEach(result => {
      newItemCategories[result.itemCode] = result.customCategory;
      newHideRates[result.itemCode] = result.hideRate;
      if (result.hideRate) {
        newHasHiddenPrice = true;
      }
    });

    // Calculate sum total for visible prices
    item.srfq_item.forEach(srfq_item => {
      if (!newHideRates[srfq_item.item_code]) {
        newSumTotal += srfq_item.qty * srfq_item.rate;
      }
    });

    setItemCategories(newItemCategories);
    setHideRates(newHideRates);
    setHasHiddenPrice(newHasHiddenPrice);
    setSumTotal(newSumTotal);


    if (item.quotation) {
      fetchQuotationData(item.quotation).then((quotationData) => {
        console.log(`quotation data fetched `, quotationData)
        item.quotationExsist = true;
        item.quotationStatus = quotationData.status;
        item.quotationDate = quotationData.transaction_date;
        item.quotationName = quotationData.name;
        setQuotationName(quotationData.name);

        setModalData(item);
        setIsOpen(true);
      });

      if (item.sales_order) {
        fetchSalesOrderData(item.sales_order).then((salesOrderData) => {
          item.salesOrderExsist = true;
          item.salesOrderStatus = salesOrderData.status;
          item.salesOrderDate = salesOrderData.transaction_date;
          item.salesOrderName = salesOrderData.name;
          setSalesOrderName(salesOrderData.name);
          setModalData(item);
          setIsOpen(true);
        });
      }

      if (item.filteredSalesInvoices.length > 0) {
        setSelectedInvoice(item.filteredSalesInvoices[0].sales_invoice);
        setSalesInvoicePostinDate(item.filteredSalesInvoices[0].posting_date);
        setSalesInvoiceStatus(item.filteredSalesInvoices[0].status);
      }
      if (item.filteredDeliveryNotes.length > 0) {
        setSelectedDeliveryNote(item.filteredDeliveryNotes[0].delivery_note);
        setDeliveryNotePostinDate(item.filteredDeliveryNotes[0].posting_date);
        setDeliveryNoteStatus(item.filteredDeliveryNotes[0].status);
      }

    } else {
      setModalData(item);
      setIsOpen(true);
    }
  };

  const handleResetFilters = () => {
    setYear("");
    setMonth("");
    setFromDate("");
    setToDate("");
    setWorkflowState("");
    setSearchName("");
    setFilteredNames(data.map((item) => item.name));
    setSelectedName("");
  };



  const openPrintView = async () => {
    if (!modalData || !modalData.name) return;

    setLoading(true);
    setError(null);

    try {
      const response = await axiosInstance.get('/api/method/frappe.utils.print_format.download_pdf', {
        params: {
          doctype: 'Sales Request For Quotation',
          name: modalData.name,
          format: 'SRFQ V00',
          no_letterhead: 0,
          _: Date.now() // Cache buster
        },
        responseType: 'blob'
      });

      const blob = new Blob([response.data], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);

      // Open the blob URL in a new window or tab
      window.open(blobUrl, '_blank');

      // Clean up the blob URL after a delay
      setTimeout(() => URL.revokeObjectURL(blobUrl), 30000);
    } catch (err) {
      console.error("Error fetching print view:", err);
      setError("Failed to load print view. Please try again.");
    } finally {
      setLoading(false);
    }
  };






  const workflowStates = [
    "",
    "Pending",
    "QTN Created",
    "Canceled",
    "In Progress",
  ];

  return (
    <>
      <div className="addsaledrequstsection"  >

        <button
          style={{ margin: "10px" }}
          className="btn btn-primary"
          onClick={() => navigate("/AddSalesRequestForQutation")}
        >
          {t.addSalesRequestManual}
        </button>
        <button
          className="btn btn-secondary"
          onClick={() => navigate("/ProductGrid")}
        >
          {t.addSalesRequestGallery}
        </button>
      </div>

      <div className="row">
        <div className="col-xl-12">
          <div className="filter cm-content-box box-primary">
            <div className="content-title">
              <div className="cpa">
                <i className="fas fa-filter me-2"></i>
                <span>{t.filter}</span>
              </div>
              <div className="tools">
                <i className="fas fa-angle-up"></i>
              </div>
            </div>

            <div className="cm-content-body form excerpt">
              <div className="card-body">
                <div className="row filter-row">
                  <div className="col-xl-3 col-xxl-6">
                    <p>{t.from}</p>
                    <input
                      type="date"
                      name="fromDate"
                      className="form-control mb-xxl-0 mb-3"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>
                  <div className="col-xl-3 col-xxl-6">
                    <p>{t.to}</p>
                    <input
                      type="date"
                      name="toDate"
                      className="form-control mb-xxl-0 mb-3"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                  <div className="col-xl-3 col-xxl-6">
                    <p>{t.status}</p>
                    <select
                      className="form-control mb-xxl-0 mb-3"
                      value={workflowState}
                      onChange={handleWorkflowStateChange}
                    >
                      {workflowStates.map((state) => (
                        <option key={state} value={state}>
                          {state || "All"}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-xl-3 col-xxl-6">
                    <p>{t.name}</p>
                    <input
                      type="text"
                      name="searchName"
                      className="form-control mb-xxl-0 mb-3"
                      value={searchName}
                      onChange={handleSearchNameChange}
                      placeholder="Search by name"
                      autoComplete="off"
                    />
                    {searchName && filteredNames.length > 0 && (
                      <div className="autocomplete-dropdown">
                        {filteredNames.map((name) => (
                          <div
                            key={name}
                            className="autocomplete-suggestion"
                            onClick={() => handleNameSelect(name)}
                          >
                            {name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
                <button
                  className="btn btn-secondary"
                  onClick={handleResetFilters}
                >
                  {t.resetFilters}
                </button>
                <div className="filter cm-content-box box-primary mt-5">
                  <div className="content-title">
                    <div className="cpa">
                      <i className="fas fa-file-word me-2"></i>
                      {t.SalesRequestForQutation}
                    </div>
                  </div>
                  <div className="cm-content-body form excerpt">
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-3"></div>
                      <div className="table-responsive fixed-header-table">
                        <div
                          id="content_wrapper"
                          className="dataTables_wrapper"
                        >
                          <table
                            className="table table-bordered table-responsive-lg table-striped table-condensed flip-content"
                            ref={tableRef}
                          >
                            <thead>
                              <tr>
                                <th>{t.name}</th>
                                <th>{t.date}</th>
                                <th>{t.status}</th>
                                <th>{t.actions}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {loading ? (
                                <tr>
                                  <td colSpan="5" className="text-center">
                                    {t.loading}
                                  </td>
                                </tr>
                              ) : data.length > 0 ? (
                                data
                                  .filter(
                                    (item) =>
                                      !selectedName ||
                                      item.name === selectedName
                                  )
                                  .map((item) => (
                                    <tr key={item.name}>
                                      <td>{item.name}</td>
                                      <td>{item.date}</td>
                                      <td>{item.workflow_state}</td>
                                      <td>
                                        <button
                                          className="btn btn-primary"
                                          onClick={() => handleViewClick(item)}
                                        >
                                          {t.view}
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                              ) : (
                                <tr>
                                  <td colSpan="5" className="text-center">
                                    {t.noDataAvailable}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isOpen && modalData && (
            <div
              className="custom-modal"
              style={{ display: isOpen ? "block" : "none", overflow: "auto" }}
            >
              <div className="custom-modal-content ">
                <div className="row text-left">
                  <h2> {modalData.quotationExsist ? "Order Track" : " "} </h2>
                  {modalData.quotationExsist && (modalData.quotationStatus !== "Draft" && modalData.quotationStatus !== "Canceled") && (
                    <div className="col-2 mb-5 ">
                      <div
                        className="circle"
                        onClick={handleQuotationClick}
                        style={{ cursor: "pointer" }}
                      >
                        <h6 className="ml-3">{t.quotation}</h6>
                        <svg
                          viewBox="0 0 36 36"
                          className="circular-chart orange"
                        >
                          <path
                            className="circle-bg"
                            d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                          />
                          <path
                            className="circle"
                            strokeDasharray={`100, 100`}
                            d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                          />
                          <text x="18" y="20.35" className="percentage">
                            QTN
                          </text>
                        </svg>
                      </div>

                      <h6
                        className="mb-2  scrollable-text "
                        onClick={handleQuotationClick}
                      >
                        <FaArrowAltCircleRight />
                        &nbsp;&nbsp; {quotationName} <br></br>
                      </h6>
                      <h6 className="mb-2 ">
                        {" "}
                        <FaArrowAltCircleRight />
                        &nbsp;&nbsp; {modalData.quotationDate} <br></br>
                      </h6>
                      <h6 className="mb-2">
                        {" "}
                        <FaArrowAltCircleRight />
                        &nbsp;&nbsp; {modalData.quotationStatus} <br></br>{" "}
                      </h6>
                      <div></div>
                    </div>
                  )}


                  {(modalData.quotationExsist && modalData.sales_order && (modalData.salesOrderStatus !== 'Draft' && modalData.salesOrderStatus !== 'Canceled')) && (
                    <>
                      <div className="col-1 mt-5">
                        <FaArrowAltCircleRight
                          style={{
                            height: "50px",
                            width: "50px",
                            marginTop: "25px",
                          }}
                        />
                      </div>
                      <div className="col-2 mb-5 ">
                        <div
                          className="circle"
                          onClick={handleSalesOrderClick}
                          style={{ cursor: "pointer" }}
                        >
                          <h6 className="">{t.salesOrder}</h6>
                          <svg
                            viewBox="0 0 36 36"
                            className="circular-chart orange"
                          >
                            <path
                              className="circle-bg"
                              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                              className="circle"
                              strokeDasharray={`100, 100`}
                              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text x="18" y="20.35" className="percentage">
                              SO
                            </text>
                          </svg>
                        </div>
                        <h6
                          className="scrollable-text"
                          onClick={handleSalesOrderClick}
                        >
                          <FaArrowAltCircleRight />
                          &nbsp;&nbsp; {modalData.sales_order} <br></br>
                        </h6>

                        <h6 className="mb-2 ">
                          {" "}
                          <FaArrowAltCircleRight />
                          &nbsp;&nbsp; {modalData.salesOrderDate} <br></br>
                        </h6>

                        <h6 className="mb-2">
                          {" "}
                          <FaArrowAltCircleRight />
                          &nbsp;&nbsp; {
                            modalData.salesOrderStatus
                          } <br></br>{" "}
                        </h6>

                        <div></div>
                      </div>
                    </>
                  )}

                  {(modalData.quotationExsist && modalData.sales_order && modalData.filteredSalesInvoices.length > 0) && (
                    <>
                      <div className="col-1 mt-5">
                        <FaArrowAltCircleRight
                          style={{
                            height: "50px",
                            width: "50px",
                            marginTop: "25px",
                          }}
                        />
                      </div>
                      <div className="col-2 mb-5 ">
                        <div
                          className="circle"
                          onClick={handleSalesInvoiceClick}
                          style={{ cursor: "pointer" }}
                        >
                          <h6 className="">{t.salesInvoice}</h6>
                          <svg
                            viewBox="0 0 36 36"
                            className="circular-chart orange"
                          >
                            <path
                              className="circle-bg"
                              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                              className="circle"
                              strokeDasharray={`100, 100`}
                              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text x="18" y="20.35" className="percentage">
                              SINV
                            </text>
                          </svg>
                        </div>

                        <select
                          className="scrollable-text"
                          onChange={(e) => handleSalesInvoiceChange(e.target.value)
                          }
                        >
                          {modalData.filteredSalesInvoices.map((invoice) => (
                            <option key={invoice.sales_invoice} value={invoice.sales_invoice}>
                              {invoice.sales_invoice}
                            </option>
                          ))}
                        </select>

                        <h6 className="scrollable-text" onClick={handleSalesInvoiceClick}>
                          <FaArrowAltCircleRight />
                          &nbsp;&nbsp; {selectedInvoice} <br></br>
                        </h6>

                        <h6 className="mb-2 ">
                          {" "}
                          <FaArrowAltCircleRight />
                          &nbsp;&nbsp;
                          {salesInvoicePostinDate} <br></br>

                        </h6>

                        <h6 className="mb-2">
                          {" "}
                          <FaArrowAltCircleRight />
                          &nbsp;&nbsp;
                          {salesInvoiceStatus}
                          <br></br>{" "}
                        </h6>

                        <div></div>
                      </div>
                    </>
                  )}

                  {(modalData.quotationExsist && modalData.sales_order && modalData.filteredDeliveryNotes.length > 0) && (
                    <>
                      <div className="col-1 mt-5">
                        <FaArrowAltCircleRight
                          style={{
                            height: "50px",
                            width: "50px",
                            marginTop: "25px",
                          }}
                        />
                      </div>
                      <div className="col-2 mb-5 ">
                        <div
                          className="circle"
                          onClick={handleDeliveryNoteClick}
                          style={{ cursor: "pointer" }}
                        >
                          <h6 className="">{t.deliveryNote}</h6>
                          <svg
                            viewBox="0 0 36 36"
                            className="circular-chart orange"
                          >
                            <path
                              className="circle-bg"
                              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                              className="circle"
                              strokeDasharray={`100, 100`}
                              d="M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text x="18" y="20.35" className="percentage">
                              DN
                            </text>
                          </svg>
                        </div>


                        <select
                          className="scrollable-text"
                          onChange={(e) => handleDeliveryNoteChange(e.target.value)
                          }
                        >
                          {modalData.filteredDeliveryNotes.map((invoice) => (
                            <option key={invoice.delivery_note} value={invoice.delivery_note}>
                              {invoice.delivery_note}
                            </option>
                          ))}
                        </select>

                        <h6 className="scrollable-text" onClick={handleDeliveryNoteClick}>
                          <FaArrowAltCircleRight />
                          &nbsp;&nbsp; {selectedDeliveryNote} <br></br>
                        </h6>
                        <h6 className="mb-2 ">
                          {" "}
                          <FaArrowAltCircleRight />
                          &nbsp;&nbsp; {deliveryNotePostinDate} <br></br>
                        </h6>

                        <h6 className="mb-2">
                          {" "}
                          <FaArrowAltCircleRight />
                          &nbsp;&nbsp; {
                            deliveryNoteStatus
                          } <br></br>{" "}
                        </h6>

                        <div></div>
                      </div>
                    </>
                  )}
                </div>

                <div className="modal-header">
                  <span className="close" onClick={handleCloseModal}>
                    &times;
                  </span>
                  <h2>{t.quotationsDetails}</h2>
                </div>
                <div className="modal-body">
                  <div className="d-flex flex-wrap">
                    <p className="mr-3">
                      <strong>{t.name}:</strong> {modalData.name}
                    </p>
                    <p className="mr-3">
                      <strong>{t.customer}:</strong> {modalData.customer}
                    </p>
                    <p className="mr-3">
                      <strong>{t.date}:</strong> {modalData.date}
                    </p>
                    <p className="mr-3">
                      <strong>{t.status}:</strong> {modalData.workflow_state}
                    </p>
                  </div>
                  <div>
                    <h3>{t.items}</h3>

                    {modalData.srfq_item && modalData.srfq_item.length > 0 ? (
                      <table className="custom-table">
                        <thead>
                          <tr>

                            <th>{t.itemCode}</th>
                            <th>{t.itemName}</th>
                            <th>{t.image}</th>
                            <th>{t.quantity}</th>

                            <th>{t.rate}</th>
                            <th>{t.total}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {modalData.srfq_item.map((srfq_item, index) => {
                            const itemTotal = !hideRates[srfq_item.item_code]
                              ? (srfq_item.qty * srfq_item.rate).toFixed(2)
                              : 0;

                            return (
                              <tr key={index}>
                                <td className="scrollable-text">{srfq_item.item_code}</td>
                                <td className="scrollable-text">{srfq_item.item_name}</td>
                                <td>
                                  {srfq_item.image && srfq_item.image !== "" ? (
                                    <img src={`${process.env.REACT_APP_BASE_URL}${srfq_item.image}`} alt="Item" />
                                  ) : (
                                    <img src="https://www.shutterstock.com/image-vector/no-image-available-vector-illustration-260nw-744886198.jpg" alt="Default" />
                                  )}
                                </td>
                                <td>{srfq_item.qty}</td>

                                {!hideRates[srfq_item.item_code] ? (
                                  <>
                                    <td>{srfq_item.rate}</td>
                                    <td>{itemTotal}</td>
                                  </>
                                ) : (
                                  <>
                                    <td>{t.hidden}</td>
                                    <td>{t.hidden}</td>
                                  </>
                                )}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <p>{t.noItemsFound}</p>
                    )}
                  </div>

                  {/* Total calculations section */}
                  {modalData.srfq_item && modalData.srfq_item.length > 0 && (
                    <div className="mt-3">
                      {/* Calculate total values */}
                      {(() => {
                        const netTotal = modalData.srfq_item.reduce((acc, srfq_item) => {
                          const itemTotal = !hideRates[srfq_item.item_code] ? srfq_item.qty * srfq_item.rate : 0;
                          return acc + itemTotal;
                        }, 0).toFixed(2);

                        const totalTaxes = (parseFloat(netTotal) * 0.15).toFixed(2);
                        const grandTotal = (parseFloat(netTotal) + parseFloat(totalTaxes)).toFixed(2);

                        return (
                          <>
                            <p><strong>{t.netTotal}:</strong> {netTotal !== "0.00" ? `${netTotal} ${t.sar}` : "Price not available"}</p>
                            <p><strong>{t.totalTaxes}:</strong> {netTotal !== "0.00" ? `${totalTaxes} ${t.sar}` : "Price not available"}</p>
                            <p><strong>{t.grandTotal}:</strong> {netTotal !== "0.00" ? `${grandTotal} ${t.sar}` : "Price not available"}</p>
                          </>
                        );
                      })()}
                    </div>
                  )}
                </div>
                <div className="modal-footer" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  <button className="btn btn-secondary" onClick={handleCloseModal}>
                    {t.close}
                  </button>
                  {!hasHiddenPrice && (
                    <button className="btn btn-primary" onClick={openPrintView}>
                      {t.print}
                    </button>
                  )}
                </div>


              </div>
            </div>
          )}
          {isQuotationModelOpen && invoiceDetails && (
            <div
              className="custom-modal"
              style={{ display: isOpen ? "block" : "none" }}
            >
              <div className="custom-modal-content">
                <div className="modal-header">
                  <span className="close" onClick={handleQuotationCloseModal}>
                    &times;
                  </span>
                  <h2>{t.quotationsDetails}</h2>
                </div>
                <div className="modal-body">
                  <div>
                    <p>
                      <strong>{t.name}:</strong> {invoiceDetails.name}
                    </p>
                    <p>
                      <strong>{t.status}:</strong> {invoiceDetails.status}
                    </p>
                  </div>
                  <div>
                    <h3>{t.items}</h3>
                    {invoiceDetails.items && invoiceDetails.items.length > 0 ? (
                      <table className="custom-table">
                        <thead>
                          <tr>
                            <th>{t.image}</th>
                            <th>{t.itemName}</th>
                            <th>{t.quantity}</th>
                            <th>{t.rate}</th>
                            <th>{t.amount}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {invoiceDetails.items.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {item.image && item.image !== "" ? (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                    alt="Item"
                                  />
                                ) : (
                                  <img
                                    src="https://www.shutterstock.com/image-vector/no-image-available-vector-illustration-260nw-744886198.jpg"
                                    alt="Default"
                                  />
                                )}
                              </td>
                              <td className="scrollable-text">
                                {item.item_name}
                              </td>
                              <td>{item.qty}</td>
                              <td>{item.rate}</td>
                              <td>{item.amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>{t.noItemsFound}</p>
                    )}
                  </div>
                </div>
                <div className="modal-body">
                  <div>
                    <p>
                      <strong>{t.grandTotal}:</strong>{" "}
                      {invoiceDetails.base_grand_total}
                    </p>
                    <p>
                      <strong>{t.netTotal}:</strong>{" "}
                      {invoiceDetails.base_net_total}
                    </p>
                    <p>
                      <strong>{t.discountAmount}:</strong>{" "}
                      {invoiceDetails.base_discount_amount}
                    </p>
                    <p>
                      <strong>{t.totalTaxesAndCharges}:</strong>{" "}
                      {invoiceDetails.base_total_taxes_and_charges}
                    </p>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    onClick={handleQuotationCloseModal}
                  >
                    {t.close}
                  </button>
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}/printview?doctype=Quotation&name=${invoiceDetails.name}&format=Custom%20Quotation`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btn btn-primary">{t.print}</button>
                  </a>
                </div>
              </div>
            </div>
          )}

          {isSalesOrderModelOpen && salesOrderDetails && (
            <div
              className="custom-modal"
              style={{ display: isOpen ? "block" : "none" }}
            >
              <div className="custom-modal-content">
                <div className="modal-header">
                  <span className="close" onClick={handleSalesOrderCloseModal}>
                    &times;
                  </span>
                  <h2>{t.orderDetails}</h2>
                </div>
                <div className="modal-body">
                  <div>
                    <p>
                      <strong>{t.name}:</strong> {salesOrderDetails.name}
                    </p>
                    <p>
                      <strong>{t.status}:</strong> {salesOrderDetails.status}
                    </p>
                  </div>
                  <div>
                    <h3>{t.items}</h3>
                    {salesOrderDetails.items &&
                      salesOrderDetails.items.length > 0 ? (
                      <table className="custom-table">
                        <thead>
                          <tr>
                            <th>{t.image}</th>
                            <th>{t.itemName}</th>
                            <th>{t.quantity}</th>
                            <th>{t.rate}</th>
                            <th>{t.amount}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {salesOrderDetails.items.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {item.image && item.image !== "" ? (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                    alt="Item"
                                  />
                                ) : (
                                  <img
                                    src="https://www.shutterstock.com/image-vector/no-image-available-vector-illustration-260nw-744886198.jpg"
                                    alt="Default"
                                  />
                                )}
                              </td>
                              <td className="scrollable-text">
                                {item.item_name}
                              </td>
                              <td>{item.qty}</td>
                              <td>{item.rate}</td>
                              <td>{item.amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>{t.noItemsFound}</p>
                    )}
                  </div>
                </div>
                <div className="modal-body">
                  <div>
                    <p>
                      <strong>{t.grandTotal}:</strong>{" "}
                      {salesOrderDetails.base_grand_total}
                    </p>
                    <p>
                      <strong>{t.netTotal}:</strong>{" "}
                      {salesOrderDetails.base_net_total}
                    </p>
                    <p>
                      <strong>{t.discountAmount}:</strong>{" "}
                      {salesOrderDetails.base_discount_amount}
                    </p>
                    <p>
                      <strong>{t.totalTaxesAndCharges}:</strong>{" "}
                      {salesOrderDetails.base_total_taxes_and_charges}
                    </p>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    onClick={handleSalesOrderCloseModal}
                  >
                    {t.close}
                  </button>
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}/printview?doctype=Sales%20Order&name=${salesOrderDetails.name}&format=Custom%20Sales%20Order`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btn btn-primary">{t.print}</button>
                  </a>
                </div>
              </div>
            </div>
          )}

          {isSalesInvoiceModelOpen && salesInvoiceData && (
            <div
              className="custom-modal"
              style={{ display: isOpen ? "block" : "none" }}
            >
              <div className="custom-modal-content">
                <div className="modal-header">
                  <span
                    className="close"
                    onClick={handleSalesInvoiceCloseModal}
                  >
                    &times;
                  </span>
                  <h2>{t.invoiceDetails}</h2>
                </div>
                <div className="modal-body">
                  <div>
                    <p>
                      <strong>{t.name}:</strong> {salesInvoiceData.name}
                    </p>
                    <p>
                      <strong>{t.status}:</strong> {salesInvoiceData.status}
                    </p>
                  </div>
                  <div>
                    <h3>{t.items}</h3>
                    {salesInvoiceData.items &&
                      salesInvoiceData.items.length > 0 ? (
                      <table className="custom-table">
                        <thead>
                          <tr>
                            <th>{t.image}</th>
                            <th>{t.itemName}</th>
                            <th>{t.quantity}</th>
                            <th>{t.rate}</th>
                            <th>{t.amount}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {salesInvoiceData.items.map((item, index) => (
                            <tr key={index}>
                              <td>
                                {item.image && item.image !== "" ? (
                                  <img
                                    src={`${process.env.REACT_APP_BASE_URL}${item.image}`}
                                    alt="Item"
                                  />
                                ) : (
                                  <img
                                    src="https://www.shutterstock.com/image-vector/no-image-available-vector-illustration-260nw-744886198.jpg"
                                    alt="Default"
                                  />
                                )}
                              </td>
                              <td className="scrollable-text">
                                {item.item_name}
                              </td>
                              <td>{item.qty}</td>
                              <td>{item.rate}</td>
                              <td>{item.amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <p>{t.noItemsFound}</p>
                    )}
                  </div>
                </div>
                <div className="modal-body">
                  <div>
                    <p>
                      <strong>{t.grandTotal}:</strong>{" "}
                      {salesInvoiceData.base_grand_total}
                    </p>
                    <p>
                      <strong>{t.netTotal}:</strong>{" "}
                      {salesInvoiceData.base_net_total}
                    </p>
                    <p>
                      <strong>{t.discountAmount}t:</strong>{" "}
                      {salesInvoiceData.base_discount_amount}
                    </p>
                    <p>
                      <strong>{t.totalTaxesAndCharges}:</strong>{" "}
                      {salesInvoiceData.base_total_taxes_and_charges}
                    </p>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    onClick={handleSalesInvoiceCloseModal}
                  >
                    {t.close}
                  </button>
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}/printview?doctype=Sales%20Invoice&name=${salesInvoiceData.name}&format=Custom%20sales%20invoice%202`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btn btn-primary">{t.print}</button>
                  </a>
                </div>
              </div>
            </div>
          )}


          {isDeliveryNoteModelOpen && deliveryNoteData && (
            <div className="custom-modal" style={{ display: isOpen ? 'block' : 'none' }}>
              <div className="custom-modal-content">
                <div className="modal-header">
                  <span className="close" onClick={handleDeliveryNoteCloseModal}>&times;</span>
                  <h2>{t.deliveryNote}</h2>
                </div>
                <div className="modal-body">
                  <div>
                    <p><strong>{t.name}:</strong> {deliveryNoteData.name}</p>
                    <p><strong>{t.status}:</strong> {deliveryNoteData.status}</p>

                  </div>
                  <div>
                    <h3>{t.items}</h3>
                    {deliveryNoteData.items && deliveryNoteData.items.length > 0 ? (
                      <table className="custom-table">
                        <thead>
                          <tr>
                            <th>{t.image}</th>
                            <th>{t.itemName}</th>
                            <th>{t.quantity}</th>
                            <th>{t.rate}</th>
                            <th>{t.amount}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {deliveryNoteData.items.map((item, index) => (
                            <tr key={index}>
                              <td>

                                {item.image && item.image !== "" ? (
                                  <img src={`${process.env.REACT_APP_BASE_URL}${item.image}`} alt="Item" />
                                ) : (
                                  <img src="https://www.shutterstock.com/image-vector/no-image-available-vector-illustration-260nw-744886198.jpg" alt="Default" />
                                )}
                              </td>
                              <td className="scrollable-text">{item.item_name}</td>
                              <td>{item.qty}</td>
                              <td>{item.rate}</td>
                              <td>{item.amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>

                    ) : (
                      <p>{t.noItemsFound}</p>
                    )}
                  </div>
                </div>
                <div className="modal-body">
                  <div>
                    <p><strong>{t.grandTotal}:</strong> {deliveryNoteData.base_grand_total}</p>
                    <p><strong>{t.netTotal}:</strong> {deliveryNoteData.base_net_total}</p>
                    <p><strong>{t.discountAmount}:</strong> {deliveryNoteData.base_discount_amount}</p>
                    <p><strong>{t.totalTaxesAndCharges}:</strong> {deliveryNoteData.base_total_taxes_and_charges}</p>
                  </div>
                </div>

                <div className="modal-footer">
                  <button className="btn btn-secondary" onClick={handleCloseModal}>{t.close}</button>
                  <a
                    href={`${process.env.REACT_APP_BASE_URL}/printview?doctype=Delivery%20Note&name=${deliveryNoteData.name}&format=DN`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="btn btn-primary">{t.print}</button>
                  </a>
                </div>
              </div>
            </div>
          )}


        </div>
      </div>
    </>
  );
};

export default Content;