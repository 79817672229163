import React, { useReducer, useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Collapse, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { MenuList } from "./Menu";
import { translations } from "./translations";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
import LogoutPage from "./Logout";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active: "",
  activeSubmenu: "",
};

const SideBar = () => {
  const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
  const t = translations[language];

  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);

  const [state, setState] = useReducer(reducer, initialState);

  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);

  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );

  const handleMenuActive = (status) => {
    setState({ active: status });
    if (state.active === status) {
      setState({ active: "" });
    }
  };
  const handleSubmenuActive = (status) => {
    setState({ activeSubmenu: status });
    if (state.activeSubmenu === status) {
      setState({ activeSubmenu: "" });
    }
  };

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  // Helper function to get translation
  const getTranslation = (key) => {
    // Return the translation if it exists, otherwise return the key itself
    return t[key] || key;
  };

  return (
    <div
      onMouseEnter={() => ChangeIconSidebar(true)}
      onMouseLeave={() => ChangeIconSidebar(false)}
      className={`dlabnav ${iconHover} ${sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
        ? hideOnScroll > 120
          ? "fixed"
          : ""
        : ""
        }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <Dropdown as="div" className="header-profile2 dropdown">
          <Dropdown.Toggle
            as="div"
            variant=""
            className="i-false c-pointer"
            role="button"
            data-toggle="dropdown"
          >
            <div className="header-info2 d-flex align-items-center">
              <div className="d-flex align-items-center sidebar-info">
                <div>
                  <span className="font-w400 d-block">
                    {
                      JSON.parse(localStorage.getItem("userDetails"))?.message
                        ?.full_name
                    }
                  </span>
                  <small className="text-end font-w400">Superadmin</small>
                </div>
                <i className="fas fa-chevron-down"></i>
              </div>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu
            align="end"
            className="dropdown-menu dropdown-menu-end"
          >
            <LogoutPage />
          </Dropdown.Menu>
        </Dropdown>
        <ul className="metismenu" id="menu">
          {MenuList.map((data, index) => {
            let menuClass = data.classsChange;
            if (menuClass === "menu-title") {
              return (
                <li className={menuClass} key={index}>
                  {getTranslation(data.title)}
                </li>
              );
            } else {
              return (
                <li
                  className={`${state.active === data.title ? "mm-active" : ""
                    }`}
                  key={index}
                >
                  {data.content && data.content.length > 0 ? (
                    <>
                      <Link
                        to={"#"}
                        className="has-arrow"
                        onClick={() => {
                          handleMenuActive(data.title);
                        }}
                      >
                        {data.iconStyle}
                        <span className="nav-text">{getTranslation(data.title)}</span>
                        {data.update && (
                          <span className="badge badge-xs style-1 badge-danger">
                            {data.update}
                          </span>
                        )}
                      </Link>
                      <Collapse in={state.active === data.title ? true : false}>
                        <ul
                          className={`${menuClass === "mm-collapse" ? "mm-show" : ""
                            }`}
                        >
                          {data.content &&
                            data.content.map((subData, subIndex) => {
                              return (
                                <li
                                  key={subIndex}
                                  className={`${state.activeSubmenu === subData.title
                                    ? "mm-active"
                                    : ""
                                    }`}
                                >
                                  {subData.content && subData.content.length > 0 ? (
                                    <>
                                      <Link
                                        to={subData.to}
                                        className={
                                          subData.hasMenu ? "has-arrow" : ""
                                        }
                                        onClick={() => {
                                          handleSubmenuActive(subData.title);
                                        }}
                                      >
                                        {getTranslation(subData.title)}
                                      </Link>
                                      <Collapse
                                        in={
                                          state.activeSubmenu === subData.title
                                            ? true
                                            : false
                                        }
                                      >
                                        <ul
                                          className={`${menuClass === "mm-collapse"
                                            ? "mm-show"
                                            : ""
                                            }`}
                                        >
                                          {subData.content &&
                                            subData.content.map((nestedData, nestedIndex) => {
                                              return (
                                                <li key={nestedIndex}>
                                                  <Link
                                                    className={`${path === nestedData.to
                                                      ? "mm-active"
                                                      : ""
                                                      }`}
                                                    to={nestedData.to}
                                                  >
                                                    {getTranslation(nestedData.title)}
                                                  </Link>
                                                </li>
                                              );
                                            })}
                                        </ul>
                                      </Collapse>
                                    </>
                                  ) : (
                                    <Link to={subData.to}>
                                      {getTranslation(subData.title)}
                                    </Link>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      </Collapse>
                    </>
                  ) : (
                    <Link to={data.to}>
                      {data.iconStyle}
                      <span className="nav-text">{getTranslation(data.title)}</span>
                    </Link>
                  )}
                </li>
              );
            }
          })}
        </ul>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;