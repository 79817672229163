import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Collapse from "react-bootstrap/Collapse";
import { callApi } from "../../../services/frappeServices/db/db";
import { format } from "date-fns";
import NumericLabel from "react-pretty-numbers";
import {
  DownloadTableExcel,
  useDownloadExcel,
} from "react-export-table-to-excel";
import "./content.css"; // Ensure this file contains the CSS for .fixed-header-tabl
import axiosInstance from "../../../services/frappeServices/AxiosInstance";
// import axios from 'axios'; // Import axios
import { useGlobalContext } from "../../../context/GlobalContext";

const translations = {
  en: {
    filter: "Filter",
    resetFilter: "Reset Filter",
    year: "Year",
    month: "Month",
    discountVoucher: "Hikvision Discount Voucher",
    name: "Name",
    customer: "Customer",
    description: "Description",
    discountAmount: "Discount Amount",
    showDetails: "Show Details",
    print: "Print",
    Month: "Month",
    loading: "Loading...",
    noDataFound: "No data found",
  },
  ar: {
    filter: "فلتر",
    resetFilter: "محو الفلتر",
    year: "السنة",
    month: "الشهر",

    discountVoucher: "قسيمة خصم",
    name: "الاسم",
    customer: "العميل",
    description: "الوصف",
    discountAmount: "مبلغ الخصم",
    showDetails: "عرض التفاصيل ",
    print: "طباعة",
    loading: "جاري التحميل...",
    noDataFound: "لا توجد بيانات",
  },
};

const Content = () => {
  const { currentCustomer } = useGlobalContext();
  const tableRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [error, setError] = useState(null);
  const [language, setLanguage] = useState(
    () => localStorage.getItem("selected_language") || "en"
  );
  const t = translations[language];

  const fetchData = async (year, month, currentCustomer) => {
    setLoading(true);

    let url = `${process.env.REACT_APP_BASE_URL}/api/resource/Customer%20Discount%20Voucher?fields=["name", "customer","year","month","description","discount_amount"]`;

    const filters = [];

    filters.push(`["from_hikvision", "=", "1"]`);
    if (year) {
      filters.push(`["year", "=", "${year}"]`);
    }
    if (month) {
      filters.push(`["month", "=", "${month}"]`);
    }
    if (currentCustomer) {
      filters.push(`["customer", "=", "${currentCustomer}"]`);
    }

    if (filters.length > 0) {
      url += `&filters=[${filters.join(",")}]&order_by=creation desc`;
    }

    try {
      const response = await axiosInstance.get(url);
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(currentCustomer);
  }, [currentCustomer]);

  useEffect(() => {
    fetchData(year, month, currentCustomer);
  }, [year, month, currentCustomer]);

  const formatDiscountAmount = (amount) => {
    if (isNaN(amount)) return amount;
    const roundedAmount = Math.round(amount);
    return new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(roundedAmount);
  };

  const openPrintView = async (itemName) => {
    if (!itemName) return;
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get(
        "/api/method/frappe.utils.print_format.download_pdf",
        {
          params: {
            doctype: "Customer Discount Voucher",
            name: itemName,
            format: "AAAQ CDV",
            no_letterhead: 0,
            _: Date.now(), // Cache buster
          },
          responseType: "blob",
        }
      );
      const blob = new Blob([response.data], { type: "application/pdf" });
      const blobUrl = URL.createObjectURL(blob);
      // Open the blob URL in a new window or tab
      window.open(blobUrl, "_blank");
      // Clean up the blob URL after a delay
      setTimeout(() => URL.revokeObjectURL(blobUrl), 30000);
    } catch (err) {
      console.error("Error fetching print view:", err);
      setError("Failed to load print view. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="filter cm-content-box box-primary">
            <div className="content-title">
              <div className="cpa">
                <i className="fas fa-filter me-2"></i>
                <span>{t.filter} </span>
              </div>
              <div className="tools">
                <i className="fas fa-angle-up"></i>
              </div>
            </div>

            <div className="cm-content-body form excerpt">
              <div className="card-body">
                <div className="row filter-row">
                  <div className="col-xl-3 col-xxl-6">
                    <p>{t.year}</p>
                    <input
                      type="number"
                      name="year"
                      className="form-control mb-xxl-0 mb-3"
                      value={year}
                      onChange={(e) => setYear(e.target.value)}
                    />
                  </div>
                  <div className="col-xl-3 col-xxl-6">
                    <p>{t.month}</p>
                    <input
                      type="number"
                      name="month"
                      className="form-control mb-xxl-0 mb-3"
                      value={month}
                      onChange={(e) => setMonth(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="filter cm-content-box box-primary mt-5">
            <div className="content-title">
              <div className="cpa">
                <i className="fas fa-file-word me-2"></i>
                {t.discountVoucher}
              </div>
            </div>
            <div className="cm-content-body form excerpt">
              <div className="card-body">
                <div className="table-responsive fixed-header-table">
                  <div id="content_wrapper" className="dataTables_wrapper">
                    <table
                      className="table table-bordered table-responsive-lg table-striped table-condensed flip-content"
                      ref={tableRef}
                    >
                      <thead>
                        <tr>
                          <th>{t.name}</th>
                          <th>{t.customer}</th>
                          <th>{t.year}</th>

                          <th>{t.description}</th>
                          <th>{t.discountAmount}</th>
                          <th>{t.showDetails}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan="7" className="text-center">
                              {t.loading}...
                            </td>
                          </tr>
                        ) : data.length > 0 ? (
                          data.map((item) => (
                            <tr key={item.name}>
                              <td>{item.name}</td>
                              <td>{item.customer}</td>
                              <td>{item.year}</td>

                              <td className="description-cell">
                                {item.description}
                              </td>
                              <td>
                                {formatDiscountAmount(item.discount_amount)}
                              </td>
                              <td>
                                <div className="col-xl-3 col-xxl-6">
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => openPrintView(item.name)}
                                    disabled={loading}
                                  >
                                    {t.print}
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="7" className="text-center">
                              {t.noDataFound}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;