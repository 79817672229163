import React, { useContext } from "react";

/// React router dom
import {Routes, Route, Outlet } from 'react-router-dom'

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from './pages/ScrollToTop';
/// Dashboard
import Home from "./components/Dashboard/Home";
import DashboardDark from "./components/Dashboard/DashboardDark";
import Jobs from "./components/Dashboard/Jobs";
import Applications from "./components/Dashboard/Applications";
import MyProfile from "./components/Dashboard/MyProfile";
import Statistics from "./components/Dashboard/Statistics";
import Companies from "./components/Dashboard/Companies";
import Task from "./components/Dashboard/Task";

//Jobs
import JobLists from "./components/Jobs/JobLists";
import JobView from "./components/Jobs/JobView";
import JobApplication from "./components/Jobs/JobApplication";
import ApplyJob from "./components/Jobs/ApplyJob";
import NewJob from "./components/Jobs/NewJob";
import UserProfile from "./components/Jobs/UserProfile";

//CMS
import Content from './components/Cms/Content';
import PrePayment from "./components/Cms/PrePayment";
import AAAQ_Discount_Voucher from './components/Cms/AAAQ_Discount_Voucher';
import DiscountVoutcher from './components/Cms/DiscountVoutcher';
import SalesRequestForQutation from './components/Cms/SalesRequestForQutation';
import AddSalesRequestForQutation from './components/Cms/AddSalesRequestForQutation';
 

import CustomerTarget from './components/Cms/CustomerTarget';
import SalesInvoice from './components/Cms/SalesInvoice';
import DeliveryNote from './components/Cms/DeliveryNote';

import SalesOrder from './components/Cms/SalesOrder';
import Quotations from './components/Cms/Quotations';


import Menu from './components/Cms/Menu';
import EmailTemplate from './components/Cms/EmailTemplate';
import Blog from './components/Cms/Blog';
//CMS Linking Pages
import ContentAdd from './components/Cms/ContentAdd';
import AddMail from './components/Cms/AddMail';
import AddBlog from './components/Cms/AddBlog';
import BlogCategory from './components/Cms/BlogCategory';

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import Compose from "./components/AppsMenu/Email/Compose/Compose";
import Inbox from "./components/AppsMenu/Email/Inbox/Inbox";
import Read from "./components/AppsMenu/Email/Read/Read";
import Calendar from "./components/AppsMenu/Calendar/Calendar";
import PostDetails from "./components/AppsMenu/AppProfile/PostDetails";

/// Product List
import ProductGrid from "./components/AppsMenu/Shop/ProductGrid/ProductGrid";
import ProductList from "./components/AppsMenu/Shop/ProductList/ProductList";
import ProductDetail from "./components/AppsMenu/Shop/ProductGrid/ProductDetail";
import Checkout from "./components/AppsMenu/Shop/Checkout/Checkout";
import Invoice from "./components/AppsMenu/Shop/Invoice/Invoice";
import ProductOrder from "./components/AppsMenu/Shop/ProductOrder";
import Customers from "./components/AppsMenu/Shop/Customers/Customers";

/// Charts
import SparklineChart from "./components/charts/Sparkline";
import ChartJs from "./components/charts/Chartjs";
//import Chartist from "./components/charts/chartist";
import RechartJs from "./components/charts/rechart";
import ApexChart from "./components/charts/apexcharts";

/// Bootstrap
import UiAlert from "./components/bootstrap/Alert";
import UiAccordion from "./components/bootstrap/Accordion";
import UiBadge from "./components/bootstrap/Badge";
import UiButton from "./components/bootstrap/Button";
import UiModal from "./components/bootstrap/Modal";
import UiButtonGroup from "./components/bootstrap/ButtonGroup";
import UiListGroup from "./components/bootstrap/ListGroup";
import UiCards from "./components/bootstrap/Cards";
import UiCarousel from "./components/bootstrap/Carousel";
import UiDropDown from "./components/bootstrap/DropDown";
import UiPopOver from "./components/bootstrap/PopOver";
import UiProgressBar from "./components/bootstrap/ProgressBar";
import UiTab from "./components/bootstrap/Tab";
import UiPagination from "./components/bootstrap/Pagination";
import UiGrid from "./components/bootstrap/Grid";
import UiTypography from "./components/bootstrap/Typography";

/// Plugins
import Select2 from "./components/PluginsMenu/Select2/Select2";
//import Nestable from "./components/PluginsMenu/Nestable/Nestable";
//import MainNouiSlider from "./components/PluginsMenu/NouiSlider/MainNouiSlider";
import MainSweetAlert from "./components/PluginsMenu/SweetAlert/SweetAlert";
import Toastr from "./components/PluginsMenu/Toastr/Toastr";
import JqvMap from "./components/PluginsMenu/JqvMap/JqvMap";
import Lightgallery from "./components/PluginsMenu/Lightgallery/Lightgallery";

//Redux
import Todo from "./pages/Todo";

/// Widget
import Widget from "./pages/Widget";

/// Table
import SortingTable from "./components/table/SortingTable/SortingTable";
import FilteringTable from "./components/table/FilteringTable/FilteringTable";
import DataTable from "./components/table/DataTable";
import BootstrapTable from "./components/table/BootstrapTable";

/// Form
import Element from "./components/Forms/Element/Element";
import Wizard from "./components/Forms/Wizard/Wizard";
import CkEditor from "./components/Forms/CkEditor/CkEditor";
import Pickers from "./components/Forms/Pickers/Pickers";
import FormValidation from "./components/Forms/FormValidation/FormValidation";

/// Pages
// import Registration from "./pages/Registration";
// import Login from "./pages/Login";
// import ForgotPassword from "./pages/ForgotPassword";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {
 // const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  const allroutes = [
    /// allroutes
    { url: "", component: <Home /> },
    { url: "dashboard", component: <Home/> },
 

    // { url: "dashboard-dark", component: <DashboardDark/> },
    // { url: "search-jobs", component: <Jobs/> },
    // { url: "applications", component: <Applications/> },
    // { url: "my-profile", component: <MyProfile/> },
    // { url: "statistics", component: <Statistics/> },
    // { url: "companies", component: <Companies/> },
    // { url: "task", component: <Task/> },
	
	//Jobs
    { url: "job-list", component: <JobLists/> },
    { url: "promotion-details", component: <JobView/> },
    { url: "promotion", component: <UiListGroup/> },
    { url: "job-application", component: <JobApplication/> },
    { url: "apply-job", component: <ApplyJob/> },
    { url: "new-job", component: <NewJob/> },
    { url: "user-profile", component: <UserProfile/> },
    
    //Cms
	  { url: 'customer-statement', component: <Content/> },
    { url: 'DiscountVoutcher', component: <DiscountVoutcher /> },
    { url: 'AAAQ_Discount_Voucher', component: <AAAQ_Discount_Voucher /> },
    { url: 'ProductGrid', component: <ProductGrid /> },

    { url: 'SalesRequestForQutation', component: <SalesRequestForQutation /> },
    // { url: 'SalesRequestForQutation2', component: <SalesRequestForQutation2 /> },

    { url: 'CustomerTarget', component: <CustomerTarget /> },
    { url: 'AddSalesRequestForQutation', component: <AddSalesRequestForQutation /> },


    { url: 'SalesInvoice', component: <SalesInvoice /> },
    { url: 'DeliveryNote', component: <DeliveryNote /> },

    { url: 'SalesOrder', component: <SalesOrder /> },
    { url: 'Quotations', component: <Quotations /> },



	  { url: 'menu', component: <Menu/> },
	  { url: 'email-template', component: <EmailTemplate/> },
	  { url: 'blog', component: <Blog/> },
	  { url: 'add-content', component: <ContentAdd/> },
	  { url: 'add-email', component: <AddMail/> },
	  { url: 'add-blog', component: <AddBlog/> },
	  { url: 'blog-category', component: <BlogCategory/> },

    /// Apps
    { url: "app-profile", component: <AppProfile/> },
    { url: "email-compose", component: <Compose /> },
    { url: "email-inbox", component: <Inbox/> },
    { url: "email-read", component: <Read/> },
    { url: "app-calender", component: <Calendar/> },
    { url: "post-details", component: <PostDetails/> },

    /// Chart
    { url: "chart-sparkline", component: <SparklineChart/> },
    { url: "chart-chartjs", component: <ChartJs/> },
    //{ url: "chart-chartist", component: Chartist },
    { url: "chart-apexchart", component: <ApexChart/> },
    { url: "chart-rechart", component: <RechartJs/> },

    /// Bootstrap
    { url: "ui-alert", component: <UiAlert/> },
    { url: "ui-badge", component: <UiBadge/> },
    { url: "ui-button", component: <UiButton/> },
    { url: "ui-modal", component: <UiModal/> },
    { url: "ui-button-group", component: <UiButtonGroup/> },
    { url: "ui-accordion", component: <UiAccordion/> },
    { url: "ui-list-group", component: <UiListGroup/> },   
    { url: "ui-card", component: <UiCards/> },
    { url: "ui-carousel", component: <UiCarousel/> },
    { url: "ui-dropdown", component: <UiDropDown/> },
    { url: "ui-popover", component: <UiPopOver/> },
    { url: "ui-progressbar", component: <UiProgressBar/> },
    { url: "ui-tab", component: <UiTab/> },
    { url: "ui-pagination", component: <UiPagination/> },
    { url: "ui-typography", component: <UiTypography/> },
    { url: "ui-grid", component: <UiGrid/> },

    /// Plugin
    { url: "uc-select2", component: <Select2 /> },
    //{ url: "uc-nestable", component: Nestable },
    //{ url: "uc-noui-slider", component: MainNouiSlider },
    { url: "uc-sweetalert", component: <MainSweetAlert/> },
    { url: "uc-toastr", component: <Toastr/> },
    { url: "map-jqvmap", component: <JqvMap/> },
    { url: "uc-lightgallery", component: <Lightgallery/> },

	///Redux
	{ url: "todo", component: <Todo/> },
    /// Widget
    { url: "widget-basic", component: <Widget/> },

    /// Shop
    { url: "ecom-product-grid", component: <ProductGrid/> },
    { url: "ecom-product-list", component: <ProductList/> },
    { url: "ecom-product-detail", component: <ProductDetail/> },
    { url: "ecom-product-order", component: <ProductOrder/> },
    { url: "ecom-checkout", component: <Checkout/> },
    { url: "ecom-invoice", component: <Invoice/> },
    { url: "ecom-customers", component: <Customers/> },

    /// Form
    { url: "form-element", component: <Element/> },
    { url: "form-wizard", component: <Wizard/> },
    { url: "form-ckeditor", component: <CkEditor /> },
    { url: "form-pickers", component: <Pickers /> },
    { url: "pre-payment-save", component: <FormValidation /> },
    { url: "pre-payment", component: <PrePayment/> },

    /// table
	  { url: 'table-filtering', component: <FilteringTable/> },
    { url: 'table-sorting', component: <SortingTable/> },
    { url: "table-datatable-basic", component: <DataTable/> },
    { url: "table-bootstrap-basic", component: <BootstrapTable/> },
    
  ];
  //let path = window.location.pathname;
 // path = path.split("/");
 // path = path[path.length - 1];

 // let pagePath = path.split("-").includes("page");
  return (
    <>
      <Routes>
          <Route path='page-lock-screen' element= {<LockScreen />} />
          <Route path='page-error-400' element={<Error400/>} />
          <Route path='page-error-403' element={<Error403/>} />
          <Route path='page-error-404' element={<Error404/>} />
          <Route path='page-error-500' element={<Error500/>} />
          <Route path='page-error-503' element={<Error503/>} />
          <Route  element={<MainLayout />} > 
              {allroutes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`${data.url}`}
                  element={data.component}
                />
              ))}
          </Route>
      </Routes>      
	    <ScrollToTop />
    </>
  );
};

function MainLayout(){  
  const { menuToggle, sidebariconHover } = useContext(ThemeContext);
  return (
    <>
      <div id="main-wrapper" className={`show ${sidebariconHover ? "iconhover-toggle": ""} ${ menuToggle ? "menu-toggle" : ""}`}>  
        <Nav />
        <div className="content-body" style={{ minHeight: window.screen.height - 45 }}>
            <div className="container-fluid">
              <Outlet />                
            </div>
        </div>
        <Footer />
      </div>
      <Setting />
    </>
  )

};

export default Markup
