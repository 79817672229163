import React, { useEffect, useState, useRef } from "react";
import { format } from "date-fns";
import Select from "react-select";
import axiosInstance from '../../../services/frappeServices/AxiosInstance';
import "./content.css"; // Ensure this file contains the CSS for .fixed-header-table and modal styles
import './fixModel.css'

import { useGlobalContext } from "../../../context/GlobalContext";
const statusOptions = [
  { value: "To Bill", label: "To Bill" },
  { value: "Completed", label: "Completed" },
  { value: "Return Issued", label: "Return Issued" },
  { value: "Closed", label: "Closed" },

];
const translations = {
  en: {
    filter: "Filter",
    resetFilter: "Reset Filter",
    status: "Status",
    name: "Name",
    searchbyName: "Search by name",
    deliveryNote: "Delivery Note",
    date: "Date",
    from: "From",
    transactionDate: "Transaction Date",
    deliveryDate: "Delivery Date",
    grandTotal: "Grand Total",
    view: "View",
    showDetails: "Show Details",
    deliveryDetails: "delivery Details",
    image: "Image",
    quantity: "Quantity",
    rate: "Rate",
    amount: "Amount",
    items: "Items",
    netTotal: "Net Total",
    discountAmount: "Discount Amount",
    totalTaxesandCharges: "Total Taxes and Charges",
    noItems: "No items found",
    close: "Close",
    print: "print",
    to: "To"
  },
  ar: {
    filter: "فلتر",
    resetFilter: "محو الفلتر",
    status: "الحالة",
    deliveryNote: "اشعار التسليم",
    searchbyName: "بحث بالاسم",
    date: "التاريخ",
    name: "الاسم",
    from: "من",
    transactionDate: "تاريخ المعامله",
    deliveryDate: "تاريخ الوصول",
    grandTotal: "الاجمالي",
    view: "عرض التفاصيل",
    deliveryDetails: "تفاصيل التسليم",
    image: "الصوره",
    quantity: "الكميه",
    rate: "السعر",
    amount: "القيمه",
    showDetails: "اظهار التفاصيل",
    items: "العناصر",
    netTotal: "صافي المبلغ",
    discountAmount: "مبلغ الخصم",
    totalTaxesandCharges: "اجمالي الضرائب والرسوم",
    close: "اغلاق",
    print: "طباعه",
    noItems: "لا توجد عناصر",
    to: "إلى"
  }
};
const Content = () => {
  const { currentCustomer } = useGlobalContext();
  const tableRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [searchName, setSearchName] = useState("");
  const [filteredNames, setFilteredNames] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [error, setError] = useState(null);
  const formatNumber = (number) => {
    if (number === null || number === undefined) return '';
    return new Intl.NumberFormat().format(number.toFixed(2));
  };
  const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
  const t = translations[language];
  const fetchData = async (currentCustomer) => {
    setLoading(true);

    // Base URL
    let url = `${process.env.REACT_APP_BASE_URL}/api/resource/Delivery%20Note?fields=["name","status","grand_total","base_grand_total","base_net_total","base_discount_amount","base_total_taxes_and_charges","posting_date"]`;

    // Filters
    const filters = [];

    // Add status filter
    if (selectedStatus) {
      filters.push(`["status", "=", "${selectedStatus.value}"]`);
    }


    if (searchName) {
      filters.push(`["name", "like", "%${searchName.trim()}%"]`);
    }

    // Add name filter
    if (selectedName) {
      filters.push(`["name", "=", "${selectedName}"]`);
    }
    if (currentCustomer) {
      filters.push(`["customer", "=", "${currentCustomer}"]`);
    }

    // Add date filters
    if (fromDate) {
      filters.push(`["posting_date", ">=", "${format(new Date(fromDate), 'yyyy-MM-dd')}"]`);
    }
    if (toDate) {
      filters.push(`["posting_date", "<=", "${format(new Date(toDate), 'yyyy-MM-dd')}"]`);
    }

    // Construct filters string
    if (filters.length > 0) {
      url += `&filters=[${filters.join(",")}]&order_by=creation desc`;
    }

    try {
      const response = await axiosInstance.get(url);
      // Filter out cancelled items
      const filteredData = response.data.data.filter(item => item.status !== 'Cancelled' && item.status !== 'Draft' && item.status !== 'Submitted');
      setData(filteredData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };






  const openPrintView = async (invoiceName) => {
    if (!invoiceName) return;
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get('/api/method/frappe.utils.print_format.download_pdf', {
        params: {
          doctype: 'Delivery Note',
          name: invoiceName,
          format: 'DN',
          no_letterhead: 0,
          _: Date.now() // Cache buster
        },
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      // Open the blob URL in a new window or tab
      window.open(blobUrl, '_blank');
      // Clean up the blob URL after a delay
      setTimeout(() => URL.revokeObjectURL(blobUrl), 30000);
    } catch (err) {
      console.error("Error fetching print view:", err);
      setError("Failed to load print view. Please try again.");
    } finally {
      setLoading(false);
    }
  };



  const fetchInvoiceDetails = async (invoiceName) => {
    try {
      const response = await axiosInstance.get(`${process.env.REACT_APP_BASE_URL}/api/resource/Delivery%20Note/${invoiceName}`);
      setInvoiceDetails(response.data.data);
    } catch (error) {
      console.error('Error fetching invoice details:', error);
    }
  };

  const handleFilterChange = () => {
    fetchData(currentCustomer);
  };

  const handleResetFilters = () => {
    setFromDate("");
    setToDate("");
    setSelectedStatus(null);
    setSearchName("");
    setFilteredNames(data.map(item => item.name));
    setSelectedName("");
    // Fetch data without any filters
  };

  useEffect(() => {
    fetchData(currentCustomer);
  }, [currentCustomer, selectedStatus, selectedName, fromDate, toDate, searchName]);

  const handleViewClick = (invoice) => {
    setSelectedInvoice(invoice);
    fetchInvoiceDetails(invoice.name);
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setInvoiceDetails(null); // Clear invoice details when closing the modal
  };

  const handleSearchNameChange = (e) => {
    const value = e.target.value;
    setSearchName(value);
    if (value) {
      const filtered = data.filter(item => item.name.toLowerCase().includes(value.toLowerCase())).map(item => item.name);
      setFilteredNames(filtered);
    } else {
      setFilteredNames(data.map(item => item.name));
    }
  };

  const handleNameSelect = (name) => {
    setSelectedName(name);
    setSearchName(name);
    setFilteredNames([]); // Clear the dropdown
  };

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="filter cm-content-box box-primary">
            <div className="content-title">
              <div className="cpa">
                <i className="fas fa-filter me-2"></i>
                <span>{t.filter}</span>
              </div>
              <div className="tools">
                <i className="fas fa-angle-up"></i>
              </div>
            </div>

            <div className="cm-content-body form excerpt">
              <div className="card-body">
                <div className="row filter-row">
                  <div className="col-xl-3 col-xxl-6">
                    <p>{t.status}</p>
                    <Select
                      options={statusOptions}
                      value={selectedStatus}
                      onChange={setSelectedStatus}
                      className="mb-xxl-0 mb-3"
                    />
                  </div>
                  <div className="col-xl-3 col-xxl-6">
                    <p>{t.name}</p>
                    <input
                      type="text"
                      name="searchName"
                      className="form-control mb-xxl-0 mb-3"
                      value={searchName}
                      onChange={handleSearchNameChange}
                      placeholder={t.searchbyName}
                      autoComplete="off"
                    />
                    {searchName && filteredNames.length > 0 && (
                      <div className="autocomplete-dropdown">
                        {filteredNames.map((name) => (
                          <div
                            key={name}
                            className="autocomplete-suggestion"
                            onClick={() => handleNameSelect(name)}
                          >
                            {name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="col-xl-3 col-xxl-6">
                    <p>{t.from}</p>
                    <input
                      type="date"
                      name="fromDate"
                      className="form-control mb-xxl-0 mb-3"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>
                  <div className="col-xl-3 col-xxl-6">
                    <p>{t.to}</p>
                    <input
                      type="date"
                      name="toDate"
                      className="form-control mb-xxl-0 mb-3"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-xl-3 col-xxl-6">
                  <button className="btn btn-primary" onClick={handleFilterChange}>{t.filter}</button>
                  <button className="btn btn-secondary" style={{ marginLeft: "20px" }} onClick={handleResetFilters}>
                    {t.resetFilter}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xl-12">
          <div className="filter cm-content-box box-primary mt-5">
            <div className="content-title">
              <div className="cpa">
                <i className="fas fa-file-word me-2"></i>
                {t.deliveryNote}
              </div>
            </div>

            <div className="cm-content-body form excerpt">
              <div className="card-body">
                <div className="table-responsive fixed-header-table" style={{ display: isOpen ? 'none' : 'block' }}>
                  <div id="content_wrapper" className="dataTables_wrapper">
                    <table
                      className="table table-bordered table-responsive-lg table-striped table-condensed flip-content"
                      ref={tableRef}
                    >
                      <thead>
                        <tr>
                          <th>{t.name}</th>
                          <th>{t.transactionDate}</th>
                          <th>{t.status}</th>
                          <th>{t.grandTotal}</th>
                          <th style={{ textAlign: 'center' }}>{t.showDetails}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan="5" className="text-center">
                              Loading...
                            </td>
                          </tr>
                        ) : data.length === 0 ? (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No data available
                            </td>
                          </tr>
                        ) : (
                          data.map((item) => (
                            <tr key={item.name}>
                              <td>{item.name}</td>
                              <td>{format(new Date(item.posting_date), 'yyyy-MM-dd')}</td>
                              <td>{item.status}</td>
                              <td>{formatNumber(item.grand_total)}</td>
                              <td style={{ textAlign: 'center' }}>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => handleViewClick(item)}
                                >
                                  {t.view}
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isOpen && invoiceDetails && (
        <div className="custom-modal" style={{ display: isOpen ? 'block' : 'none' }}>
          <div className="custom-modal-content">
            <div className="modal-header">
              <span className="close" onClick={handleCloseModal}>&times;</span>
              <h2>{t.deliveryDetails}</h2>
            </div>
            <div className="modal-body">
              <div>
                <p><strong>{t.name}:</strong> {invoiceDetails.name}</p>
                <p><strong>{t.status}:</strong> {invoiceDetails.status}</p>

              </div>
              <div>
                <h3>Items</h3>
                {invoiceDetails.items && invoiceDetails.items.length > 0 ? (
                  <table className="custom-table">
                    <thead>
                      <tr>
                        <th>{t.image}</th>
                        <th>{t.name}</th>
                        <th>{t.quantity}</th>
                        <th>{t.rate}</th>
                        <th>{t.amount}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceDetails.items.map((item, index) => (
                        <tr key={index}>
                          <td>

                            {item.image && item.image !== "" ? (
                              <img src={`${process.env.REACT_APP_BASE_URL}${item.image}`} alt="Item" />
                            ) : (
                              <img src="https://www.shutterstock.com/image-vector/no-image-available-vector-illustration-260nw-744886198.jpg" alt="Default" />
                            )}
                          </td>
                          <td className="scrollable-text">{item.item_name}</td>
                          <td>{item.qty}</td>
                          <td>{item.rate}</td>
                          <td>{item.amount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                ) : (
                  <p>{t.noItems}</p>
                )}
              </div>
            </div>
            <div className="modal-body">
              <div>
                <p><strong>{t.grandTotal}:</strong> {invoiceDetails.base_grand_total}</p>
                <p><strong>{t.netTotal}:</strong> {invoiceDetails.base_net_total}</p>
                <p><strong>{t.discountAmount}:</strong> {invoiceDetails.base_discount_amount}</p>
                <p><strong>{t.totalTaxesandCharges}:</strong> {invoiceDetails.base_total_taxes_and_charges}</p>
              </div>
            </div>

            <div className="modal-footer">
              <button className="btn btn-secondary" onClick={handleCloseModal}>{t.close}</button>
              <button
                className="btn btn-primary"
                onClick={() => openPrintView(selectedInvoice.name)}
                disabled={loading}
              >{t.print}</button>

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Content;