import React, { useEffect, useState, useRef } from "react";
// import axios from "axios";
import "./content.css"; // Ensure this file contains the CSS for .fixed-header-table
import axiosInstance from '../../../services/frappeServices/AxiosInstance';
import { useGlobalContext } from '../../../context/GlobalContext';

// Function to format numbers with commas and two decimal places
const formatNumber = (number) => {
  if (number == null) return '0.00'; // Handle null or undefined
  return parseFloat(number).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
};
const translations = {
  en: {
    customerTarget: "Customer Target",
    target: "Target",
    actual: "Actual",
    variance: "Variance",
    name: "Name",
    year: "Year",
    print: "Print",
    nodata: "No Data Found",
  },
  ar: {

    customerTarget: "هدف العميل ",
    target: "الهدف",
    actual: "الفعلي",
    variance: "الفرق",
    name: "الاسم",
    year: "السنه",
    print: "طباعه",
    nodata: "لا توجد معلومات",

  }
};

const Content = () => {
  const { currentCustomer } = useGlobalContext();
  const tableRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [currentQuarter, setCurrentQuarter] = useState(1);
  const [dataYear, setDataYear] = useState(null);
  const [error, setError] = useState(null);

  const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
  const t = translations[language];

  const openPrintView = async (itemName) => {
    if (!itemName) return;
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get('/api/method/frappe.utils.print_format.download_pdf', {
        params: {
          doctype: 'Customer Target',
          name: itemName,
          format: 'Customer Target V00',
          no_letterhead: 0,
          _: Date.now() // Cache buster
        },
        responseType: 'blob'
      });
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const blobUrl = URL.createObjectURL(blob);
      // Open the blob URL in a new window or tab
      window.open(blobUrl, '_blank');
      // Clean up the blob URL after a delay
      setTimeout(() => URL.revokeObjectURL(blobUrl), 30000);
    } catch (err) {
      console.error("Error fetching print view:", err);
      setError("Failed to load print view. Please try again.");
    } finally {
      setLoading(false);
    }
  };









  const fetchData = async (year, month, currentCustomer) => {
    setLoading(true);
    let url = `${process.env.REACT_APP_BASE_URL}/api/resource/Customer Target?fields=[\"name\", \"year\",\"q1_target\",\"q1_actual\",\"q1_variance\",\"q2_target\",\"q2_actual\",\"q2_variance\",\"q3_target\",\"q3_actual\",\"q3_variance\",\"q4_target\",\"q4_actual\",\"q4_variance\"]`;

    const filters = [];
    if (year) {
      filters.push(`["year", "=", "${year}"]`);
    }
    if (month) {
      filters.push(`["month", "=", "${month}"]`);
    }
    if (currentCustomer) {
      filters.push(`["customer", "=", "${currentCustomer}"]`);
    }
    if (filters.length > 0) {
      url += `&filters=[${filters.join(",")}]`;
    }

    try {
      const response = await axiosInstance.get(url);
      setData(response.data.data);

      if (response.data.data.length > 0) {
        setDataYear(response.data.data[0].year);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    let quarter = 1;
    if (currentMonth >= 1 && currentMonth <= 3) {
      quarter = 1;
    } else if (currentMonth >= 4 && currentMonth <= 6) {
      quarter = 2;
    } else if (currentMonth >= 7 && currentMonth <= 9) {
      quarter = 3;
    } else if (currentMonth >= 10 && currentMonth <= 12) {
      quarter = 4;
    }
    setCurrentQuarter(quarter);
  }, []);

  useEffect(() => {
    fetchData(year, month, currentCustomer);
  }, [year, month, currentCustomer]);

  const isCurrentYear = (itemYear) => {
    const currentYear = new Date().getFullYear();
    return parseInt(itemYear) === currentYear;
  };




  return (
    <>
      <div className="filter cm-content-box box-primary mt-5">
        <div className="content-title">
          <div className="cpa">
            <i className="fas fa-file-word me-2"></i>{t.customerTarget}
          </div>
        </div>
        <div className="info-section">
          <div className="info-content">
            <div className="info-item">
              <div className="info-icon1">T</div>
              <div className="info-text">{t.target}</div>
            </div>
            <div className="info-item">
              <div className="info-icon2">A</div>
              <div className="info-text">{t.actual}</div>
            </div>
            <div className="info-item">
              <div className="info-icon3">V</div>
              <div className="info-text">{t.variance}</div>
            </div>
          </div>
        </div>
        <div className="cm-content-body form excerpt">
          <div className="card-body">
            <div className="table-responsive fixed-header-table">
              <div id="content_wrapper" className="dataTables_wrapper">
                <table
                  className="table table-bordered table-responsive-lg table-striped table-condensed flip-content"
                  ref={tableRef}
                >
                  <thead>
                    <tr>
                      <th>{t.name}</th>
                      <th>{t.year}</th>
                      <th>Q1</th>
                      <th>Q2</th>
                      <th>Q3</th>
                      <th>Q4</th>
                      <th style={{ textAlign: "left" }}>{t.print}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="7" className="text-center">
                          Loading...
                        </td>
                      </tr>
                    ) : data.length > 0 ? (
                      data.map((item) => (
                        <tr key={item.name}>
                          <td>{item.name}</td>
                          <td>{item.year}</td>
                          {isCurrentYear(item.year) ? (
                            <>
                              <td>
                                {currentQuarter >= 1 && (
                                  <>
                                    <div><span style={{ color: "green" }}>T: </span>{formatNumber(item.q1_target)}</div>
                                    <div><span style={{ color: "red" }}>A: </span>{formatNumber(item.q1_actual)}</div>
                                    <div><span style={{ color: "blue" }}>V: </span> {formatNumber(item.q1_variance)}</div>
                                  </>
                                )}
                              </td>
                              <td>
                                {currentQuarter >= 2 && (
                                  <>
                                    <div><span style={{ color: "green" }}>T: </span>{formatNumber(item.q2_target)}</div>
                                    <div><span style={{ color: "red" }}>A: </span>{formatNumber(item.q2_actual)}</div>
                                    <div><span style={{ color: "blue" }}>V: </span> {formatNumber(item.q2_variance)}</div>
                                  </>
                                )}
                              </td>
                              <td>
                                {currentQuarter >= 3 && (
                                  <>
                                    <div><span style={{ color: "green" }}>T: </span>{formatNumber(item.q3_target)}</div>
                                    <div><span style={{ color: "red" }}>A: </span>{formatNumber(item.q3_actual)}</div>
                                    <div><span style={{ color: "blue" }}>V: </span> {formatNumber(item.q3_variance)}</div>
                                  </>
                                )}
                              </td>
                              <td>
                                {currentQuarter >= 4 && (
                                  <>
                                    <div><span style={{ color: "green" }}>T: </span>{formatNumber(item.q4_target)}</div>
                                    <div><span style={{ color: "red" }}>A: </span>{formatNumber(item.q4_actual)}</div>
                                    <div><span style={{ color: "blue" }}>V: </span> {formatNumber(item.q4_variance)}</div>
                                  </>
                                )}
                              </td>
                            </>
                          ) : (
                            <>
                              <td>
                                <div><span style={{ color: "green" }}>T: </span>{formatNumber(item.q1_target)}</div>
                                <div><span style={{ color: "red" }}>A: </span>{formatNumber(item.q1_actual)}</div>
                                <div><span style={{ color: "blue" }}>V: </span> {formatNumber(item.q1_variance)}</div>
                              </td>
                              <td>
                                <div><span style={{ color: "green" }}>T: </span>{formatNumber(item.q2_target)}</div>
                                <div><span style={{ color: "red" }}>A: </span>{formatNumber(item.q2_actual)}</div>
                                <div><span style={{ color: "blue" }}>V: </span> {formatNumber(item.q2_variance)}</div>
                              </td>
                              <td>
                                <div><span style={{ color: "green" }}>T: </span>{formatNumber(item.q3_target)}</div>
                                <div><span style={{ color: "red" }}>A: </span>{formatNumber(item.q3_actual)}</div>
                                <div><span style={{ color: "blue" }}>V: </span> {formatNumber(item.q3_variance)}</div>
                              </td>
                              <td>
                                <div><span style={{ color: "green" }}>T: </span>{formatNumber(item.q4_target)}</div>
                                <div><span style={{ color: "red" }}>A: </span>{formatNumber(item.q4_actual)}</div>
                                <div><span style={{ color: "blue" }}>V: </span> {formatNumber(item.q4_variance)}</div>
                              </td>
                            </>
                          )}
                          <td>
                            <div className="col-xl-3 col-xxl-6">
                              <button
                                className="btn btn-primary"
                                onClick={() => openPrintView(item.name)}
                                disabled={loading}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  className="bi bi-printer"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M2 4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2v3a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1v-3a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2zM1 6a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-1v2H2v-2H1a1 1 0 0 1-1-1V6zm2 4v3h10v-3H3zm0-1h10V7H3v2zm1-1h1v1H4V8zm1 0h1v1H5V8zm1 0h1v1H6V8z" />
                                </svg>
                              </button>

                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          {t.nodata}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Content;