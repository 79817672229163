import React,{useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import {getTopSalesItems} from '../../../../services/frappeServices/api/api';
//Images 
import pic1 from './../../../../images/comapny/1.png';
import pic2 from './../../../../images/comapny/2.png';
import pic3 from './../../../../images/comapny/3.png';
import pic4 from './../../../../images/comapny/4.png';
import pic5 from './../../../../images/comapny/5.png';
import pic6 from './../../../../images/comapny/6.png';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useGlobalContext } from '../../../../context/GlobalContext';

const translations = {
	en: {
		topPurchasingItems: "Top Purchasing Items",
		details: "Details",
		cancel: "Cancel",
		amount: "Amount",
		quantity: "Quantity",
		viewMore: "View more",
		loading: "loading ..."
	},
	ar: {
		topPurchasingItems: "أفضل العناصر المشتراة",
		details: "التفاصيل",
		cancel: "إلغاء",
		amount: "المبلغ",
		quantity: "الكمية",
		viewMore: "عرض المزيد",
		loading: "جار التحميل ..."
	}
};



const Companyblog = [
	{ image: pic1, title:'Kleon Team'},
	{ image: pic2, title:'Ziro Studios Inc.'},
	{ image: pic3, title:'Qerza'},
	{ image: pic4, title:'Kripton Studios'},
	{ image: pic5, title:'Omah Ku Inc.'},
	{ image: pic6, title:'Ventic'},
	{ image: pic4, title:'Sakola'},
	{ image: pic2, title:'Uena Foods'},
];



  
const FeaturedCompanies = () => {	
	// This is load more function 
	const {currentCustomer} = useGlobalContext();

	const [data, setData] = useState([]);
	const [language, setLanguage] = useState(() => localStorage.getItem("selected_language") || "en");
	const t = translations[language];
	useEffect(() => {
		const topSalesItems = async () => {
			try {
				const response = await getTopSalesItems(currentCustomer);
				 setData(response.message);
			}
			catch(error){
		console.log(error)
			}
		}
		topSalesItems();
	},[currentCustomer])
	const [refresh, setRefresh] = useState(false);
	const onClick = () => {
		setRefresh(true);
		setTimeout(() => {
		  setData([
			...data,
			data[Math.floor(Math.random() * Math.floor(data.length - 1))],
		  ]);
		  setRefresh(false);
		}, 1000);
	};
	return(
		<> 
			<div className="card fix-top-selling-item">
				<div className="card-header border-0 pb-0 ">
					<h4 className="fs-20 mb-1">{t.topPurchasingItems}</h4>
					<Dropdown >
						<Dropdown.Toggle as="div" className="btn-link i-false">
							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#342E59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="#342E59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
								<path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="#342E59" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
							</svg>
						</Dropdown.Toggle>
						<Dropdown.Menu className="dropdown-menu-right">
							<Dropdown.Item>{t.details}</Dropdown.Item>
							<Dropdown.Item>{t.cancel}</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
				<div className="card-body pt-3 featured-scroll loadmore-content dlab-scroll height370 ">
					<div className="row" id="FeaturedCompaniesContent">
						{ 
						data ? (
							data.map((item, index)=>(
								<div className="col-xl-6 col-sm-6 mt-4" key={index}>
            <OverlayTrigger
                placement="top"
                overlay={
                    <Tooltip id={`tooltip-${index}`}>
                        <div>
                            <h5 style={{color:'white'}}>{item.item_name}</h5><br />
                        </div>
                    </Tooltip>
                }
            >
                <div className="d-flex mb-2">
                    <img src={ item.image ? `${process.env.REACT_APP_BASE_URL}/${ item.image}` : pic1} alt="" style={{ height: '50px' }} />
                    <div className="ms-3 featured">
                        <h5 className="fs-18 mb-2">{item.item_code}</h5>
						<h6 className="fs-16 mb-1"> {t.amount}: {item.base_amount.toLocaleString()}</h6>
						<h6 className="fs-16 mb-1"> {t.quantity}: {item.quantity.toLocaleString()}</h6>
                    </div>
                </div>
            </OverlayTrigger>
        </div>
							))
						) :(
								<p>{t.loading} ...</p>
						)
					}
					</div>
				</div>
				<div className="card-footer border-0 m-auto">
					<Link to={"#"} className="btn btn-outline-primary  m-auto dlab-load-more"  onClick={() => onClick()}>
						{t.viewMore}{" "}
						{refresh && <i className="fa fa-refresh"></i>}
					</Link>
				</div>
			</div>
		</>
	)
} 
export default FeaturedCompanies; 