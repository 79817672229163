import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

class CanvasChart2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activityData: [],
    };
  }

  componentDidMount() {
    this.processActivityData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.statisticsData !== this.props.statisticsData || prevProps.year !== this.props.year) {
      this.processActivityData();
    }
  }

  processActivityData() {
    const { statisticsData, year } = this.props;
    let activityData = [];
  
    if (!statisticsData || statisticsData.length === 0) {
      this.setState({ activityData: [] });
      return ;
    }

    if (year == new Date().getFullYear()) {
      const month = new Date().getMonth();
      const quarters = {
        1: [1, 2, 3],
        2: [4, 5, 6],
        3: [7, 8, 9],
        4: [10, 11, 12],
      };

      const getQuarter = (month) => {
        for (const [quarter, months] of Object.entries(quarters)) {
          if (months.includes(month + 1)) { // Adjusting month index to 1-based
            return quarter;
          }
        }
        return null; // If month is invalid
      };

      const currentQuarter = getQuarter(month);

      const getAllQuartersUpToCurrent = (currentQuarter) => {
        const allQuarters = [];
        for (let i = parseInt(currentQuarter); i >= 1; i--) {
          allQuarters.push(i);
        }
        return allQuarters;
      };

      const previousQuarters = getAllQuartersUpToCurrent(currentQuarter);
  
      const quarterData = [];
  
      for (let i = 0; i < previousQuarters.length; i++) {
        quarterData.push(Math.abs(statisticsData[0][`q${previousQuarters[i]}_variance`]));
        quarterData.push(statisticsData[0][`q${previousQuarters[i]}_actual`]);
        quarterData.push(statisticsData[0][`q${previousQuarters[i]}_target`]);
      }
  
      for (let i = 0; i < 4; i++) {
        activityData.push([...quarterData].reverse());
      }
    } else {
      activityData = [
        [
          statisticsData[0].q1_target, statisticsData[0].q1_actual, Math.abs(statisticsData[0].q1_variance),
          statisticsData[0].q2_target, statisticsData[0].q2_actual, Math.abs(statisticsData[0].q2_variance),
          statisticsData[0].q3_target, statisticsData[0].q3_actual, Math.abs(statisticsData[0].q3_variance),
          statisticsData[0].q4_target, statisticsData[0].q4_actual, Math.abs(statisticsData[0].q4_variance), 
        ],
        [
          statisticsData[0].q1_target, statisticsData[0].q1_actual, Math.abs(statisticsData[0].q1_variance),
          statisticsData[0].q2_target, statisticsData[0].q2_actual, Math.abs(statisticsData[0].q2_variance),
          statisticsData[0].q3_target, statisticsData[0].q3_actual, Math.abs(statisticsData[0].q3_variance),
          statisticsData[0].q4_target, statisticsData[0].q4_actual, Math.abs(statisticsData[0].q4_variance), 
        ],
        [
          statisticsData[0].q1_target, statisticsData[0].q1_actual, Math.abs(statisticsData[0].q1_variance),
          statisticsData[0].q2_target, statisticsData[0].q2_actual, Math.abs(statisticsData[0].q2_variance),
          statisticsData[0].q3_target, statisticsData[0].q3_actual, Math.abs(statisticsData[0].q3_variance),
          statisticsData[0].q4_target, statisticsData[0].q4_actual, Math.abs(statisticsData[0].q4_variance), 
        ],
        [
          statisticsData[0].q1_target, statisticsData[0].q1_actual, Math.abs(statisticsData[0].q1_variance),
          statisticsData[0].q2_target, statisticsData[0].q2_actual, Math.abs(statisticsData[0].q2_variance),
          statisticsData[0].q3_target, statisticsData[0].q3_actual, Math.abs(statisticsData[0].q3_variance),
          statisticsData[0].q4_target, statisticsData[0].q4_actual, Math.abs(statisticsData[0].q4_variance), 
        ],
      ];
    }
  
    this.setState({ activityData });
  }

  render() {
    const { activityData } = this.state;
    const { dataActive } = this.props;

    const barColors = [
      '#F73A0B', '#4BC0C0', '#FFCE56', '#F73A0B', '#4BC0C0', '#FFCE56',
      '#F73A0B', '#4BC0C0', '#FFCE56', '#F73A0B', '#4BC0C0', '#FFCE56',
    ];

    const data = {
      labels: ["", "Quarter 1", "", "", "Quarter 2", "", "", "Quarter 3", "", "", "Quarter 4", "", ""],
      datasets: [{
        backgroundColor: barColors,
        borderColor: 'var(--primary)',
        data: activityData[dataActive] || [],
        borderWidth: 0,
        barThickness: 'flex',
        minBarLength: 10,
        barPercentage: 0.3,
      }],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: false,
      },
      scales: {
        y: {
          grid: {
            color: "rgba(255,255,255,0.2)",
            drawBorder: true,
          },
          ticks: {
            fontColor: "#6E6E6E",
            max: 60,
            min: 0,
            stepSize: 20,
          },
        },
        x: {
          grid: {
            display: false,
            zeroLineColor: "transparent",
          },
          ticks: {
            stepSize: 20,
            fontColor: "#6E6E6E",
            fontFamily: "Nunito, sans-serif",
          },
        },
      },
      tooltips: {
        mode: "index",
        intersect: false,
        titleFontColor: "#888",
        bodyFontColor: "#555",
        titleFontSize: 12,
        bodyFontSize: 15,
        backgroundColor: "rgba(255,255,255,1)",
        displayColors: true,
        xPadding: 10,
        yPadding: 7,
        borderColor: "rgba(220, 220, 220, 1)",
        borderWidth: 1,
        caretSize: 6,
        caretPadding: 10,
      },
    };

    return (
      <div style={{ minHeight: "200px" }}>
        <Bar
          data={data}
          width={this.props.width ? this.props.width : 433}
          height={this.props.height ? this.props.height : 200}
          options={options}
        />
      </div>
    );
  }
}

export default CanvasChart2;
